import {useAsync} from "react-async-hook";
import {$axios} from "../http/axiosInstance";
import {CreateSearchParams} from "../helpers/helpers";

export const CurrencyService = {
    GetCurrency(searchParamsObj: any,) {
        return useAsync(async () => {
            return await $axios.get('/exchanges/' + CreateSearchParams(searchParamsObj));
        }, [CreateSearchParams(searchParamsObj)])
    },
    async UpdateCurrency(value: any) {
        return await $axios.put(`/exchanges/${value.id}/`, {
            id: value.id,
            name: value.name,
            code: value.code,
            value: value.value,
        })
    }
}