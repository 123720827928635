import React, {useEffect, useState} from 'react'
import '../../styles/orders.css'
import {DataGrid, GridColDef, GridFooterContainer} from "@mui/x-data-grid";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Stack} from "@mui/material";
import {BoxOfficeService} from "../../services/BoxOfficeService";

export default function QueueView() {
    const navigate = useNavigate();
    const {id} = useParams();
    const billingInfo = BoxOfficeService.GetBillingChequeInfo(id)
    const mainTableColumns: GridColDef[] = [
        {field: 'id', headerName: 'ID', width: 100},
        {field: 'tracking_number', headerName: 'Трек номер', width: 250},
        {field: 'store', headerName: 'Магазин', width: 150},
        {field: 'country', headerName: 'Страна', width: 150},
        {field: 'rounded_weight', headerName: 'Окр. вес', width: 100},
    ];
    const [mainTableData, setMainTableData] = useState<any>([])
    useEffect(() => {
        if (!billingInfo.loading && !billingInfo.error) {
            setMainTableData(billingInfo.result?.data.bill_orders.map((order: any) => ({
                id: order.id,
                tracking_number: order.tracking_number,
                store: order.store.name,
                country: order.country.name,
                rounded_weight: Math.round(order.rounded_weight),
            })))
        }
    }, [billingInfo.loading, billingInfo.error])

    return (
        <>
            <h1 className='componentMainWrapper__title'>Просмотр выдачи №{id}</h1>
            <span className="componentMainWrapper__subTitle">Товарищи! укрепление и развитие структуры в значительной степени обуславливает создание новых предложений. </span>
            <Stack width={'100%'}>
                <div style={{width: '100%', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'}}>
                    <DataGrid
                        rows={mainTableData}
                        columns={mainTableColumns}
                        keepNonExistentRowsSelected
                        disableColumnFilter
                        filterMode='server'
                        autoHeight
                        localeText={{
                            noRowsLabel: "Нет информации",
                          }}
                        loading={billingInfo.loading}
                        disableSelectionOnClick
                        onRowDoubleClick={(params) => {
                            navigate(`/boxOffice/orderViewPage/${params.row.id}`)
                        }}
                        components={{
                            Footer: () => {
                                return (
                                    <GridFooterContainer>
                                        <Stack direction='row' justifyContent='flex-end' spacing='20px'
                                               sx={{margin: '20px', width: '100%'}}>
                                            <Button
                                                sx={{
                                                    width: "200px",
                                                    height: "36px",
                                                    background: "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
                                                    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
                                                    borderRadius: "4px",
                                                    color: 'white',
                                                }}
                                                onClick={() => {
                                                    navigate('/queue')
                                                }}
                                            >
                                                Назад
                                            </Button>
                                        </Stack>
                                    </GridFooterContainer>
                                );
                            },
                        }}
                    />
                </div>
            </Stack>
        </>
    )
}