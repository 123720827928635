import {useAsync} from "react-async-hook";
import {$axios} from "../http/axiosInstance";
import {CreateSearchParams} from "../helpers/helpers";

export const WarehouseService = {
    GetOrdersList(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get(`/admin/warehouse/orders/` + CreateSearchParams(searchParamsObj));
        }, [CreateSearchParams(searchParamsObj)])
    },
    GetExcelsList(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/warehouse/excels/' + CreateSearchParams(searchParamsObj));
        }, [CreateSearchParams(searchParamsObj)])
    },
    SearchOrderInfo(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/warehouse/order/search/' + CreateSearchParams(searchParamsObj));
        }, [CreateSearchParams(searchParamsObj)])
    },

    GetOrderInfo(id: string | undefined) {
        return useAsync(async () => {
            return await $axios.get(`/admin/warehouse/order/${id}/`);
        }, [])
    },
    async GetOrder(id: string) {
        return await $axios.get(`/admin/warehouse/order/${id}/`)
    },
    async SearchOrder(searchParamsObj: any) {
        return await $axios.get('/admin/warehouse/order/search/' + CreateSearchParams(searchParamsObj))
    },
    async DeleteExcel(id: string) {
        return await $axios.delete(`/admin/warehouse/excels/${id}/`)
    },
    async AddOrder(values: any) {
        return await $axios.post('/admin/warehouse/order/create/', values)
    },
    async EditOrder(values: any) {
        return await $axios.post('/admin/warehouse/order/create/', values)
    },
    async AddExcel(values:any) {
        let form_data = new FormData()
        const data: any = values
        for (let key in data) {
            form_data.append(key, data[key]);
        }
        return await $axios.post('/admin/warehouse/orders/import/', form_data)
    },
    async SendOrders(values: any) {
        return await $axios.post('/admin/warehouse/orders/update/', values)
    },
    async SendExcels(values: any) {
        return await $axios.post('/admin/warehouse/excels/orders/update/', values)
    },
}