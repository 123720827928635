import React, {useEffect, useState} from 'react';
import '../../styles/layout.css';
import {
    Autocomplete,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel, FormHelperText, InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {StoresService} from "../../services/StoresService";
import {TransportsService} from "../../services/TransportsService";
import {AddressesService} from "../../services/AddressesService";
import {WarehouseService} from "../../services/WarehouseService";
import {CustomerService} from "../../services/CustomerService";
import {checkModalResponse} from "../../helpers/helpers";
import {useSelector} from "react-redux";
import {LoadingButton} from "@mui/lab";
import {SortingService} from "../../services/SortingService";

export default function SortingDetails() {
    const user = useSelector((state: any)=> state.userData.user)

    const formInitialValues = {
        open: false,
        values: {
            id: "",
            tracking_number: '',
            user: '',
            full_name: '',
            store: '',
            store_name: '',
            type_of_transport: '',
            country: user.country?.id !== '' ? user.country?.id : '',
            weight: '',
            delivery_country: '',
            rounded_weight: '',
            comment: '',
        },
        validation: {
            error: {
                tracking_number: false,
                user: false,
                full_name: false,
                store: false,
                type_of_transport: false,
                country: false,
                delivery_country: false,
                weight: false,
                rounded_weight: false,
                comment: false,
            },
            message: {
                tracking_number: '',
                user: '',
                full_name: '',
                store: '',
                type_of_transport: '',
                country: '',
                delivery_country: '',
                weight: '',
                rounded_weight: '',
                comment: '',
            },
        },
        requested: false,
    };

    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const action = params.get("action");
    const tracking_number = params.get("tracking_number");

    const [form, setForm] = useState<any>({
        ...formInitialValues,
        action: action,
    });

    const storesList = StoresService.GetStores()
    const countriesList = AddressesService.GetCountries()
    const personalCountriesList = AddressesService.GetPersonalCountries('customer')
    const transportsList = TransportsService.GetTransports(form.values.country)
    const customersList = CustomerService.GetCustomersListBySearch({search: form.values.full_name})

    useEffect(() => {
        WarehouseService.SearchOrder({search: form.values.tracking_number}).then((res)=>{
            const data = res.data.pop()
            if (data) {
                setForm((prevState: any) => ({
                    ...formInitialValues,
                    values:{
                        ...formInitialValues.values,
                        tracking_number: prevState.values.tracking_number,
                        user: data.user?.id,
                        full_name: data.user === null ? data.attribute : data.user.full_name,
                        store: data.store === null ? '' : data.store.id,
                        store_name: data.store === null ? '' : data.store.name,
                        type_of_transport: data.type_of_transport?.id,
                        country: data.country?.id,
                        delivery_country: data.delivery_country?.id,
                        weight: data.weight,
                        rounded_weight: data.rounded_weight,
                        comment: data.comment,
                        leave_gift_boxes: data.leave_gift_boxes,
                        leave_shoe_boxes: data.leave_shoe_boxes,
                    }
                }))
            } else {
                setForm((prevState: any) => ({
                    ...prevState,
                    values:{
                        ...prevState.values,
                        user: '',
                        full_name: '',
                        store: '',
                        store_name: '',
                        country: '',
                        delivery_country: '',
                        type_of_transport: '',
                        weight: '',
                        comment: '',
                    }
                }))
            }
        })
    }, [form.values.tracking_number])

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setForm({
            ...form,
            requested: true,
        });
        const values = form.values

        switch (form.action) {
            case "add":
                SortingService.AddOrder(values)
                    .then((res) => {
                        if (`${form.values.country}` === '2') {
                            window.open(res.data.sticker_link, '_blank');
                        }
                        setForm(formInitialValues);
                        navigate({
                            pathname: "/sorting",
                        });
                    })
                    .catch((err) => {
                        checkModalResponse(err.response.data, setForm, form);
                    });
                break;

            case "edit":
                SortingService.EditOrder(values)
                    .then((res) => {
                        if (`${form.values.country}` === '2') {
                            window.open(res.data.sticker_link, '_blank');
                        }
                        setForm(formInitialValues);
                        navigate({
                            pathname: "/sorting",
                        });
                    })
                    .catch((err) => {
                        checkModalResponse(err.response.data, setForm, form);
                    });
                break;
        }
    };

    useEffect(() => {
        if (action === "edit" && tracking_number) {
            setForm({
                ...form,
                values: {
                    ...form.values,
                    tracking_number: tracking_number,
                },
            });
        }
    }, [action, tracking_number]);

    return (
        <>
            <p className="componentMainWrapper__title mb-[40px]">Склад</p>

            <div className="newDataAdd">
                <div className="newDataAdd__title">
                    {action === 'edit' && "Редактирование посылки"}
                    {action === 'add' && "Добавление посылки"}
                </div>
                <form className="newDataAdd__form" onSubmit={handleFormSubmit}>
                    <TextField
                        label='Трек код / Track code / 跟踪代码:'
                        value={form.values.tracking_number}
                        placeholder='Трек код'
                        fullWidth
                        helperText={form.validation.message.tracking_number}
                        error={form.validation.error.tracking_number}
                        onChange={(event) => {
                            setForm({
                                ...form,
                                values: {
                                    ...form.values,
                                    tracking_number: event.target.value,
                                }});
                        }}
                    />
                    <TextField
                        label='ID Пользователя / User ID / 用户身份:'
                        value={form.values.user}
                        helperText={form.validation.message.user}
                        error={form.validation.error.user}
                        fullWidth
                        disabled
                    />
                    <Autocomplete
                        fullWidth
                        freeSolo
                        isOptionEqualToValue={(option: any, value) => option.full_name === value.full_name}
                        getOptionLabel={(option: any) => option.full_name}
                        options={(!customersList.loading && !customersList.error) ? customersList.result?.data : []}
                        loading={customersList.loading}
                        onChange={(event: any, newValue: any,) => {
                            setForm({
                                ...form,
                                values: {
                                    ...form.values,
                                    user: newValue !== null ? newValue.id : '',
                                    full_name: newValue !== null ? newValue.full_name : '',
                                }
                            });
                        }}
                        inputValue={form.values.full_name}
                        onInputChange={(event, newInputValue,) => {
                            setForm({
                                ...form,
                                values: {
                                    ...form.values,
                                    full_name: newInputValue !== null ? newInputValue : '',
                                    user: '',
                                }
                            });
                        }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                helperText={form.validation.message.full_name}
                                error={form.validation.error.full_name}
                                label="ФИО Пользователя / Username / 用户的姓氏名字父名:"
                            />
                        }
                    />
                    <Autocomplete
                        fullWidth
                        freeSolo
                        disabled
                        isOptionEqualToValue={(option: any, value) => option.name === value.name}
                        getOptionLabel={(option: any) => option.name}
                        options={(!storesList.loading && !storesList.error) ? storesList.result?.data : []}
                        loading={storesList.loading}
                        onChange={(event: any, newValue: any,) => {
                            setForm({
                                ...form,
                                values: {
                                    ...form.values,
                                    store: newValue !== null ? newValue.id : '',
                                    store_name: newValue !== null ? newValue.name : '',
                                }
                            });
                        }}
                        inputValue={form.values.store_name}
                        onInputChange={(event, newInputValue,) => {
                            setForm({
                                ...form,
                                values: {
                                    ...form.values,
                                    store_name: newInputValue !== null ? newInputValue : '',
                                    store: ''
                                }
                            });
                        }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label="Магазин / Store / 店铺:"
                                helperText={form.validation.message.store}
                                error={form.validation.error.store}
                            />
                        }
                    />
                    <FormControl fullWidth>
                        <InputLabel>Страна / Country / 国家:</InputLabel>
                        <Select
                            label='Страна / Country / 国家:'
                            value={form.values.country}
                            onChange={(event) => {
                                setForm({
                                    ...form,
                                    values: {
                                        ...form.values,
                                        country: `${event.target.value}`
                                    }
                                })
                            }}
                            required
                            disabled
                            error={form.validation.error.country}
                        >
                            {countriesList.loading
                                ? <div>loading</div>
                                : countriesList.error
                                    ? <div>Error</div>
                                    : countriesList.result?.data.map((item: any) => (
                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                    ))
                            }
                        </Select>
                        <FormHelperText>{form.validation.message.country}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Страна получателя:</InputLabel>
                        <Select
                            label='Страна получателя:'
                            disabled
                            value={form.values.delivery_country}
                            onChange={(event) => {
                                setForm({
                                    ...form,
                                    values:{
                                        ...form.values,
                                        delivery_country: event.target.value
                                    }
                                })
                            }}
                            required
                            error={form.validation.error.delivery_country}
                        >
                            {personalCountriesList.loading
                                ? <div>loading</div>
                                : personalCountriesList.error
                                    ? <div>Error</div>
                                    : personalCountriesList.result?.data.map((item: any) => (
                                        <MenuItem key={item.id} value={item.id}>{item.country}</MenuItem>
                                    ))
                            }
                        </Select>
                        <FormHelperText>{form.validation.message.delivery_country}</FormHelperText>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Транспорт / Transport / 运输:</InputLabel>
                        <Select
                            disabled
                            label='Транспорт / Transport / 运输:'
                            value={form.values.type_of_transport}
                            onChange={(event) => {
                                setForm({
                                    ...form,
                                    values: {
                                        ...form.values,
                                        type_of_transport: event.target.value
                                    } })
                            }}
                            required
                            error={form.validation.error.type_of_transport}
                        >
                            {transportsList.loading
                                ? <div>loading</div>
                                : transportsList.error
                                    ? <div>Error</div>
                                    : transportsList.result?.data.map((item: any) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            <img src={item.icon} alt="icon"/>
                                        </MenuItem>
                                    ))
                            }
                        </Select>
                        <FormHelperText>{form.validation.message.type_of_transport}</FormHelperText>
                    </FormControl>
                    <TextField
                        value={form.values.weight}
                        label='Фактический вес / Actual weight / 实际重量:'
                        fullWidth
                        required
                        disabled
                        type='number'
                        helperText={form.validation.message.weight}
                        error={form.validation.error.weight}
                        onChange={(event) => {
                            if (event.target.value >= '0' || event.target.value === '') {
                                setForm({
                                    ...form,
                                    values: {
                                        ...form.values,
                                        weight: event.target.value,
                                    }});
                            }
                        }}
                    />
                    <TextField
                        value={form.values.rounded_weight}
                        label='Округленный вес'
                        fullWidth
                        required
                        type='number'
                        helperText={form.validation.message.rounded_weight}
                        error={form.validation.error.rounded_weight}
                        onChange={(event) => {
                            if (event.target.value >= '0' || event.target.value === '') {
                                setForm({
                                    ...form,
                                    values: {
                                        ...form.values,
                                        rounded_weight: event.target.value,
                                    }});
                            }
                        }}
                    />
                    <TextField
                        value={form.values.comment}
                        label='Комментарий / Comment / 评论:'
                        fullWidth
                        helperText={form.validation.message.comment}
                        error={form.validation.error.comment}
                        onChange={(event) => {
                            setForm({
                                ...form,
                                values: {
                                    ...form.values,
                                    comment: event.target.value,
                                }
                            })
                        }}
                    />
                    {!countriesList.loading && !countriesList.error && countriesList.result?.data.find((country: any)=> form.values.country === country.id) && countriesList.result?.data.find((country: any)=> form.values.country === country.id).code === 'USA' &&
                        <Stack direction='row' spacing='20px' width='100%' justifyContent='center' marginBottom='20px'>
                            <FormControlLabel control={<Checkbox disabled={true} checked={form.values.leave_shoe_boxes}/>} label={'Leave shoe boxes'}/>
                            <FormControlLabel control={<Checkbox disabled={true} checked={form.values.leave_gift_boxes}/>} label={'Leave gift boxes (bulk weight)'}/>
                        </Stack>
                    }
                    <Stack direction='row' spacing='20px'>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                navigate({
                                    pathname: '/sorting',
                                })
                            }}
                        >
                            Отменить
                        </Button>
                        <LoadingButton
                            variant='contained'
                            type='submit'
                            loading={form.requested}
                            disabled={form.requested}
                        >
                            Сохранить
                        </LoadingButton>
                    </Stack>
                </form>
            </div>
        </>
    );
}

