import {useAsync} from "react-async-hook";
import {$axios} from "../http/axiosInstance";
import {CreateSearchParams} from "../helpers/helpers";

export const CustomerService = {
    GetCustomers(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/customers/' + CreateSearchParams(searchParamsObj));
        }, [CreateSearchParams(searchParamsObj)])
    },
    GetCustomersListBySearch(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get(`/admin/customer/search/` + CreateSearchParams(searchParamsObj));
        }, [CreateSearchParams(searchParamsObj)])
    },

    GetCustomersExcel() {
        return useAsync(async () => {
            return await $axios.get('/admin/customers/excel/');
        }, [])
    },
    GetCustomerInfo(id: string | undefined) {
        return useAsync(async () => {
            return await $axios.get(`/admin/customer/${id}/`);
        }, [])
    },
    async CustomerUpdate(id: string | undefined, customerData: {
        is_verified: string,
        cashback_percent: string,
    }) {
        return await $axios.put(`/admin/customer-update/${id}/`, {...customerData})
    }
}