import React, { useEffect, useState } from "react";
import "../../styles/queueUserPanel.css";
import { DataGrid, GridColDef, GridFooterContainer } from "@mui/x-data-grid";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import SyncIcon from "@mui/icons-material/Sync";
import { useInterval } from "usehooks-ts";
import { QueueService } from "../../services/QueueService";
import bell from "../../assets/bell.mp3";

function QueueUserPanel() {
  const [reloadRequest, setReloadRequest] = useState<any>(false);
  const [isAudioPermissionGranted, setIsAudioPermissionGranted] =
    useState(false);
  const queueList = QueueService.GetQueueList(reloadRequest, 5);

  const playBellSound = () => {
    const audio = new Audio(bell);
    audio.play().catch((error) => {
      console.error("Audio play failed:", error);
    });
  };

  const requestAudioPermission = () => {
    alert("Разрешить воспроизведение звука?");
    setIsAudioPermissionGranted(true);
    playBellSound();
  };

  const mainTableColumns: GridColDef[] = [
    { field: "id", headerName: "", hide: true, width: 140 },
    { field: "user_id", headerName: "ID клиента", width: 300 },
    {
      field: "status_icon",
      headerName: "",
      width: 100,
      renderCell: (params) => {
        return (
          <div>
            {params.row.status.slug === "ready" && (
              <KeyboardDoubleArrowRightIcon style={{ color: "#009D35" }} />
            )}
            {params.row.status.slug === "waiting" && (
              <SyncIcon style={{ color: "#E52EC7" }} />
            )}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Статус",
      width: 300,
      renderCell: (params) => {
        return (
          <div>
            {params.row.status.slug === "ready" && (
              <p style={{ color: "#009D35" }}>{params.row.status.name}</p>
            )}
            {params.row.status.slug === "waiting" && (
              <p style={{ color: "#E52EC7" }}>{params.row.status.name}</p>
            )}
          </div>
        );
      },
    },
    { field: "box", headerName: "Ящик", width: 150 },
  ];
  const [mainTableData, setMainTableData] = useState<any>([]);
  const [previousData, setPreviousData] = useState<any>([]);

  useInterval(() => {
    setReloadRequest((prevState: any) => {
      return !prevState;
    });
  }, 3000);

  useEffect(() => {
    if (!queueList.loading && !queueList.error) {
      const data =
        queueList.result?.data.map((item: any, index: any) => ({
          id: index,
          user_id: item.user_id,
          status: item.status,
          box: item.orders_box,
        })) || [];

      if (data.length !== previousData.length) {
        console.log("Data length has changed");
        playBellSound();
      } else {
        const newItems = data.filter(
          (item: any) =>
            !previousData.some(
              (prevItem: any) => prevItem.user_id === item.user_id
            )
        );

        const newReadyStatuses = data.filter(
          (item: any) =>
            item.status.slug === "ready" &&
            !previousData.some(
              (prevItem: any) =>
                prevItem.user_id === item.user_id &&
                prevItem.status.slug === "ready"
            )
        );

        if (newItems.length > 0 || newReadyStatuses.length > 0) {
          playBellSound();
        }
      }

      setMainTableData(data);
      setPreviousData(data);
    }
  }, [queueList.loading, queueList.error, queueList.result]);

  return (
    <div className="queueUserPanel" id="queueUserPanel">
      <div className="queueUserPanel__header"></div>
      <div className="queueUserPanel__body">
        <h1>Очередь</h1>

        <div
          style={{
            width: "1000px",
            background: "white",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            marginRight: "100px",
            overflow: "hidden",
            alignSelf: "flex-end",
          }}
        >
          <DataGrid
            rows={mainTableData}
            columns={mainTableColumns}
            disableColumnFilter
            rowHeight={100}
            sx={{ top: 0 }}
            filterMode="server"
            autoHeight
            localeText={{
              noRowsLabel: "Нет информации",
            }}
            disableSelectionOnClick
            components={{
              Footer: () => {
                return <GridFooterContainer></GridFooterContainer>;
              },
            }}
          />
        </div>
      </div>

      {!isAudioPermissionGranted && (
        <div className="queueUserPanel__footer text-white fixed bottom-0">
          <button onClick={requestAudioPermission}>
            Разрешить воспроизведение звука?
          </button>
        </div>
      )}
    </div>
  );
}

export default QueueUserPanel;
