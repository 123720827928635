import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
export const accessRules: any = {
  "/home": [
    {
      position: "Супер-админ",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Кассир",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Складовщик",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Администратор",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Сортировщик",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Менеджер",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },

    {
      position: "Курьер",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
  ],
  "/customers": [
    {
      position: "Супер-админ",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Администратор",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Менеджер",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
  ],
  "/warehouse": [
    {
      position: "Супер-админ",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Администратор",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Складовщик",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
  ],
  "/ransom": [
    {
      position: "Супер-админ",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Администратор",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Менеджер",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
  ],
  "/sorting": [
    {
      position: "Супер-админ",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Администратор",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Сортировщик",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
  ],
  "/orders": [
    {
      position: "Супер-админ",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Администратор",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Менеджер",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
  ],
  "/deliveryOrders": [
    {
      position: "Супер-админ",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Администратор",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Менеджер",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
  ],
  "/boxOffice": [
    {
      position: "Супер-админ",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Администратор",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Кассир",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
  ],
  "/queue": [
    {
      position: "Супер-админ",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Администратор",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Сортировщик",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
  ],
  "/lostOrders": [
    {
      position: "Супер-админ",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Администратор",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
  ],
  "/tariff": [
    {
      position: "Супер-админ",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Администратор",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
  ],
  "/notifications": [
    {
      position: "Супер-админ",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Администратор",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
  ],
  "/personal": [
    {
      position: "Супер-админ",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
  ],
  "/order-pick-up-points": [
    {
      position: "Супер-админ",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Администратор",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Менеджер",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Сортировщик",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    {
      position: "Кассир",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
    // {
    //   position: "Складовщик",
    //   privileges: {
    //     add: true,
    //     edit: true,
    //     delete: true,
    //   },
    // },
    {
      position: "Курьер",
      privileges: {
        add: true,
        edit: true,
        delete: true,
      },
    },
  ],
};

const MiddleWare = ({ children }: any) => {
  const location = useLocation();
  const userData = useSelector((state: any) => state.userData);
  const isOnLoginPage = location.pathname.includes("login");

  if (userData.authed) {
    if (isOnLoginPage) {
      return <Navigate to="/home" replace />;
    }
    if (location.pathname === "/") {
      return <Navigate to="/home" replace />;
    }

    for (const pageName in accessRules) {
      if (location.pathname.includes(pageName)) {
        const allowedRoles = accessRules[pageName];
        if (
          allowedRoles.some((role: any) =>
            role.position.includes(userData.user?.group)
          )
        ) {
          return children;
        } else {
          return <Navigate to="/denied-permission" replace />;
        }
      }
    }

    return children;
  }

  if (!userData.authed && !isOnLoginPage) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default MiddleWare;
