import React, {useEffect, useState} from 'react';
import '../../styles/ransom.css';
import {DataGrid, GridColDef, GridFooterContainer} from "@mui/x-data-grid";
import {createSearchParams, useNavigate} from "react-router-dom";
import {Button, IconButton, Stack} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import {TariffService} from "../../services/TariffService";

function Tariff() {
    const navigate = useNavigate();
    const tariffList = TariffService.GetTariffList()
    const mainTableColumns: GridColDef[] = [
        {field: 'id', headerName: 'ID', flex: 1,},
        {field: 'country', headerName: 'Страна отправки', flex: 1,},
        {field: 'delivery_country', headerName: 'Страна доставки', flex: 1,},
        {
            field: 'type_of_transport', headerName: 'Транспорт', flex: 1, renderCell: (params) => {
                return (<img src={params.row.type_of_transport} alt="icon"/>)
            }
        },
        {field: 'tariff', headerName: 'Тариф', flex: 1,},
        {field: 'note', headerName: 'Примечание', flex: 1,},
        {
            field: 'edit', headerName: '', width: 80, renderCell: (params) => {
                return (
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                         onClick={(event) => {
                             event.stopPropagation()
                         }}>
                        <IconButton color="secondary" aria-label="add an alarm" onClick={() => {
                            navigate({
                                pathname: '/tariff/details',
                                search: createSearchParams({
                                    action: 'edit',
                                    tariff: params.row.id
                                }).toString()
                            })
                        }}>
                            <EditIcon style={{color: "#B9B9B9"}}/>
                        </IconButton>
                        <IconButton color="secondary" aria-label="add an alarm" onClick={() => {
                            TariffService.TariffDelete(params.row).then((res) => {
                                tariffList.execute()
                            })
                        }}>
                            <DeleteIcon style={{color: "#B9B9B9"}}/>
                        </IconButton>
                    </div>
                )
            }
        },
    ];
    const [mainTableData, setMainTableData] = useState<any>([])
    useEffect(() => {
        if (!tariffList.loading && !tariffList.error) {
            const data: any = []
            for (let i = 0; i < tariffList.result?.data.length; i++) {
                data[i] = {
                    id: tariffList.result?.data[i].id,
                    country: tariffList.result?.data[i].country.name,
                    delivery_country: tariffList.result?.data[i].delivery_country.country,
                    type_of_transport: tariffList.result?.data[i].type_of_transport.icon,
                    tariff: tariffList.result?.data[i].tariff,
                    note: tariffList.result?.data[i].note,
                }
            }
            setMainTableData(data)
        }
    }, [tariffList.loading, tariffList.error, tariffList.result?.data])
    return (
        <>
            <p className="componentMainWrapper__title">Тарифы</p>
            <span className="componentMainWrapper__subTitle">Товарищи! укрепление и развитие структуры в значительной степени обуславливает создание новых предложений. </span>

            <Stack direction='row' spacing='20px' justifyContent='space-between' alignItems='center' width='100%'
                   marginBottom='20px'>

                <Stack>

                </Stack>
                <Stack direction='row' spacing='20px'>
                    <Button
                        sx={{
                            width: "200px",
                            height: "36px",
                            background: "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
                            boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
                            borderRadius: "4px",
                            color: 'white',
                        }}
                        onClick={() => {
                            navigate({
                                pathname: '/tariff/details',
                                search: createSearchParams({
                                    action: 'add',
                                }).toString()
                            })
                        }}
                    >
                        Добавить тариф
                    </Button>
                </Stack>
            </Stack>

            <div style={{width: '100%', borderRadius: '10px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'}}>
                <DataGrid
                    rows={mainTableData}
                    columns={mainTableColumns}
                    disableColumnFilter
                    filterMode='server'
                    autoHeight
                    loading={tariffList.loading}
                    localeText={{
                        noRowsLabel: "Нет информации",
                      }}
                    disableSelectionOnClick
                    components={{
                        Footer: () => {
                            return (
                                <GridFooterContainer>
                                </GridFooterContainer>
                            );
                        },
                    }}
                />
            </div>
        </>
    );
}

export default Tariff;

