import React, { useEffect, useState } from "react";
import "../../styles/customerView.css";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Avatar,
  Button,
  Dialog,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { CustomerService } from "../../services/CustomerService";

function CustomersView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const customerInfo = CustomerService.GetCustomerInfo(id);
  const [customerData, setCustomerData] = useState<any>({
    is_verified: "false",
    cashback_percent: "0",
  });
  const [dialog, setDialog] = useState({
    open: false,
    img: "",
    side: "",
  });
  useEffect(() => {
    if (!customerInfo.loading && !customerInfo.error) {
      setCustomerData({
        is_verified: `${customerInfo.result?.data.is_verified}`,
        cashback_percent: `${customerInfo.result?.data.cashback_percent}`,
      });
    }
  }, [customerInfo.loading]);
  return (
    <>
      <p className="componentMainWrapper__title">Просмотр клиента</p>
      <span className="componentMainWrapper__subTitle">
        Товарищи! укрепление и развитие структуры в значительной степени
        обуславливает создание новых предложений.{" "}
      </span>

      {customerInfo.loading ? (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <CircularProgress
            style={{ marginTop: "20px", marginBottom: "20px" }}
          />
        </div>
      ) : (
        <div className="personalPage__mainContainer">
          <div className="personalPage__userInfo">
            <div className="personalPage-userInfo__header">
              <div className="userInfo-header__avatarBox">
                <Avatar></Avatar>
                <div className="userInfo-header__avatarBoxTitle">
                  <h1>
                    {customerInfo.result?.data.user.first_name}{" "}
                    {customerInfo.result?.data.user.last_name}
                  </h1>
                  <div>
                    <span>ID{customerInfo.result?.data.user.id}</span>
                  </div>
                </div>
                <Select
                  id="country-select"
                  size="small"
                  sx={{ position: "absolute", right: "0" }}
                  value={customerData.is_verified}
                  onChange={(event) => {
                    setCustomerData({
                      ...customerData,
                      is_verified: event.target.value,
                    });
                  }}
                >
                  <MenuItem value="true">
                    <p style={{ color: "green" }}>Потвержден</p>
                  </MenuItem>
                  <MenuItem value="false">
                    <p style={{ color: "red" }}>Не потвержден</p>
                  </MenuItem>
                </Select>
              </div>
            </div>
            <div className="personalPage-userInfo__body">
              <div className="userInfo-body__items">
                <p>Почта:</p>
                <span>{customerInfo.result?.data.user.email}</span>
              </div>
              <div className="userInfo-body__items">
                <p>Номер:</p>
                <span>{customerInfo.result?.data.phone}</span>
              </div>
              <div className="userInfo-body__items">
                <p>ИНН:</p>
                <span>{customerInfo.result?.data.inn}</span>
              </div>
              <div className="userInfo-body__items">
                <p>Страна:</p>
                <span>{customerInfo.result?.data.user.country.country}</span>
              </div>
              <div className="userInfo-body__items">
                <p>Город:</p>
                <span>{customerInfo.result?.data.city}</span>
              </div>
              <div className="userInfo-body__items">
                <p>Улица:</p>
                <span>{customerInfo.result?.data.street}</span>
              </div>
              <div className="userInfo-body__items">
                <p>Дом:</p>
                <span>{customerInfo.result?.data.house_number}</span>
              </div>
              <div className="userInfo-body__items">
                <p>ПВЗ:</p>
                <span>{customerInfo.result?.data?.pick_up_point?.name}</span>
              </div>
            </div>
            <Stack direction="row" spacing="20px" sx={{ margin: "20px" }}>
              <Button
                sx={{
                  width: "200px",
                  height: "36px",
                  background:
                    "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
                  boxShadow:
                    "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
                  borderRadius: "4px",
                  color: "white",
                }}
                disabled={
                  customerData.is_verified ===
                    `${customerInfo.result?.data.is_verified}` &&
                  customerData.cashback_percent ===
                    `${customerInfo.result?.data.cashback_percent}`
                }
                onClick={() => {
                  CustomerService.CustomerUpdate(id, customerData)
                    .then((res) => {
                      navigate("/customers");
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                Применить
              </Button>
              <Button
                variant="contained"
                sx={{
                  background: "rgba(72,165,209,0.7)",
                  height: "36px",
                  width: "120px",
                  textTransform: "uppercase",
                }}
                onClick={() => {
                  navigate({
                    pathname: "/customers",
                  });
                }}
              >
                Отменить
              </Button>
            </Stack>
          </div>
          <div className="personalPage__balanceInfo">
            <div className="personalPage-balanceInfo__balanceText">
              <h1>Процент кэшбека</h1>
              <TextField
                value={customerData.cashback_percent}
                sx={{ width: "200px" }}
                label="кэшбек"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                onChange={(event) => {
                  setCustomerData({
                    ...customerData,
                    cashback_percent: event.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="personalPage__passportInfo">
            <div className="personalPage-passportInfo__title">
              <h1>Паспортные данные</h1>
            </div>
            <div className="personalPage-passportInfo__images">
              <div
                className="passportInfo-images__imgBox"
                onClick={() => {
                  setDialog({
                    open: true,
                    img: customerInfo.result?.data.passport_front_side,
                    side: "front",
                  });
                }}
              >
                <p className="passportInfo-images__imgBoxTitle">
                  Лицевая сторона
                </p>
                <span className="passportInfo-images__imgBoxSubTitle">
                  Просмотреть
                </span>
              </div>
              <div
                className="passportInfo-images__imgBox"
                onClick={() => {
                  setDialog({
                    open: true,
                    img: customerInfo.result?.data.passport_back_side,
                    side: "back",
                  });
                }}
              >
                <p className="passportInfo-images__imgBoxTitle">
                  Оборотная сторона
                </p>
                <span className="passportInfo-images__imgBoxSubTitle">
                  Просмотреть
                </span>
              </div>
              <Dialog
                onClose={() => {
                  setDialog({
                    ...dialog,
                    open: false,
                  });
                }}
                open={dialog.open}
              >
                <div className="passportDialogContainer">
                  <h1>
                    {dialog.side === "front" && "Лицевая сторона паспорта"}
                    {dialog.side === "back" && "Задняя сторона паспорта"}
                  </h1>
                  <img src={dialog.img} alt="img" />
                  <div>
                    <Button
                      onClick={() => {
                        setDialog({
                          ...dialog,
                          open: false,
                        });
                      }}
                    >
                      Закрыть
                    </Button>
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CustomersView;
