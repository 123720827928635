import React from 'react';
import '../styles/footer.css'

function Footer() {
    return (
        <div className='w-full flex flex-col justify-start items-start mt-[70px]' id='footer'>
            <div className="footer__subContainer">
                Global Express 2022
            </div>
        </div>

    );
}

export default Footer;