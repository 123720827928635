import {useAsync} from "react-async-hook";
import {$axios} from "../http/axiosInstance";
import {CreateSearchParams} from "../helpers/helpers";

export const RansomService = {
    GetRansomList(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/ransoms/' + CreateSearchParams(searchParamsObj));
        }, [CreateSearchParams(searchParamsObj)])
    },
    GetRansomInfo(ransom_id: any) {
        return useAsync(async () => {
            return await $axios.get(`/admin/ransoms/${ransom_id}/`);
        }, [])
    },
    async GetRansom(ransom_id: any) {
        return await $axios.get(`/admin/ransoms/${ransom_id}/`)
    },
    async CreateRansom(orderInfo: any) {
        return await $axios.post('/admin/ransoms/create/', {...orderInfo})
    },
    async UpdateRansom(orderInfo: any, ransom_id: any) {
        return await $axios.put(`/admin/ransoms/${ransom_id}/update/`, {...orderInfo})
    }

}