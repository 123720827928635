import React, {useEffect, useState} from 'react'
import {DataGrid, GridFooterContainer} from "@mui/x-data-grid";
import {
    Button, Pagination,
    Stack, TextField,
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import '../../styles/arrangeDelivery.css'
import moment from "moment/moment";
import {OrderI} from "../../models/OrderI";
import {DeliveryService} from "../../services/DeliveryService";
import {OrdersTableI} from "../../models/OrdersTableI";
import {DeliveryI} from "../../models/DeliveryI";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

const tableFilterInitialState = {
    page: 1,
    total_pages: 1,
    updateTable: false,
}

const initialDeliveryInfo = {
    address: '',
    phone: '',
    payment: '',
    comment: '',
}
export default function DeliveryView() {
    let navigate = useNavigate();
    let {id} = useParams();

    const [deliveryOrdersInfo, setDeliveryOrdersInfo] = useState<DeliveryI>(initialDeliveryInfo)

    const [table, setTable] = useState<OrdersTableI>({
        selectedRows: [],
        filter: tableFilterInitialState,
        rows: [],
        columns:
            [
                {field: 'id', headerName: 'ID', width: 80, hide: true},
                {field: 'created_at', headerName: 'Дата', width: 100},
                {
                    field: 'country', headerName: 'Страна', width: 200, renderCell: (params) => (
                        params.row.country.name
                    )
                },
                {field: 'tracking_number', headerName: 'Трек', width: 300},
                {field: 'rounded_weight', headerName: 'Вес', width: 110},
                {field: 'total_price', headerName: 'Цена', width: 110},
            ]
    })

    const delivery = DeliveryService.GetDelivery(id)
    const deliveryOrders = DeliveryService.GetDeliveryOrderList(table.filter, id)

    useEffect(() => {
        if (!deliveryOrders.loading && !deliveryOrders.error) {
            const data: OrderI[] = []
            for (let i = 0; i < deliveryOrders.result?.data.results.length; i++) {
                let order = deliveryOrders.result?.data.results[i]
                data[i] = {
                    id: order.id,
                    created_at: moment(order.created_at).format('DD.MM.YYYY').toString(),
                    country: order.country,
                    tracking_number: order.tracking_number,
                    rounded_weight: Math.round(order.rounded_weight),
                    total_price: order.total_price,
                }
            }
            setTable((prevState) => {
                return {
                    ...prevState,
                    filter: {
                        ...prevState.filter,
                        total_pages: deliveryOrders.result?.data.total_pages,
                    },
                    rows: data
                }
            })
        }
    }, [deliveryOrders.error, deliveryOrders.loading])

    useEffect(() => {
        if (!delivery.loading && !delivery.error) {
            setDeliveryOrdersInfo((prevState) => ({
                ...prevState,
                address: delivery.result?.data.address,
                phone: delivery.result?.data.phone,
                payment: delivery.result?.data.payment.payment,
                comment: delivery.result?.data.comment,
            }))
        }
    }, [delivery.error, delivery.loading, delivery.result?.data.orders])

    return (
        <>
            <h1 className='componentMainWrapper__title'>Просмотр доставки</h1>
            <form className="arrangeDelivery__mainContainer" onSubmit={(event) => {
                event.preventDefault()
            }}>
                <h1 className="arrangeDelivery-mainContainer__title">
                    Посылки
                </h1>
                <div style={{width: '100%', marginBottom: '50px'}}>
                    <DataGrid
                        rows={table.rows}
                        columns={table.columns}
                        disableColumnFilter
                        filterMode='server'
                        onRowDoubleClick={(params) => {
                            navigate(`/orders/orderViewPage/${params.row.id}`)
                        }}
                        loading={delivery.loading}
                        localeText={{
                            noRowsLabel: "Нет информации",
                          }}
                        autoHeight
                        components={{
                            Footer: () => {
                                return (
                                    <GridFooterContainer>
                                        <Pagination
                                            count={table.filter.total_pages}
                                            page={table.filter.page}
                                            onChange={(event, value: number) => {
                                                setTable({
                                                    ...table,
                                                    filter: {
                                                        ...table.filter,
                                                        page: value
                                                    }
                                                })
                                            }}
                                        />
                                    </GridFooterContainer>
                                );
                            },
                        }}
                    />
                </div>
                <div style={{
                    width: '100%',
                    display: "flex",
                    flexDirection: 'column',
                    gap: '20px',
                    justifyContent: "center",
                    marginBottom: '50px'
                }}>
                    <Stack width={'100%'} spacing={'20px'} direction={'row'}>
                        <div className='arrangeDelivery__selectBox'>
                            <h1 className="arrangeDelivery-selectBox__title">
                                Адреса
                            </h1>
                            <TextField
                                value={deliveryOrdersInfo.address}
                                sx={{width: '100%'}}
                                size='small'
                            />
                        </div>
                        <div className='arrangeDelivery__selectBox'>
                            <h1 className="arrangeDelivery-selectBox__title">
                                Телефон
                            </h1>
                            <TextField
                                value={deliveryOrdersInfo.phone}
                                sx={{width: '100%'}}
                                size='small'
                            />
                        </div>
                        <div className='arrangeDelivery__selectBox'>
                            <h1 className="arrangeDelivery-selectBox__title">
                                Способ оплаты
                            </h1>
                            <TextField
                                value={deliveryOrdersInfo.payment}
                                sx={{width: '100%'}}
                                size='small'
                            />
                        </div>
                    </Stack>
                    <div style={{width: '100%'}}>
                        <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                            <ChatBubbleOutlineIcon/><p>{deliveryOrdersInfo.comment}</p>
                        </div>
                    </div>
                </div>
                <div style={{width: '100%', display: "flex", justifyContent: "center"}}>
                    <Stack spacing={'20px'} direction={"row"}>
                        <Button variant="contained"
                                className='orderAdd-mainContainer__footerOrderCLoseButton'
                                onClick={() => {
                                    navigate('/deliveryOrders')
                                }}>
                            Назад
                        </Button>
                    </Stack>
                </div>
            </form>
        </>
    )
}