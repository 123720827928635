import React, { useEffect, useState } from "react";
import "../../styles/ransom.css";
import {
  DataGrid,
  GridColDef,
  GridFooterContainer,
  GridHeader,
} from "@mui/x-data-grid";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { QueueService } from "../../services/QueueService";

function Queue() {
  const navigate = useNavigate();
  const [refresh_queue, setRefresh_queue] = useState(false);
  const queueList = QueueService.GetQueueList(refresh_queue, 0);
  const queueStatus = QueueService.GetQueueStatusList();
  const queueBoxList = QueueService.GetQueueBoxList();
  const mainTableColumns: GridColDef[] = [
    { field: "id", headerName: "No", width: 80, hide: true },
    { field: "user_name", headerName: "Клиент", width: 150 },
    { field: "user_id", headerName: "ID", width: 80 },
    { field: "order_count", headerName: "Кол-во посылок", width: 140 },
    { field: "summary_weight", headerName: "Общий вес", width: 120 },
    {
      field: "status",
      headerName: "Статус готовности",
      width: 170,
      renderCell: (params) => {
        return (
          <div>
            {params.row.status === "ready" && (
              <p style={{ color: "#00989d" }}>Готов</p>
            )}
            {params.row.status === "waiting" && (
              <p style={{ color: "#9d7e00" }}>Ожидание</p>
            )}
          </div>
        );
      },
    },
    { field: "box", headerName: "Ящик", width: 90 },
    // { field: 'stick', headerName: 'Стикер', width: 100, renderCell: (params) => {
    //         return (
    //             params.row.stick !== null &&
    //             <IconButton color="secondary" aria-label="add an alarm" onClick={()=>{
    //                 window.location.assign(params.row.stick)
    //             }} >
    //                 <StickyNote2Icon />
    //             </IconButton>
    //         )
    //     }},
    { field: "comment", headerName: "Комментарий", width: 360 },
  ];
  const [queueStatusInfo, setQueueStatusInfo] = useState<any>({
    status: "",
    box: "",
    bills: [],
  });
  const [mainTableData, setMainTableData] = useState<any>([]);

  useEffect(() => {
    if (!queueList.loading && !queueList.error) {
      const data: any = [];
      for (let i = 0; i < queueList.result?.data.length; i++) {
        data[i] = {
          id: queueList.result?.data[i].id,
          user_name: queueList.result?.data[i].user_full_name,
          user_id: queueList.result?.data[i].user_id,
          order_count: queueList.result?.data[i].orders_count,
          summary_weight: Math.round(
            queueList.result?.data[i].orders_total_wight
          ),
          status: queueList.result?.data[i].status.slug,
          box: queueList.result?.data[i].orders_box,
          // stick: queueList.result?.data[i].orders_sticker ? queueList.result?.data[i].orders_sticker : null,
          comment: queueList.result?.data[i].comment,
        };
      }
      setMainTableData(data);
    }
  }, [queueList.loading]);

  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    const id: any = setInterval(() => {
      setRefresh_queue(!refresh_queue);
    }, 10000);

    setIntervalId(id);

    return () => clearInterval(id);
  }, [refresh_queue]);

  return (
    <>
      <p className="componentMainWrapper__title">Выдача </p>
      <span className="componentMainWrapper__subTitle">
        Товарищи! укрепление и развитие структуры в значительной степени
        обуславливает создание новых предложений.{" "}
      </span>

      <div
        style={{
          width: "100%",
          borderRadius: "10px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <DataGrid
          rows={mainTableData}
          columns={mainTableColumns}
          checkboxSelection
          keepNonExistentRowsSelected
          disableColumnFilter
          filterMode="server"
          autoHeight
          localeText={{
            noRowsLabel: "Нет информации",
          }}
          loading={queueList.loading}
          //   onSelectionModelChange={(value) => {
          //     setQueueStatusInfo({
          //       ...queueStatusInfo,
          //       status: "",
          //       box: "",
          //       bills: value,
          //     });
          //   }}
          //   onSelectionModelChange={(value) => {
          //     setQueueStatusInfo((prevQueueStatusInfo: any) => ({
          //       ...prevQueueStatusInfo,
          //       bills: value,
          //     }));
          //   }}

          onSelectionModelChange={(value) => {
            if (value.length > 0) {
              setQueueStatusInfo({
                ...queueStatusInfo,
                status: "",
                box: "",
                bills: value,
              });
            } else {
              setQueueStatusInfo({
                status: "",
                box: "",
                bills: [],
              });
            }
          }}
          disableSelectionOnClick
          onRowDoubleClick={(params) => {
            navigate(`queueView/${params.row.id}`);
          }}
          components={{
            Header: () => {
              return <GridHeader></GridHeader>;
            },
            Footer: () => {
              return (
                <GridFooterContainer>
                  <Stack direction="row" spacing="20px" sx={{ margin: "20px" }}>
                    <FormControl
                      sx={{ width: "300px", height: 40 }}
                      size="small"
                    >
                      <InputLabel id="queue_select">Поменять статус</InputLabel>
                      <Select
                        labelId="queue_select"
                        id="queue_select"
                        label="Поменять статус"
                        value={queueStatusInfo.status}
                        onChange={(event) => {
                          setQueueStatusInfo({
                            ...queueStatusInfo,
                            box: "",
                            status: event.target.value,
                          });
                        }}
                        disabled={!(queueStatusInfo.bills.length > 0)}
                        defaultValue=""
                      >
                        {queueStatus.loading ? (
                          <div>loading</div>
                        ) : queueStatus.error ? (
                          <div>Error</div>
                        ) : (
                          queueStatus.result?.data.map((item: any) => (
                            <MenuItem
                              key={item.id}
                              id={item.id}
                              value={item.slug}
                            >
                              {item.name}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </FormControl>
                    <FormControl
                      sx={{ width: "300px", height: 40 }}
                      size="small"
                    >
                      <InputLabel id="queue_select">Ящик</InputLabel>
                      <Select
                        labelId="queue_select"
                        id="queue_select"
                        label="Ящик"
                        value={queueStatusInfo.box}
                        onChange={(event) => {
                          setQueueStatusInfo({
                            ...queueStatusInfo,
                            box: event.target.value,
                          });
                        }}
                        disabled={queueStatusInfo.status !== "ready"}
                        defaultValue=""
                      >
                        {queueBoxList.loading ? (
                          <div>loading</div>
                        ) : queueBoxList.error ? (
                          <div>Error</div>
                        ) : (
                          queueBoxList.result?.data.map((item: any) => (
                            <MenuItem
                              key={item.id}
                              id={item.id}
                              value={item.id}
                            >
                              {item.number}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                    </FormControl>
                  </Stack>
                  <Stack direction="row" spacing="20px" sx={{ margin: "20px" }}>
                    <Button
                      sx={{
                        width: "200px",
                        height: "36px",
                        background:
                          "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
                        boxShadow:
                          "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
                        borderRadius: "4px",
                        color: "white",
                      }}
                      disabled={
                        queueStatusInfo.status === "" ||
                        (queueStatusInfo.status === "ready" &&
                          queueStatusInfo.orders_box === "")
                      }
                      // onClick={() => {

                      //   QueueService.QueueUpdateStatus(queueStatusInfo)
                      //     .then(() => {
                      //       setRefresh_queue(!refresh_queue);
                      //     })
                      //     .catch((err) => {
                      //       console.log(err);
                      //     });
                      // }}

                      // onClick={() => {
                      //   const lastBillToSend =
                      //     queueStatusInfo.bills.length > 0
                      //       ? queueStatusInfo.bills[
                      //           queueStatusInfo.bills.length - 1
                      //         ]
                      //       : null;
                      //   QueueService.QueueUpdateStatus({
                      //     status: queueStatusInfo.status,
                      //     box: queueStatusInfo.box,
                      //     bills:
                      //       lastBillToSend !== null ? [lastBillToSend] : [],
                      //   })
                      //     .then(() => {
                      //       queueList.execute();
                      //     })
                      //     .catch((err) => {
                      //       console.log(err);
                      //     });
                      // }}

                      onClick={() => {
                        const selectedBills = queueStatusInfo.bills;

                        if (selectedBills.length > 0) {
                          const updateInfo = {
                            status: queueStatusInfo.status,
                            box: queueStatusInfo.box,
                            bills: selectedBills,
                          };

                          QueueService.QueueUpdateStatus(updateInfo)
                            .then(() => {
                              setRefresh_queue(!refresh_queue);

                              window.location.reload();
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }
                      }}
                    >
                      Применить
                    </Button>
                  </Stack>
                </GridFooterContainer>
              );
            },
          }}
        />
      </div>
    </>
  );
}

export default Queue;
