import {useAsync} from "react-async-hook";
import {$axios} from "../http/axiosInstance";

export const TransportsService = {
    GetTransports(country_id: string = 'empty') {
        const searchParams = country_id === 'empty' ? '' : country_id === '' ? '' : `?country=${country_id}`
        return useAsync(async () => {
            return await $axios.get('/type-of-transports/' + searchParams);
        }, [country_id])
    }
}