import {useAsync} from "react-async-hook";
import {$axios} from "../http/axiosInstance";
import {CreateSearchParams, RemoveEmptyObj} from "../helpers/helpers";

export const PersonalService = {
    GetStaffList(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/staff/users/' + CreateSearchParams(searchParamsObj));
        }, [CreateSearchParams(searchParamsObj)])
    },
    async DeleteStaff(id: string) {
        return await $axios.delete(`/admin/staff/users/delete/${id}/`)
    },
    async CreateStaff(staff: any) {
        return await $axios.post('/admin/register/staff/', RemoveEmptyObj(staff))
    },
    async EditStaff(staff: any) {
        return await $axios.put(`/accounts/staff/users/${staff.id}/`, RemoveEmptyObj(staff))
    }
}