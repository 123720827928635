import {useAsync} from "react-async-hook";
import {$axios} from "../http/axiosInstance";

export const QueueService = {
    GetQueueList(repeatRequest: boolean, limit?: number) {
        let searchParams = limit === 0 ? '' : `?limit=${limit}`
        return useAsync(async () => {
            return await $axios.get('/admin/bill/queue/' + searchParams);
        }, [`${repeatRequest}`])
    },

    GetQueueStatusList() {
        return useAsync(async () => {
            return await $axios.get('/admin/bill/status-without-courier/');
        }, [])
    },

    GetQueueBoxList() {
        return useAsync(async () => {
            return await $axios.get('/admin/bill/orders-box/');
        }, [])
    },
    async QueueUpdateStatus(data: any) {
        return await $axios.post('/admin/bill/bulk-update-status/', {...data})
    }
}