import {$axios} from "../http/axiosInstance";
import {useAsync} from "react-async-hook";
import {CreateSearchParams} from "../helpers/helpers";

export const DeliveryService = {
    GetDeliveryList(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get(`/admin/delivery/` + CreateSearchParams(searchParamsObj));
        }, [CreateSearchParams(searchParamsObj)])
    },
    GetAcceptDeliveryList(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get(`/admin/bill/accepted/deliveries/` + CreateSearchParams(searchParamsObj));
        }, [CreateSearchParams(searchParamsObj)])
    },
    GetCourierDeliveryList(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get(`/admin/atTheCourier/deliveries/` + CreateSearchParams(searchParamsObj));
        }, [CreateSearchParams(searchParamsObj)])
    },
    GetDeliveryOrderList(searchParamsObj: any, id?: string) {
        return useAsync(async () => {
            return await $axios.get(`/admin/delivery/${id}/orders/` + CreateSearchParams(searchParamsObj));
        }, [CreateSearchParams(searchParamsObj)])
    },
    async SetComment(data: any) {
        return await $axios.post(`/admin/delivery/set/comment/`, data)
    },
    async UpdateDeliveryStatus(data: any) {
        return await $axios.post(`/admin/delivery/status/update/`, data)
    },
    GetDelivery(id: string | undefined) {
        return useAsync(async () => {
            return await $axios.get(`/admin/delivery/${id}`);
        }, [id])
    },
}