import {useAsync} from "react-async-hook";
import {$axios} from "../http/axiosInstance";

export const AuthService = {
    GetStaffInfo() {
        return useAsync(async () => {
            return await $axios.get('/admin/staff/user-info/');
        }, [])
    },
    async PostAuthData(formValues: any) {
        return await $axios.post('/admin/token/', formValues)
    }
}