import {useAsync} from "react-async-hook";
import {$axios} from "../http/axiosInstance";

export const AnalyticsService = {
    GetAnalyticsTotalUsers(period: string) {
        return useAsync(async () => {
            return await $axios.get(`/admin/analytics/total-users/?period=${period}`);
        }, [period])
    },

    GetAnalyticsTotalOrders(period: string) {
        return useAsync(async () => {
            return await $axios.get(`/admin/analytics/total-orders/?period=${period}`);
        }, [period])
    },

    GetAnalyticsTotalOrdersByCountry(period: string) {
        return useAsync(async () => {
            return await $axios.get(`/admin/analytics/total-orders-by-country/?period=${period}`);
        }, [period])
    },

    GetAnalyticsBill(period: string) {
        return useAsync(async () => {
            return await $axios.get(`/admin/analytics/bill/?period=${period}`);
        }, [period])
    },
    GetDiscountInfo(period: any) {
        return useAsync(async () => {
            return await $axios.get(`/admin/analytics/bill/discount/?period=${period}`);
        }, [period])
    }
}