import React, {FormEvent, useEffect, useState} from 'react';
import '../../styles/sortPage.css';
import {DataGrid, GridFooterContainer} from "@mui/x-data-grid";
import {
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Modal,
    Pagination,
    Select,
    TextField,
} from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {createSearchParams, useNavigate} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {AddressesService} from "../../services/AddressesService";
import {SortingService} from "../../services/SortingService";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment/moment";
import CloseIcon from "@mui/icons-material/Close";
import {LoadingButton} from "@mui/lab";
import {checkModalResponse, VisuallyHiddenInput} from "../../helpers/helpers";
import AttachFileIcon from '@mui/icons-material/AttachFile';


const tableInitialValues = {
    rows: [],
    rows1: [],
    filter: {
        delivery_country: "",
        search: "",
        page: 1,
        total_pages: 1,
    },
    filter1: {
        delivery_country: "",
        search: "",
        page: 1,
        total_pages: 1,
    },
    status: {
        loading: false,
        error: false,
        message: "",
    },
    excel: false,
    selectedRows: [],
}

const modalInitialValues = {
    open: false,
    values: {
        excel: null,
        send_all: false,
        orders: [],
        excels: [],
        customer: '',
        delivery_country: '',
    },
    validation: {
        error: {
            excel: false,
            delivery_country: false,
        },
        message: {
            excel: '',
            delivery_country: '',
        },
    },
    requested: false,
    action: '',
};

function SortPage() {
    const navigate = useNavigate()
    const getPersonalCountries = AddressesService.GetPersonalCountries('customer')

    const [table, setTable] = useState<any>({
        ...tableInitialValues,
        columns: [
            {field: 'id', headerName: 'ID', width: 80},
            {
                field: 'code', headerName: 'Код клиента', flex: 1, renderCell: (params: any) =>
                    params.row.user ? params.row.user.id : ''
            },
            {
                field: 'name', headerName: 'ФИО', flex: 1, renderCell: (params: any) =>
                    params.row.user ? params.row.user.full_name : params.row.attribute
            },
            {field: 'tracking_number', headerName: 'Трек номер', flex: 1},
            {
                field: 'store', headerName: 'Магазин', flex: 1, renderCell: (params: any) =>
                    params.row.store?.name
            },
            {field: 'weight', headerName: 'Факт. вес', flex: 1},
            {
                field: 'rounded_weight', headerName: 'Окр. вес', flex: 1, renderCell: (params: any) =>
                    Math.round(params.row.rounded_weight)
            },
            {
                field: 'country', headerName: 'Страна', flex: 1, renderCell: (params: any) =>
                    params.row.country?.name
            },
            {field: 'comment', headerName: 'Комментарий', flex: 1},
            {
                field: 'edit', headerName: '', flex: 1, renderCell: (params: any) =>
                    <IconButton color="secondary" aria-label="add an alarm" onClick={() => {
                        navigate({
                            pathname: "/sorting/details",
                            search: createSearchParams({
                                action: "edit",
                                tracking_number: params.row.tracking_number,
                            }).toString(),
                        });
                    }}>
                        <EditIcon style={{color: "#B9B9B9"}}/>
                    </IconButton>
            },
        ],
        columns1: [
            {field: 'id', headerName: 'ID файла', width: 80},
            {
                field: 'created_at', headerName: 'Дата добавления', flex: 1, renderCell: (params: any) =>
                    params.row.created_at !== null
                        ? moment(params.row.created_at).format('YYYY-MM-DD').toString()
                        : '-'
            },
            {
                field: 'updated_at', headerName: 'Дата отправки', flex: 1, renderCell: (params: any) =>
                    params.row.updated_at !== null
                        ? moment(params.row.updated_at).format('YYYY-MM-DD').toString()
                        : '-'
            },
            {
                field: 'created_excel', headerName: 'Готовый файл', flex: 1, renderCell: (params: any) => {
                    return (
                        params.row.created_excel !== null &&
                        <IconButton onClick={() => {
                            window.location.assign(params.row.created_excel)
                        }}>
                            <FileCopyIcon sx={{color: "#48A5D1", fontSize: '30px'}}/>
                        </IconButton>
                    )
                }
            },
            {
                field: 'uploaded_excel', headerName: 'Загруженный файл', flex: 1, renderCell: (params: any) => {
                    return (
                        params.row.uploaded_excel !== null &&
                        <IconButton onClick={() => {
                            window.location.assign(params.row.uploaded_excel)
                        }}>
                            <FileCopyIcon sx={{color: "#48A5D1", fontSize: '30px'}}/>
                        </IconButton>
                    )
                }
            },
            {
                field: 'weighted_excel', headerName: 'Взвешенный файл', flex: 1, renderCell: (params: any) => {
                    return (
                        params.row.weighted_excel !== null
                            ?
                            <IconButton onClick={() => {
                                window.location.assign(params.row.weighted_excel)
                            }}>
                                <FileCopyIcon sx={{color: "#48A5D1", fontSize: '30px'}}/>
                            </IconButton>
                            :
                            <IconButton color="primary" aria-label="upload picture" component="label">
                                <input
                                    hidden
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    type="file"
                                    required={true}
                                    onChange={(event) => {
                                        handleUploadExcel(event, params.row.id)
                                    }}/>
                                <FileUploadIcon sx={{color: "#48A5D1", fontSize: '30px'}}/>
                            </IconButton>

                    )
                }
            },
        ]
    })

    const [modal, setModal] = useState<any>({
        ...modalInitialValues
    })

    const excelsList = SortingService.GetExcelsList({
        ...table.filter1,
        ...table.filter1.date_from !== null ? {date_from: moment(table.filter1.date_from?.$d).format('YYYY-MM-DD')} : {},
        ...table.filter1.date_to !== null ? {date_to: moment(table.filter1.date_to?.$d).format('YYYY-MM-DD')} : {},
    })
    const ordersList = SortingService.GetOrdersList({
        ...table.filter,
        ...table.filter.date_from !== null ? {date_from: moment(table.filter.date_from?.$d).format('YYYY-MM-DD')} : {},
        ...table.filter.date_to !== null ? {date_to: moment(table.filter.date_to?.$d).format('YYYY-MM-DD')} : {},
    })

    useEffect(() => {
        if (excelsList.loading) {
            setTable((prevState: any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: true,
                },
            }));
        } else if (excelsList.error) {
            setTable((prevState: any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: false,
                    error: true,
                },
            }));
        } else {
            const data = excelsList.result?.data;
            setTable((prevState: any) => ({
                ...prevState,
                rows1: data.results,
                status: {
                    ...prevState.status,
                    loading: false,
                    error: false,
                },
                filter1: {
                    ...prevState.filter1,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
                selectedRows: []
            }));
        }
    }, [excelsList.loading, excelsList.error, excelsList.result?.data]);
    useEffect(() => {
        if (ordersList.loading) {
            setTable((prevState: any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: true,
                },
            }));
        } else if (ordersList.error) {
            setTable((prevState: any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: false,
                    error: true,
                },
            }));
        } else {
            const data = ordersList.result?.data;
            setTable((prevState: any) => ({
                ...prevState,
                rows: data.results,
                status: {
                    ...prevState.status,
                    loading: false,
                    error: false,
                },
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
                selectedRows: []
            }));
        }
    }, [ordersList.loading, ordersList.error, ordersList.result?.data]);

    const handleUploadExcel = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const files = event.target.files
        if (files !== null && files.length > 0) {
            SortingService.UploadExcelInSorting(id, files[0]).then(() => {
                excelsList.execute()
                ordersList.execute()
            }).catch((err) => {
                alert(err.response.data.weighted_excel[0])
            })
        }
    }

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault()
        setModal({
            ...modal,
            requested: true,
        })
        if (modal.action === 'uploadExcel') {
            SortingService.AddExcel({
                excel: modal.values.excel,
                delivery_country: modal.values.delivery_country,
            }).then(() => {
                excelsList.execute()
                ordersList.execute()
                setModal(modalInitialValues)
            }).catch((err) => {
                checkModalResponse(err.response.data, setModal, modal)
            })
        }
        if (modal.action === 'ordersSending') {
            SortingService.SendOrders({
                orders: modal.values.orders,
                send_all: modal.values.send_all,
                customer: modal.values.customer,
                delivery_country: modal.values.delivery_country
            }).then((res) => {
                excelsList.execute()
                ordersList.execute()
                setModal(modalInitialValues)
                window.open(res.data.excel_link, '_blank')
            }).catch((err) => {
                checkModalResponse(err.response.data, setModal, modal)
            })
        }
        if (modal.action === 'excelsSending') {
            SortingService.SendExcels({
                excels: modal.values.orders,
                send_all: modal.values.send_all,
                delivery_country: modal.values.delivery_country
            }).then(() => {
                excelsList.execute()
                ordersList.execute()
                setModal(modalInitialValues)
            }).catch((err) => {
                checkModalResponse(err.response.data, setModal, modal)
            })
        }
    }

    useEffect(() => {
        if (!getPersonalCountries.loading && !getPersonalCountries.error) {
            for (let i = 0; i < getPersonalCountries.result?.data.length; i++) {
                if (getPersonalCountries.result?.data[i].code === 'KGZ') {
                    setTable((prevState: any) => ({
                        ...prevState,
                        filter: {
                            ...prevState.filter,
                            delivery_country: getPersonalCountries.result?.data[i].id,
                        },
                        filter1: {
                            ...prevState.filter1,
                            delivery_country: getPersonalCountries.result?.data[i].id,
                        }
                    }))
                }
            }
        }
    }, [getPersonalCountries.loading, getPersonalCountries.error, getPersonalCountries.result?.data])

    return (
        <>
            <div className='w-full flex items-center gap-[20px] mb-[50px]'>
                <h1 className="componentMainWrapper__title">
                    Сортировка
                </h1>
                <Button
                    disabled={!table.excel}
                    variant='contained'
                    onClick={() => {
                        setTable({
                            ...table,
                            excel: false
                        })
                    }}
                >
                    Посылки
                </Button>
                <Button
                    disabled={table.excel}
                    variant='contained'
                    onClick={() => {
                        setTable({
                            ...table,
                            excel: true
                        })
                    }}
                >
                    Excel
                </Button>
            </div>

            {table.excel
                ?
                <>
                    <div className='w-full flex justify-between items-center mb-[20px]'>
                        <div className='flex items-center gap-[20px]'>
                            <FormControl size="small">
                                <InputLabel>Страна получателя</InputLabel>
                                <Select
                                    value={table.filter1.delivery_country}
                                    label="Страна получателя"
                                    onChange={(event) => {
                                        setTable({
                                            ...table,
                                            filter1: {
                                                ...table.filter1,
                                                delivery_country: event.target.value,
                                            },
                                        });
                                    }}
                                >
                                    {!getPersonalCountries.loading && !getPersonalCountries.error &&
                                        getPersonalCountries.result?.data.map((item: any) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.country}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className='flex items-center gap-[20px]'>
                            <TextField
                                label="Поиск клиента"
                                value={table.filter1.search}
                                sx={{width: "340px"}}
                                size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon style={{fill: "#48A5D1"}}/>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(event) => {
                                    setTable({
                                        ...table,
                                        filter1: {
                                            ...table.filter1,
                                            search: event.target.value,
                                        },
                                    });
                                }}
                            />
                            <Button
                                sx={{
                                    width: "150px",
                                    height: "36px",
                                    background: "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
                                    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
                                    borderRadius: "4px",
                                    color: 'white',
                                }}
                                onClick={() => {
                                    setModal({
                                        ...modal,
                                        open: true,
                                        action: 'uploadExcel'
                                    })
                                }}
                            >
                                Добавить
                            </Button>
                        </div>
                    </div>

                    <div className='w-full rounded-[10px] shadow-md'>
                        <DataGrid
                            rows={table.rows1}
                            keepNonExistentRowsSelected
                            columns={table.columns1}
                            disableColumnFilter
                            filterMode="server"
                            selectionModel={table.selectedRows}
                            localeText={{
                                noRowsLabel: "Нет информации",
                              }}
                            checkboxSelection
                            onSelectionModelChange={(value: any) => {
                                setTable({
                                    ...table,
                                    selectedRows: value
                                })
                            }}
                            isRowSelectable={(params: any) => params.row.updated_at === null && params.row.weighted_excel !== null}
                            autoHeight
                            loading={ordersList.loading || excelsList.loading}
                            disableSelectionOnClick
                            components={{
                                Footer: () => {
                                    return (
                                        <GridFooterContainer>
                                            <Pagination
                                                count={table.filter1.total_pages}
                                                page={table.filter1.page}
                                                onChange={(event, value: number) => {
                                                    setTable((prevState: any) => ({
                                                        ...prevState,
                                                        filter1: {
                                                            ...prevState.filter1,
                                                            page: value,
                                                        }
                                                    }));
                                                }}
                                            />
                                            <div className='flex items-center gap-[20px] pr-[10px]'>
                                                <Button
                                                    disabled={table.rows1.length === 0}
                                                    sx={{
                                                        height: "36px",
                                                        background: "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
                                                        boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
                                                        borderRadius: "4px",
                                                        color: 'white',
                                                    }}
                                                    onClick={() => {
                                                        setModal({
                                                            ...modalInitialValues,
                                                            open: true,
                                                            action: 'excelsSending',
                                                            values: {
                                                                ...modalInitialValues.values,
                                                                excels: table.selectedRows,
                                                                send_all: table.selectedRows.length === 0,
                                                                delivery_country: table.filter1.delivery_country,
                                                                customer: table.filter1.search,
                                                            }
                                                        })
                                                    }}
                                                >
                                                    Отправить
                                                </Button>
                                            </div>
                                        </GridFooterContainer>
                                    );
                                },
                            }}
                        />
                    </div>
                </>
                :
                <>
                    <div className='w-full flex justify-between items-center mb-[20px]'>
                        <div className='flex items-center gap-[20px]'>
                            <FormControl size="small">
                                <InputLabel>Страна получателя</InputLabel>
                                <Select
                                    value={table.filter.delivery_country}
                                    label="Страна получателя"
                                    onChange={(event) => {
                                        setTable({
                                            ...table,
                                            filter: {
                                                ...table.filter,
                                                delivery_country: event.target.value,
                                            },
                                        });
                                    }}
                                >
                                    {!getPersonalCountries.loading && !getPersonalCountries.error &&
                                        getPersonalCountries.result?.data.map((item: any) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.country}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className='flex items-center gap-[20px]'>
                            <TextField
                                label="Поиск клиента"
                                value={table.filter.search}
                                sx={{width: "340px"}}
                                size="small"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon style={{fill: "#48A5D1"}}/>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(event) => {
                                    setTable({
                                        ...table,
                                        filter: {
                                            ...table.filter,
                                            search: event.target.value,
                                        },
                                    });
                                }}
                            />
                            <Button
                                sx={{
                                    width: "150px",
                                    height: "36px",
                                    background: "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
                                    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
                                    borderRadius: "4px",
                                    color: 'white',
                                }}
                                onClick={() => {
                                    if (table.excel) {
                                        setModal({
                                            ...modal,
                                            open: true,
                                            action: 'uploadExcel'
                                        })
                                    } else {
                                        navigate({
                                            pathname: "/sorting/details",
                                            search: createSearchParams({
                                                action: "add",
                                            }).toString(),
                                        });
                                    }
                                }}
                            >
                                Добавить
                            </Button>
                        </div>
                    </div>

                    <div className='w-full rounded-[10px] shadow-md'>
                        <DataGrid
                            rows={table.rows}
                            keepNonExistentRowsSelected
                            columns={table.columns}
                            disableColumnFilter
                            filterMode="server"
                            selectionModel={table.selectedRows}
                            localeText={{
                                noRowsLabel: "Нет информации",
                              }}
                            checkboxSelection
                            onSelectionModelChange={(value: any) => {
                                setTable({
                                    ...table,
                                    selectedRows: value
                                })
                            }}
                            isRowSelectable={(params: any) => params.row.user !== null && table.filter.search !== ''}
                            autoHeight
                            loading={ordersList.loading || excelsList.loading}
                            disableSelectionOnClick
                            components={{
                                Footer: () => {
                                    return (
                                        <GridFooterContainer>
                                            <Pagination
                                                count={table.filter.total_pages}
                                                page={table.filter.page}
                                                onChange={(event, value: number) => {
                                                    setTable((prevState: any) => ({
                                                        ...prevState,
                                                        filter: {
                                                            ...prevState.filter,
                                                            page: value,
                                                        }
                                                    }));
                                                }}
                                            />
                                            <div className='flex items-center gap-[20px] pr-[10px]'>
                                                <Button
                                                    disabled={
                                                        table.filter.search === ''
                                                            ? true
                                                            : table.rows.length === 0
                                                    }
                                                    sx={{
                                                        height: "36px",
                                                        background: "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
                                                        boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
                                                        borderRadius: "4px",
                                                        color: 'white',
                                                    }}
                                                    onClick={() => {
                                                        setModal({
                                                            ...modalInitialValues,
                                                            open: true,
                                                            action: 'ordersSending',
                                                            values: {
                                                                ...modalInitialValues.values,
                                                                orders: table.selectedRows,
                                                                send_all: table.selectedRows.length === 0,
                                                                delivery_country: table.filter.delivery_country,
                                                                customer: table.filter.search,
                                                            }
                                                        })
                                                    }}
                                                >
                                                    Отправить
                                                </Button>
                                            </div>
                                        </GridFooterContainer>
                                    );
                                },
                            }}
                        />
                    </div>
                </>
            }

            <Modal
                open={modal.open}
                onClose={() => setModal(modalInitialValues)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form
                    onSubmit={handleFormSubmit}
                    className="mainModal relative flex flex-col justify-start items-center"
                >
                    <IconButton
                        sx={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                        }}
                        onClick={() => setModal(modalInitialValues)}
                    >
                        <CloseIcon/>
                    </IconButton>
                    <h2 className="text-[30px] font-[600] text-[#1E1C2A] mb-[40px]">
                        {modal.action === 'uploadExcel' && 'Загрузить excel'}
                        {modal.action === 'ordersSending' && 'Отправить посылки?'}
                        {modal.action === 'excelsSending' && 'Отправить excel-файлы?'}
                    </h2>

                    {modal.values.send_all &&
                        <p>
                            Внимание! Вы отправляете ВСЕ записи!
                        </p>
                    }

                    {modal.action === 'uploadExcel' &&
                        <div className="w-full flex flex-col gap-[30px] mb-[50px]">
                            <FormControl fullWidth>
                                <InputLabel>Страна получателя</InputLabel>
                                <Select
                                    value={modal.values.delivery_country}
                                    label="Страна получателя"
                                    error={modal.validation.error.delivery_country}
                                    onChange={(event) => {
                                        setModal({
                                            ...modal,
                                            values: {
                                                ...modal.values,
                                                delivery_country: event.target.value,
                                            },
                                        });
                                    }}
                                >
                                    {!getPersonalCountries.loading && !getPersonalCountries.error &&
                                        getPersonalCountries.result?.data.map((item: any) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.country}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                                <FormHelperText>{modal.validation.message.delivery_country}</FormHelperText>
                            </FormControl>
                            <TextField
                                variant="outlined"
                                fullWidth={true}
                                disabled
                                value={modal.values.excel === null ? 'Выберите файл' : modal.values.excel?.name}
                                error={modal.validation.error.excel}
                                helperText={modal.validation.message.excel}
                                required={true}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        {modal.values.excel !== null
                                            ?
                                            <Button onClick={() => {
                                                setModal({
                                                    ...modal,
                                                    values: {
                                                        ...modal.values,
                                                        excel: null
                                                    }
                                                })
                                            }}>
                                                <CloseIcon/>
                                            </Button>
                                            :
                                            <Button
                                                component="label"
                                                role={undefined}
                                                tabIndex={-1}
                                                variant='contained'
                                            >
                                                <AttachFileIcon/>
                                                <VisuallyHiddenInput
                                                    required type="file"
                                                    onChange={(event) => {
                                                        if (event.target.files) {
                                                            setModal({
                                                                ...modal,
                                                                values: {
                                                                    ...modal.values,
                                                                    excel: event.target.files[0]
                                                                }
                                                            })
                                                        }
                                                    }}
                                                />
                                            </Button>
                                        }
                                    </InputAdornment>,
                                }}
                            />
                        </div>
                    }

                    <div className="flex justify-between gap-[40px] items-center mt-[20px]">
                        <Button
                            type="button"
                            variant='outlined'
                            onClick={() => setModal(modalInitialValues)}
                        >
                            Отмена
                        </Button>
                        <LoadingButton
                            type="submit"
                            disabled={modal.requested}
                            loading={modal.requested}
                            variant='contained'
                        >
                            Готово
                        </LoadingButton>
                    </div>
                </form>
            </Modal>
        </>
    );
}

export default SortPage;

