import React, {useEffect, useState} from 'react';
import '../styles/home.css'
import {AuthService} from "../services/AuthService";
import {AnalyticsService} from "../services/AnalyticsService";
import {useSelector} from "react-redux";

function Home() {
    const user = useSelector((state: any)=> state.userData.user)
    const staffInfo = AuthService.GetStaffInfo()
    const [usersState, setUsersState] = useState<any>({
        period: 'month',
        usersCount: '0',
        usersInflow: '0',
        periodDescription: 'за последние 30 дней',
    })
    const [ordersState, setOrdersState] = useState<any>({
        period: 'month',
        ordersCount: '0',
        ordersInflow: '0',
        periodDescription: 'за последние 30 дней',
    })
    const [ordersStatistic, setOrdersStatistic] = useState<any>({
        period: 'month',
        countries: [],
    })
    const [boxOfficeState, setBoxOfficeState] = useState<any>({
        period: 'month',
        boxOfficeCount: '0',
        boxOfficeInflow: '0',
        periodDescription: 'за последние 30 дней',
    })
    const [discountState, setDiscountState] = useState<any>({
        period: 'month',
        discountCount: '0',
        discountInflow: '0',
        periodDescription: 'за последние 30 дней',
    })
    const usersAnalytics = AnalyticsService.GetAnalyticsTotalUsers(usersState.period)
    const ordersAnalytics = AnalyticsService.GetAnalyticsTotalOrders(ordersState.period)
    const ordersByCountryAnalytics = AnalyticsService.GetAnalyticsTotalOrdersByCountry(ordersStatistic.period)
    const billAnalytics = AnalyticsService.GetAnalyticsBill(boxOfficeState.period)
    const discount = AnalyticsService.GetDiscountInfo(discountState.period)
    useEffect(() => {
        if (!usersAnalytics.loading && !usersAnalytics.error) {
            setUsersState({
                ...usersState,
                usersCount: usersAnalytics.result?.data.total,
                usersInflow: usersAnalytics.result?.data.inflow,
            })
        }
    }, [usersAnalytics.loading])
    useEffect(() => {
        if (!ordersAnalytics.loading && !ordersAnalytics.error) {
            setOrdersState({
                ...ordersState,
                ordersCount: ordersAnalytics.result?.data.total,
                ordersInflow: ordersAnalytics.result?.data.inflow,
            })
        }
    }, [ordersAnalytics.loading])
    useEffect(() => {
        if (!ordersByCountryAnalytics.loading && !ordersByCountryAnalytics.error) {
            const countries = ordersByCountryAnalytics.result?.data.map((country: any) => ({
                name: country.name,
                orders_count: country.orders_count,
                icon: country.icon,
                orders_count_in_percent: country.orders_count_in_percent,
            }))
            setOrdersStatistic({
                ...ordersStatistic,
                countries: countries,
            })
        }
    }, [ordersByCountryAnalytics.loading])
    useEffect(() => {
        if (!billAnalytics.loading && !billAnalytics.error) {
            setBoxOfficeState({
                ...boxOfficeState,
                boxOfficeCount: billAnalytics.result?.data.total_sum,
                boxOfficeInflow: billAnalytics.result?.data.inflow,
            })
        }
    }, [billAnalytics.loading])
    useEffect(() => {
        if (!discount.loading && !discount.error) {
            setDiscountState({
                ...boxOfficeState,
                discountCount: discount.result?.data.total_sum,
                discountInflow: discount.result?.data.inflow,
            })
        }
    }, [discount.loading])

    return (
        <>
            {user?.group === 'Администратор' || user?.group === 'Супер-админ'
                ?
                <>
                    <div className="componentMainWrapper__title">Добро пожаловать,
                        <p>
                            {!staffInfo.loading
                                ? !staffInfo.error &&
                                staffInfo.result?.data.first_name + ' ' + staffInfo.result?.data.last_name
                                : 'Пользователь'
                            }!
                        </p>
                    </div>
                    <span className="componentMainWrapper__subTitle"></span>
                    <div className="homePage__mainContainer">
                        <div className="homePage__card homePage__usersBox">
                            <div className="homePage-card_info">
                                <div className="homePage-card_infoTitle">Общие кол-во пользователей</div>
                                <div className="homePage-card_infoText">{usersState.usersCount}</div>
                                <div className="homePage-card_infoButtonBox">
                                    <p>+ {usersState.usersInflow}</p>
                                    <span>{usersState.periodDescription}</span>
                                </div>
                            </div>
                            <div className="homePage-card__buttons">
                                <button onClick={() => {
                                    setUsersState({
                                        ...usersState,
                                        period: 'month',
                                        periodDescription: 'за последние 30 дней'
                                    })
                                }}
                                        className={`homePage-card__button ${usersState.period === 'month' && 'homePage-card__buttonSelected'}`}
                                >за 30 дней
                                </button>
                                <button onClick={() => {
                                    setUsersState({
                                        ...usersState,
                                        period: 'quarter',
                                        periodDescription: 'за последний квартал'
                                    })
                                }}
                                        className={`homePage-card__button ${usersState.period === 'quarter' && 'homePage-card__buttonSelected'}`}
                                >за Квартал
                                </button>
                                <button onClick={() => {
                                    setUsersState({
                                        ...usersState,
                                        period: 'half-year',
                                        periodDescription: 'за последнее полугодие'
                                    })
                                }}
                                        className={`homePage-card__button ${usersState.period === 'half-year' && 'homePage-card__buttonSelected'}`}
                                >за Полугодие
                                </button>
                                <button onClick={() => {
                                    setUsersState({
                                        ...usersState,
                                        period: 'year',
                                        periodDescription: 'за последний год'
                                    })
                                }}
                                        className={`homePage-card__button ${usersState.period === 'year' && 'homePage-card__buttonSelected'}`}
                                >за Год
                                </button>
                            </div>
                        </div>
                        <div className="homePage__card homePage__ordersBox">
                            <div className="homePage-card_info">
                                <div className="homePage-card_infoTitle">Общие кол-во посылок</div>
                                <div className="homePage-card_infoText">{ordersState.ordersCount}</div>
                                <div className="homePage-card_infoButtonBox">
                                    <p>+ {ordersState.ordersInflow}</p>
                                    <span>{ordersState.periodDescription}</span>
                                </div>
                            </div>
                            <div className="homePage-card__buttons">
                                <button onClick={() => {
                                    setOrdersState({
                                        ...ordersState,
                                        period: 'month',
                                        periodDescription: 'за последние 30 дней'
                                    })
                                }}
                                        className={`homePage-card__button ${ordersState.period === 'month' && 'homePage-card__buttonSelected'}`}
                                >за 30 дней
                                </button>
                                <button onClick={() => {
                                    setOrdersState({
                                        ...ordersState,
                                        period: 'quarter',
                                        periodDescription: 'за последний квартал'
                                    })
                                }}
                                        className={`homePage-card__button ${ordersState.period === 'quarter' && 'homePage-card__buttonSelected'}`}
                                >за Квартал
                                </button>
                                <button onClick={() => {
                                    setOrdersState({
                                        ...ordersState,
                                        period: 'half-year',
                                        periodDescription: 'за последнее полугодие'
                                    })
                                }}
                                        className={`homePage-card__button ${ordersState.period === 'half-year' && 'homePage-card__buttonSelected'}`}
                                >за Полугодие
                                </button>
                                <button onClick={() => {
                                    setOrdersState({
                                        ...ordersState,
                                        period: 'year',
                                        periodDescription: 'за последний год'
                                    })
                                }}
                                        className={`homePage-card__button ${ordersState.period === 'year' && 'homePage-card__buttonSelected'}`}
                                >за Год
                                </button>
                            </div>
                        </div>
                        <div className="homePage__card homePage__ordersStatisticBox">
                            <div className="homePage-card_info">
                                <div className="homePage-card_infoTitle">Общие кол-во посылок</div>
                                <div className="homePage-card_infoStatistics">
                                    {ordersStatistic.countries.map((country: any, index: number) => (
                                        <div className="homePage-card_infoStatistic" key={index}>
                                            <p className='homePage-card_infoStatistic_title'>{country.name}</p>
                                            <div className="homePage-card_infoStatisticFlagBox">
                                                <img src={country.icon} alt=""/>
                                                <div className="homePage-card_infoStatisticBox">
                                                    <div className='homePage-card_infoStatisticBox__text'>
                                                        <p>{country.orders_count_in_percent}%</p>
                                                        <span>(+{country.orders_count})</span></div>
                                                    <div className="homePage-card_infoStatisticBox_rangeBox"
                                                         style={{width: `${country.orders_count_in_percent}%`}}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                            <div className="homePage-card__buttons">
                                <button onClick={() => {
                                    setOrdersStatistic({...ordersStatistic, period: 'month'})
                                }}
                                        className={`homePage-card__button ${ordersStatistic.period === 'month' && 'homePage-card__buttonSelected'}`}
                                >за 30 дней
                                </button>
                                <button onClick={() => {
                                    setOrdersStatistic({...ordersStatistic, period: 'quarter'})
                                }}
                                        className={`homePage-card__button ${ordersStatistic.period === 'quarter' && 'homePage-card__buttonSelected'}`}
                                >за Квартал
                                </button>
                                <button onClick={() => {
                                    setOrdersStatistic({...ordersStatistic, period: 'half-year'})
                                }}
                                        className={`homePage-card__button ${ordersStatistic.period === 'half-year' && 'homePage-card__buttonSelected'}`}
                                >за Полугодие
                                </button>
                                <button onClick={() => {
                                    setOrdersStatistic({...ordersStatistic, period: 'year'})
                                }}
                                        className={`homePage-card__button ${ordersStatistic.period === 'year' && 'homePage-card__buttonSelected'}`}
                                >за Год
                                </button>
                            </div>
                        </div>
                        <div className="homePage__card homePage__boxOfficeBox">
                            <div className="homePage-card_info">
                                <div className="homePage-card_infoTitle">Общая касса</div>
                                <div className="homePage-card_infoText">{boxOfficeState.boxOfficeCount}</div>
                                <div className="homePage-card_infoButtonBox">
                                    <p>+ {boxOfficeState.boxOfficeInflow}</p>
                                    <span>{boxOfficeState.periodDescription}</span>
                                </div>
                            </div>
                            <div className="homePage-card__buttons">
                                <button onClick={() => {
                                    setBoxOfficeState({
                                        ...boxOfficeState,
                                        period: 'month',
                                        periodDescription: 'за последние 30 дней'
                                    })
                                }}
                                        className={`homePage-card__button ${boxOfficeState.period === 'month' && 'homePage-card__buttonSelected'}`}
                                >за 30 дней
                                </button>
                                <button onClick={() => {
                                    setBoxOfficeState({
                                        ...boxOfficeState,
                                        period: 'quarter',
                                        periodDescription: 'за последний квартал'
                                    })
                                }}
                                        className={`homePage-card__button ${boxOfficeState.period === 'quarter' && 'homePage-card__buttonSelected'}`}
                                >за Квартал
                                </button>
                                <button onClick={() => {
                                    setBoxOfficeState({
                                        ...boxOfficeState,
                                        period: 'half-year',
                                        periodDescription: 'за последнее полугодие'
                                    })
                                }}
                                        className={`homePage-card__button ${boxOfficeState.period === 'half-year' && 'homePage-card__buttonSelected'}`}
                                >за Полугодие
                                </button>
                                <button onClick={() => {
                                    setBoxOfficeState({
                                        ...boxOfficeState,
                                        period: 'year',
                                        periodDescription: 'за последний год'
                                    })
                                }}
                                        className={`homePage-card__button ${boxOfficeState.period === 'year' && 'homePage-card__buttonSelected'}`}
                                >за Год
                                </button>
                            </div>
                        </div>
                        <div className="homePage__card homePage__boxOfficeBox">
                            <div className="homePage-card_info">
                                <div className="homePage-card_infoTitle">Общая скидка</div>
                                <div className="homePage-card_infoText">{discountState.discountCount}</div>
                                <div className="homePage-card_infoButtonBox">
                                    <p>+ {discountState.discountInflow}</p>
                                    <span>{discountState.periodDescription}</span>
                                </div>
                            </div>
                            <div className="homePage-card__buttons">
                                <button onClick={() => {
                                    setDiscountState({
                                        ...discountState,
                                        period: 'month',
                                        periodDescription: 'за последние 30 дней'
                                    })
                                }}
                                        className={`homePage-card__button ${discountState.period === 'month' && 'homePage-card__buttonSelected'}`}
                                >за 30 дней
                                </button>
                                <button onClick={() => {
                                    setDiscountState({
                                        ...discountState,
                                        period: 'quarter',
                                        periodDescription: 'за последний квартал'
                                    })
                                }}
                                        className={`homePage-card__button ${discountState.period === 'quarter' && 'homePage-card__buttonSelected'}`}
                                >за Квартал
                                </button>
                                <button onClick={() => {
                                    setDiscountState({
                                        ...discountState,
                                        period: 'half-year',
                                        periodDescription: 'за последнее полугодие'
                                    })
                                }}
                                        className={`homePage-card__button ${discountState.period === 'half-year' && 'homePage-card__buttonSelected'}`}
                                >за Полугодие
                                </button>
                                <button onClick={() => {
                                    setDiscountState({
                                        ...discountState,
                                        period: 'year',
                                        periodDescription: 'за последний год'
                                    })
                                }}
                                        className={`homePage-card__button ${discountState.period === 'year' && 'homePage-card__buttonSelected'}`}
                                >за Год
                                </button>
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="componentMainWrapper__title">Добро пожаловать,
                        <p>
                            {!staffInfo.loading
                                ? !staffInfo.error &&
                                staffInfo.result?.data.first_name + ' ' + staffInfo.result?.data.last_name
                                : 'Пользователь'
                            }!
                        </p>
                    </div>
                    <span className="componentMainWrapper__subTitle"></span>
                    <div className="homePage__mainContainerNoAdmin">

                    </div>
                </>
            }
        </>
    );
}

export default Home;

