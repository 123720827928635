import { useAsync } from "react-async-hook";
import {$axios} from "../http/axiosInstance";
import { CreateSearchParams } from "../helpers/helpers";

export const BoxOfficeService = {
  GetBoxOfficeList(searchParamsObj: any) {
    return useAsync(async () => {
      return await $axios.get(
        "/admin/bill/" + CreateSearchParams(searchParamsObj)
      );
    }, [CreateSearchParams(searchParamsObj)]);
  },
  GetBillingList(searchParamsObj: any) {
    return useAsync(async () => {
      return await $axios.get(
        "/admin/bill/sorting-groups/" + CreateSearchParams(searchParamsObj)
      );
    }, [CreateSearchParams(searchParamsObj)]);
  },
  GetBillingChequeInfo(id: any) {
    return useAsync(async () => {
      return await $axios.get(`/admin/bill/${id}/`);
    }, []);
  },
  async GetBillingPaymentInfo(data: any) {
    return await $axios.post("/admin/bill/preview/", { ...data });
  },
  async BillingPaymentCreate(paymentInfo: any) {
    return await $axios.post("/admin/bill/create/", { ...paymentInfo });
  },
  async UpdateStatusFromBilling(billId: any, status: any) {
    return await $axios.post("/admin/bill/orders-status-update/", {
      bill_id: billId,
      status: status,
    });
  },
  async BillingPaymentUpdate(paymentInfo: any, bill_id: any) {
    return await $axios.put(`/admin/bill/${bill_id}/update-pay`, {
      ...paymentInfo,
    });
  },
};
