import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  TextField,
} from "@mui/material";
import "../../styles/layout.css";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import TvIcon from "@mui/icons-material/Tv";
import { AddressesService } from "../../services/AddressesService";
import { BoxOfficeService } from "../../services/BoxOfficeService";
import PrintIcon from "@mui/icons-material/Print";

const tableInitialValues = {
  rows: [],
  filter: {
    delivery_country: "",
    search: "",
    page: 1,
    total_pages: 1,
  },
  status: {
    loading: false,
    error: false,
    message: "",
  },
  selectedRows: [],
};
export default function BoxOffice() {
  const navigate = useNavigate();
  let getPersonalCountries = AddressesService.GetPersonalCountries("customer");

  const [table, setTable] = useState<any>({
    ...tableInitialValues,
    columns: [
      { field: "id", headerName: "ID посылки", flex: 1, hide: true },
      {
        field: "customer",
        headerName: "Код клиента",
        flex: 1,
        renderCell: (params: any) => params.row?.user_id,
      },
      {
        field: "full_name",
        headerName: "ФИО",
        flex: 1,
        renderCell: (params: any) => params.row?.user_full_name,
      },
      {
        field: "customer_country",
        headerName: "Страна клиента",
        flex: 1,
        renderCell: (params: any) =>
          params.row.sorting_group?.customer?.user?.country?.country,
      },
      {
        field: "orders_count",
        headerName: "Количество",
        flex: 1,
        renderCell: (params: any) => params.row.sorting_group?.orders_count,
      },
      {
        field: "total_weight",
        headerName: "Вес",
        flex: 1,
        renderCell: (params: any) => params.row.sorting_group?.total_weight,
      },
      {
        field: "paid",
        headerName: "Оплата",
        width: 100,
        renderCell: (params: any) => (
          <div>
            {params.row.paid ? (
              <p style={{ color: "#009D35" }}>оплачено</p>
            ) : (
              <p style={{ color: "#FF0000" }}>Не оплачено</p>
            )}
          </div>
        ),
      },
      {
        field: "pdf",
        headerName: "Чек",
        width: 70,
        renderCell: (params: any) => {
          return (
            params.row.pdf != null && (
              <IconButton
                onClick={() => {
                  window.open(params.row.pdf, "_blank");
                }}
              >
                <PrintIcon sx={{ color: "#48A5D1", fontSize: "30px" }} />
              </IconButton>
            )
          );
        },
      },
      {
        field: "actions",
        headerName: "В очередь",
        width: 100,
        renderCell: (params: any) =>
          params.row.for_delivery
            ? "Доставка"
            : params.row.status === null && (
                <IconButton
                  title="В очередь"
                  onClick={() => {
                    BoxOfficeService.UpdateStatusFromBilling(
                      params.row.id,
                      "issueQueue"
                    ).then(() => {
                      setTable({
                        ...table,
                        filter: {
                          ...table.filter,
                          updateTable: !table.filter.updateTable,
                        },
                      });
                    });
                  }}
                >
                  <TvIcon />
                </IconButton>
              ),
      },
    ],
  });

  const tableList = BoxOfficeService.GetBoxOfficeList(table.filter);

  useEffect(() => {
    if (tableList.loading) {
      setTable((prevState: any) => ({
        ...prevState,
        status: {
          ...prevState.status,
          loading: true,
        },
      }));
    } else if (tableList.error) {
      setTable((prevState: any) => ({
        ...prevState,
        status: {
          ...prevState.status,
          loading: false,
          error: true,
        },
      }));
    } else {
      const data = tableList.result?.data;
      setTable((prevState: any) => ({
        ...prevState,
        rows: data.results,
        status: {
          ...prevState.status,
          loading: false,
          error: false,
        },
        filter: {
          ...prevState.filter,
          page: data.current_page,
          total_pages: data.total_pages,
        },
        selectedRows: [],
      }));
    }
  }, [tableList.loading, tableList.error, tableList.result?.data]);

  useEffect(() => {
    if (!getPersonalCountries.loading && !getPersonalCountries.error) {
      for (let i = 0; i < getPersonalCountries.result?.data.length; i++) {
        if (getPersonalCountries.result?.data[i].code === "KGZ") {
          setTable((prevState: any) => ({
            ...prevState,
            filter: {
              ...prevState.filter,
              page: 1,
              delivery_country: getPersonalCountries.result?.data[i].id,
            },
          }));
        }
      }
    }
  }, [
    getPersonalCountries.error,
    getPersonalCountries.loading,
    getPersonalCountries.result?.data,
  ]);

  return (
    <>
      <div className="w-full flex items-center gap-[20px] mb-[50px]">
        <h1 className="componentMainWrapper__title">Касса</h1>
      </div>
      <div className="w-full flex justify-between items-center mb-[20px]">
        <div className="flex items-center gap-[20px]">
          <FormControl size="small">
            <InputLabel>Страна получателя</InputLabel>
            <Select
              value={table.filter.delivery_country}
              label="Страна получателя"
              onChange={(event) => {
                setTable({
                  ...table,
                  filter: {
                    ...table.filter,
                    delivery_country: event.target.value,
                  },
                });
              }}
            >
              {!getPersonalCountries.loading &&
                !getPersonalCountries.error &&
                getPersonalCountries.result?.data.map((item: any) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.country}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <div className="flex items-center gap-[20px]">
          <TextField
            label="Поиск клиента"
            value={table.filter.search}
            sx={{ width: "340px" }}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fill: "#48A5D1" }} />
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              setTable({
                ...table,
                filter: {
                  ...table.filter,
                  search: event.target.value,
                },
              });
            }}
          />
          <Button
            sx={{
              background:
                "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
              boxShadow:
                "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
              borderRadius: "4px",
              color: "white",
            }}
            onClick={() => {
              navigate({
                pathname: "billing",
              });
            }}
          >
            Выставление счета
          </Button>
        </div>
      </div>
      <div className="w-full rounded-[10px] shadow-md">
        <DataGrid
          rows={table.rows}
          columns={table.columns}
          disableColumnFilter
          filterMode="server"
          autoHeight
          onRowDoubleClick={(params: any) => {
            navigate(`/boxOffice/billingView/${params.row.id}/`);
          }}
          localeText={{
            noRowsLabel: "Нет информации",
          }}
          loading={tableList.loading}
          disableSelectionOnClick
          components={{
            Footer: () => {
              return (
                <GridFooterContainer>
                  <Pagination
                    count={table.filter.total_pages}
                    page={table.filter.page}
                    onChange={(event, value: number) => {
                      setTable((prevState: any) => ({
                        ...prevState,
                        filter: {
                          ...prevState.filter,
                          page: value,
                        },
                      }));
                    }}
                  />
                </GridFooterContainer>
              );
            },
          }}
        />
      </div>
    </>
  );
}
