import React, { FormEvent, useEffect, useState } from "react";
import "../../styles/personal.css";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Pagination,
  Select,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { AddressesService } from "../../services/AddressesService";
import { RolesService } from "../../services/RolesService";
import { PersonalService } from "../../services/PersonalService";
import { checkModalResponse } from "../../helpers/helpers";
import moment from "moment/moment";
import { LoadingButton } from "@mui/lab";

const modalInitialValues = {
  open: false,
  values: {
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    country: "",
    group: "",
  },
  validation: {
    error: {
      first_name: false,
      last_name: false,
      email: false,
      password: false,
      country: false,
      group: false,
    },
    message: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      country: "",
      group: "",
    },
  },
  requested: false,
  showPassword: false,
  action: "",
};
const tableInitialValues = {
  rows: [],
  status: {
    loading: false,
    error: false,
    message: "",
  },
  filter: {
    page: 1,
    size: 20,
    total_pages: 1,
  },
};

export default function Personal() {
  const [table, setTable] = useState<any>({
    ...tableInitialValues,
    columns: [
      {
        field: "id",
        headerName: "ID",
        flex: 1,
        hide: true,
      },
      {
        field: "data",
        headerName: "Дата создания",
        flex: 1,
        renderCell: (params: any) =>
          moment(params.row.user?.date_joined).format("YYYY-MM-DD").toString(),
      },
      {
        field: "name",
        headerName: "Имя сотрудника",
        flex: 1,
        renderCell: (params: any) => params.row.full_name,
      },
      {
        field: "post",
        headerName: "Должность",
        flex: 1,
        renderCell: (params: any) => params.row.user?.groups[0]?.name,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
        renderCell: (params: any) => params.row.user?.email,
      },
      {
        field: "status",
        headerName: "Статус",
        flex: 1,
        renderCell: (params: any) => {
          return (
            <div>
              {params.row.status ? (
                <p style={{ color: "#009D35" }}>Активен</p>
              ) : (
                <p style={{ color: "#FF0000" }}>Не активен</p>
              )}
            </div>
          );
        },
      },
      {
        field: "edit",
        headerName: "",
        flex: 1,
        renderCell: (params: any) => {
          return (
            <div className="w-full flex items-center gap-[20px]">
              <IconButton
                color="secondary"
                onClick={() => {
                  setModal({
                    ...modal,
                    open: true,
                    action: "edit",
                    values: {
                      ...modal.values,
                      ...params.row.user,
                      id: params.row.id,
                      group: [...params.row.user?.groups].pop()?.id,
                    },
                  });
                }}
              >
                <EditIcon style={{ color: "#B9B9B9" }} />
              </IconButton>
              <IconButton
                color="secondary"
                onClick={() => {
                  setModal({
                    ...modal,
                    open: true,
                    action: "delete",
                    values: {
                      ...modal.values,
                      ...params.row.user,
                      id: params.row.id,
                      group: [...params.row.user?.groups].pop()?.id,
                    },
                  });
                }}
              >
                <DeleteIcon style={{ color: "#B9B9B9" }} />
              </IconButton>
            </div>
          );
        },
      },
    ],
  });

  const [modal, setModal] = useState<any>(modalInitialValues);

  const personalCountriesList = AddressesService.GetPersonalCountries("staff");
  const roles = RolesService.GetRoles();
  const tableList = PersonalService.GetStaffList(table.filter);

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    setModal({
      ...modal,
      requested: true,
    });

    switch (modal.action) {
      case "add":
        PersonalService.CreateStaff(modal.values)
          .then(() => {
            setModal(modalInitialValues);
            tableList.execute();
          })
          .catch((err) => {
            checkModalResponse(err.response.data, setModal, modal);
          });
        break;

      case "edit":
        if (!modal.values.id) return;

        const passwordNotEmpty = modal.values.password.trim() !== "";
        const confirmNotEmpty = modal.values.confirm_password.trim() !== "";

        if (passwordNotEmpty || confirmNotEmpty) {
          if (modal.values.password === modal.values.confirm_password) {
            PersonalService.EditStaff({
              id: modal.values.id,
              user: {
                first_name: modal.values.first_name,
                last_name: modal.values.last_name,
                email: modal.values.email,
                country: modal.values.country,
                group: modal.values.group,
                password: modal.values.password,
                confirm_password: modal.values.confirm_password,
              },
            })
              .then(() => {
                setModal(modalInitialValues);
                tableList.execute();
              })
              .catch((err) => {
                checkModalResponse(err.response.data, setModal, modal);
              });
          } else {
            setModal({
              ...modal,
              validation: {
                ...modal.validation,
                error: {
                  ...modal.validation.error,
                  confirm_password: true,
                },
                message: {
                  ...modal.validation.message,
                  confirm_password: "Пароли не совпадают",
                },
              },
            });
          }
        } else {
          PersonalService.EditStaff({
            id: modal.values.id,
            user: {
              first_name: modal.values.first_name,
              last_name: modal.values.last_name,
              email: modal.values.email,
              country: modal.values.country,
              group: modal.values.group,
            },
          })
            .then(() => {
              setModal(modalInitialValues);
              tableList.execute();
            })
            .catch((err) => {
              checkModalResponse(err.response.data, setModal, modal);
            });
        }
        break;

      //   case "edit":
      //     PersonalService.EditStaff(modal.values)
      //       .then(() => {
      //         setModal(modalInitialValues);
      //         tableList.execute();
      //       })
      //       .catch((err) => {
      //         checkModalResponse(err.response.data, setModal, modal);
      //       });
      //     break;

      case "delete":
        PersonalService.DeleteStaff(modal.values.id)
          .then(() => {
            setModal(modalInitialValues);
            tableList.execute();
          })
          .catch((err) => {
            checkModalResponse(err.response.data, setModal, modal);
          });

        break;
    }
  };

  useEffect(() => {
    if (tableList.loading) {
      setTable((prevState: any) => ({
        ...prevState,
        status: {
          ...prevState.status,
          loading: true,
        },
      }));
    } else if (tableList.error) {
      setTable((prevState: any) => ({
        ...prevState,
        status: {
          ...prevState.status,
          loading: false,
          error: true,
        },
      }));
    } else {
      const data = tableList.result?.data;
      setTable((prevState: any) => ({
        ...prevState,
        rows: data.results,
        status: {
          ...prevState.status,
          loading: false,
          error: false,
        },
        filter: {
          ...prevState.filter,
          page: data.current_page,
          total_pages: data.total_pages,
        },
        selectedRows: [],
      }));
    }
  }, [tableList.loading, tableList.error, tableList.result?.data]);

  return (
    <>
      <h1 className="text-[30px] font-[400] text-black my-[50px]">
        Сотрудники
      </h1>

      <div className="w-full flex justify-end">
        <Button
          color="purple"
          variant="contained"
          onClick={() => {
            setModal({
              ...modalInitialValues,
              open: true,
              action: "add",
            });
          }}
        >
          Добавить
        </Button>
      </div>

      <div className="w-full mt-[20px] rounded-[10px] shadow-md">
        <DataGrid
          rows={table.rows}
          columns={table.columns}
          disableColumnFilter
          filterMode="server"
          autoHeight
          localeText={{
            noRowsLabel: "Нет информации",
          }}
          loading={tableList.loading}
          disableSelectionOnClick
          components={{
            Footer: () => {
              return (
                <GridFooterContainer>
                  <Pagination
                    count={table.filter.total_pages}
                    page={table.filter.page}
                    onChange={(event, value: number) => {
                      setTable({
                        ...table,
                        filter: {
                          ...table.filter,
                          page: value,
                        },
                      });
                    }}
                  />
                </GridFooterContainer>
              );
            },
          }}
        />
      </div>

      <Modal open={modal.open} onClose={() => setModal(modalInitialValues)}>
        <form
          onSubmit={handleFormSubmit}
          className="mainModal relative flex flex-col justify-start items-center"
        >
          <div className="header-currencyBox__avatar">
            <EmojiPeopleIcon sx={{ color: "white" }} />
          </div>
          <h1 className="header-currencyBox__title">
            {modal.action === "add" && "Добавление сотрудника"}
            {modal.action === "delete" && "Удалить сотрудника?"}
            {modal.action === "edit" && "Редактирование сотрудника"}
          </h1>
          {modal.action !== "delete" && (
            <div className="grid grid-cols-2 gap-[30px]">
              <TextField
                fullWidth
                label="Фамилия"
                required
                value={modal.values.first_name}
                onChange={(event) => {
                  setModal({
                    ...modal,
                    values: {
                      ...modal.values,
                      first_name: event.target.value,
                    },
                  });
                }}
              />
              <TextField
                fullWidth
                label="Имя"
                required
                value={modal.values.last_name}
                onChange={(event) => {
                  setModal({
                    ...modal,
                    values: {
                      ...modal.values,
                      last_name: event.target.value,
                    },
                  });
                }}
              />
              <TextField
                fullWidth
                label="Email"
                type="email"
                required
                error={modal.validation.error.email}
                helperText={modal.validation.message.email}
                value={modal.values.email}
                onChange={(event) => {
                  setModal({
                    ...modal,
                    values: {
                      ...modal.values,
                      email: event.target.value,
                    },
                  });
                }}
              />
              <div className="w-full flex items-center gap-[20px]">
                <FormControl fullWidth>
                  <InputLabel>Должность</InputLabel>
                  <Select
                    value={modal.values.group}
                    label="Должность"
                    required
                    defaultValue=""
                    onChange={(event) => {
                      setModal({
                        ...modal,
                        values: {
                          ...modal.values,
                          group: event.target.value,
                        },
                      });
                    }}
                  >
                    {roles.loading ? (
                      <div>loading</div>
                    ) : roles.error ? (
                      <div>Error</div>
                    ) : (
                      roles.result?.data.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel>Страна</InputLabel>
                  <Select
                    value={modal.values.country}
                    label="Страна"
                    required
                    defaultValue=""
                    onChange={(event) => {
                      setModal({
                        ...modal,
                        values: {
                          ...modal.values,
                          country: event.target.value,
                        },
                      });
                    }}
                  >
                    {personalCountriesList.loading ? (
                      <div>loading</div>
                    ) : personalCountriesList.error ? (
                      <div>Error</div>
                    ) : (
                      personalCountriesList.result?.data.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.country}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </div>
              <TextField
                fullWidth
                label="Пароль"
                variant="outlined"
                type={modal.showPassword ? "text" : "password"}
                value={modal.values.password}
                onChange={(event) => {
                  setModal({
                    ...modal,
                    values: {
                      ...modal.values,
                      password: event.target.value,
                    },
                  });
                }}
                error={modal.validation.error.password}
                helperText={modal.validation.message.password}
                required={modal.action === "add"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setModal({
                            ...modal,
                            showPassword: !modal.showPassword,
                          });
                        }}
                      >
                        {modal.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                label="Подтвердите пароль"
                variant="outlined"
                type={modal.showPassword ? "text" : "password"}
                value={modal.values.confirm_password}
                onChange={(event) => {
                  setModal({
                    ...modal,
                    values: {
                      ...modal.values,
                      confirm_password: event.target.value,
                    },
                  });
                }}
                error={modal.values.confirm_password !== modal.values.password}
                helperText={
                  modal.values.confirm_password !== modal.values.password
                    ? "Пароли не совпадают"
                    : ""
                }
                required={modal.action === "add"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setModal({
                            ...modal,
                            showPassword: !modal.showPassword,
                          });
                        }}
                      >
                        {modal.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}

          <div className="w-full flex justify-center gap-[30px] items-center mt-[50px]">
            <Button
              variant="outlined"
              onClick={() => setModal(modalInitialValues)}
            >
              Отменить
            </Button>
            <LoadingButton
              variant="contained"
              loading={modal.requested}
              disabled={modal.requested}
              type="submit"
            >
              Готово
            </LoadingButton>
          </div>
        </form>
      </Modal>
    </>
  );
}
