import React, {useEffect, useState} from 'react';
import {CircularProgress, InputAdornment, Pagination, Stack, TextField} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {DeliveryService} from "../../services/DeliveryService";
import {EmptyData, OrdersEmptyIcon} from "../../helpers/helpers";
import moment from "moment";
import {useNavigate} from "react-router-dom";

const deliveryInitialState = {
    data: [],
    filter: {
        search: '',
        page: 1,
        total_pages: 1,
        updateTable: false,
    },
    selectedDeliveries: [],
}
const CourierMainPage = () => {
    const navigate = useNavigate()

    const [delivery, setDelivery] = useState<any>(deliveryInitialState)

    const deliveryList = DeliveryService.GetCourierDeliveryList(delivery.filter)

    useEffect(() => {
        if (!deliveryList.loading && !deliveryList.error) {
            const data = deliveryList.result?.data
            setDelivery((prevState: any) => ({
                ...prevState,
                data: data.results,
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                }
            }))
        }
    }, [deliveryList.loading, deliveryList.error, deliveryList.result?.data])
    return (
        <div className='courierMainPage'>
            <TextField
                placeholder='Введите код клиента'
                sx={{background: ' white', width: '100%', marginTop: '20px', marginBottom: '20px'}}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <SearchIcon/>
                    </InputAdornment>,
                }}
                onChange={(event) => {
                    setDelivery({
                        ...delivery,
                        filter: {
                            ...delivery.filter,
                            search: event.target.value
                        }
                    })
                }}
            />
            <Stack width='100%' direction='column' spacing='10px' alignItems='center'>
                {deliveryList.loading
                    ? <CircularProgress/>
                    : deliveryList.error
                        ? 'error...'
                        :
                        delivery.data.length > 0
                            ? delivery.data.map((delivery: any, index: number) => (
                                <div className='courier_delivery' key={index} onDoubleClick={() => {
                                    navigate(`delivery/${delivery.id}`)
                                }}>
                                    <div>
                                        <p className='user_id'>ID {delivery.user.id}</p>
                                        <p className='date'>{moment(delivery.created_at).format('DD.MM.YYYY').toString()}</p>
                                    </div>
                                    <div>
                                        <p className='user_name'>{delivery.user.full_name}</p>
                                        <p className='status'>{delivery.status.name}</p>
                                    </div>
                                </div>
                            ))

                            : <EmptyData text={'Нет доставок'} icon={<OrdersEmptyIcon/>}/>

                }

                <Pagination
                    style={{marginTop: '20px'}}
                    count={delivery.filter.total_pages}
                    page={delivery.filter.page}
                    onChange={(event, value: number) => {
                        setDelivery({
                            ...delivery,
                            filter: {
                                ...delivery.filter,
                                page: value
                            }
                        })
                    }}
                />
            </Stack>
        </div>
    );
};

export default CourierMainPage;