import {useAsync} from "react-async-hook";
import {$axios} from "../http/axiosInstance";

export const NotificationService = {
    GetNotifications() {
        return useAsync(async () => {
            return await $axios.get('/admin/notifications/');
        }, [])
    },
    async GetNotificationsInfo(id: string) {
        return await $axios.get(`/admin/notifications/${id}/`)
    },
    async DeleteNotifications(id: string) {
        return await $axios.delete(`admin/notifications/${id}/`)
    },
    async PostSelectedNotifications(arrOfId: [], flag: string) {
        return await $axios.post('/admin/notification-bulk-update/', {
            "notification_ids": arrOfId,
            "flag": flag
        })
    },
    async CreateNotification(notificationInfo: any) {
        return await $axios.post('/admin/notifications/create/', {...notificationInfo})
    }
}