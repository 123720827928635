import React, {useEffect, useState} from 'react';
import '../../styles/notifications.css'
import {
    DataGrid,
    GridColDef,
    GridFooter,
    GridFooterContainer,
    GridRowParams,
} from "@mui/x-data-grid";
import {Button, IconButton, Stack} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment";
import {useNavigate} from "react-router-dom";
import parse from 'html-react-parser'
import {NotificationService} from "../../services/NotificationService";

function Notifications() {
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState('notifications')
    const [notificationsData, setNotificationsData] = useState<any>([])
    const [notificationsInfoData, setNotificationsInfoData] = useState<any>({
        id: '',
        title: '',
        message: '',
        date: '',
        loading: false,
    })
    const [selectedNotifications, setSelectedNotification] = useState<any>([])
    const notifications = NotificationService.GetNotifications()
    const notificationsColumns: GridColDef[] = [
        {field: 'id', headerName: 'ID', width: 100, hide: true},
        {
            field: 'notification', headerName: 'Уведомление', width: 1000, renderCell: (params) => {
                return (
                    <div style={{color: params.row.status === 'read' ? 'rgba(0, 0, 0, 0.36)' : 'black'}}>
                        {params.row.notification}
                    </div>
                )
            }
        },
        {
            field: 'status', headerName: '', width: 120, renderCell: (params) => {
                return (
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                         onClick={(event) => {
                             event.stopPropagation()
                         }}>
                        {params.row.status === 'unread' &&
                            <MarkEmailUnreadIcon style={{color: "#48A5D1", marginRight: '10px'}}/>}
                        {params.row.status === 'read' &&
                            <MarkEmailReadIcon style={{color: "#48A5D1", marginRight: '10px'}}/>}
                        <IconButton color="secondary" aria-label="add an alarm" onClick={() => {
                            handleNotificationDeleteClick(params.row.id)
                        }}>
                            <DeleteIcon style={{color: "#B9B9B9"}}/>
                        </IconButton>
                    </div>
                )
            }
        },
    ];
    const handleNotificationDeleteClick = (index: string) => {
        setNotificationsData((prev: any) => {
            const newArray = [...prev]
            for (let i = 0; i < newArray.length; i++) {
                if (newArray[i].id === index) {
                    newArray.splice(i, 1)
                }
            }
            return newArray
        })
        NotificationService.DeleteNotifications(index)
    }
    const handleNotificationRowClick = (params: GridRowParams) => {
        setCurrentPage('notificationsView')
        setNotificationsInfoData({
            ...notificationsInfoData,
            loading: true,
        })
        NotificationService.GetNotificationsInfo(params.row.id).then((res) => {
            setNotificationsInfoData({
                ...notificationsInfoData,
                id: res.data.id,
                title: res.data.title,
                message: res.data.message,
                date: (moment(res.data.created_at)).format('DD.MM.YYYY HH:mm').toString(),
                loading: false,
            })
        }).catch((res) => {
            setNotificationsInfoData({
                ...notificationsInfoData,
                loading: false,
            })
        })
    }
    const handleSelectedNotificationDelete = () => {
        setNotificationsData((prev: any) => {
            const newArray = [...prev]
            for (let j = 0; j < selectedNotifications.length; j++) {
                for (let i = 0; i < newArray.length; i++) {
                    if (newArray[i].id === selectedNotifications[j]) {
                        newArray.splice(i, 1)
                    }
                }
            }
            return newArray
        })
        NotificationService.PostSelectedNotifications(selectedNotifications, "delete")
    }
    useEffect(() => {
        if (!notifications.loading && !notifications.error) {
            const notificationsArray = []
            for (let i = 0; i < notifications.result?.data.length; i++) {
                notificationsArray[i] = {
                    id: notifications.result?.data[i].id,
                    notification: notifications.result?.data[i].title,
                    status: notifications.result?.data[i].status,
                }
            }
            setNotificationsData(notificationsArray)
        }
    }, [notifications.error, notifications.loading, notifications.result?.data])
    return (
        <>
            <div className='notifications__titleButtonBox'>
                <p className="componentMainWrapper__title">Уведомления</p>
                <Button
                    sx={{
                        background: "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
                        height: "34px",
                        width: "220px",
                        color: "#FFFFFF",
                        textTransform: "uppercase",
                    }}
                    onClick={() => {
                        navigate({
                            pathname: '/notifications/addNotification',
                        })
                    }}>
                    добавить уведомление
                </Button>
            </div>
            <span className="componentMainWrapper__subTitle">Товарищи! укрепление и развитие структуры в значительной степени обуславливает создание новых предложений. </span>
            <div className="notificationPage__mainContainer">
                {currentPage === 'notifications' &&
                    <DataGrid
                        rows={notificationsData}
                        columns={notificationsColumns}
                        checkboxSelection
                        keepNonExistentRowsSelected
                        disableColumnFilter
                        filterMode='server'
                        loading={notifications.loading}
                        autoHeight
                        disableSelectionOnClick
                        onRowClick={handleNotificationRowClick}
                        onSelectionModelChange={(selectionModel) => setSelectedNotification(selectionModel)}
                        selectionModel={selectedNotifications}
                        localeText={{
                            noRowsLabel: "Нет информации",
                          }}
                        components={{
                            Footer: () => {
                                return (
                                    <GridFooterContainer>
                                        <GridFooter sx={{
                                            border: 'none', // To delete double border.
                                        }}/>
                                        <Stack direction={"row"} spacing={"20px"}>
                                            <Button className='notification__deleteButton'
                                                    onClick={handleSelectedNotificationDelete}
                                                    disabled={!(selectedNotifications.length > 0)}
                                            >
                                                Удалить
                                            </Button>
                                        </Stack>
                                    </GridFooterContainer>
                                );
                            },
                        }}
                    />
                }
                {currentPage === 'notificationsView'
                    ? notificationsInfoData.loading
                        ?
                        <div className='notificationsView__wrapperLoading'>
                            <CircularProgress/>
                        </div>
                        :
                        <div className='notificationsView__wrapper'>
                            <div className="notificationsView__wrapperTitleBar">
                                <h1 className='notificationsView__title'>{notificationsInfoData.title}</h1>
                                <span className='notificationsView__date'>{notificationsInfoData.date}</span>
                            </div>
                            <div className="notificationsView__wrapperTextBar">
                                {parse(notificationsInfoData.message)}
                            </div>
                            <IconButton color="secondary"
                                        aria-label="add an alarm"
                                        style={{position: 'absolute', top: '23px', left: '15px'}}
                                        onClick={() => {
                                            setCurrentPage('notifications')
                                        }}>
                                <ArrowBackIosIcon/>
                            </IconButton>
                        </div>
                    : ''
                }
            </div>
        </>
    );
}

export default Notifications;

