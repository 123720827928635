import {useAsync} from "react-async-hook";
import {$axios} from "../http/axiosInstance";
import {CreateSearchParams} from "../helpers/helpers";

export const SortingService = {
    GetOrdersList(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/sorting/orders/' + CreateSearchParams(searchParamsObj));
        }, [CreateSearchParams(searchParamsObj)])
    },
    GetExcelsList(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/sorting/excels/' + CreateSearchParams(searchParamsObj));
        }, [CreateSearchParams(searchParamsObj)])
    },
    GetOrder(id: string | undefined) {
        return useAsync(async () => {
            return await $axios.get(`/admin/sorting/order/${id}/`);
        }, [])
    },
    async AddOrder(orderInfo: any) {
        return await $axios.post('/admin/sorting/order/update/', {...orderInfo})
    },
    async EditOrder(orderInfo: any) {
        return await $axios.post('/admin/sorting/order/update/', {...orderInfo})
    },
    async AddExcel(values:any) {
        let form_data = new FormData()
        const data: any = values
        for (let key in data) {
            form_data.append(key, data[key]);
        }
        return await $axios.post('/admin/sorting/orders/import/', form_data)
    },
    async SendOrders(values: any) {
        return await $axios.post('/admin/sorting/orders/change/status/', values)
    },
    async SendExcels(values: any) {
        return await $axios.post('/admin/sorting/excels/orders/update/', values)
    },
    async UploadExcelInSorting(excel_id: string | undefined, file: any) {
        let form_data = new FormData()
        const data: any = {
            weighted_excel: file
        }
        for (let key in data) {
            form_data.append(key, data[key]);
        }
        return await $axios.put(`/admin/sorting/update/excel/${excel_id}/`, form_data)
    }
}