import { useAsync } from "react-async-hook";
import { $axios } from "../http/axiosInstance";
import { CreateSearchParams } from "../helpers/helpers";

export const OrderPickUpPointsService = {
  GetOrdersList() {
    return useAsync(async () => {
      return await $axios.get("/admin/pick-up-points/");
    }, []);
  },
  async CreatePickUpPoints(item: any) {
    return await $axios.post("/admin/pick-up-points/", item);
  },
  async UpdatePickUpPoint(item: any) {
    return await $axios.put(`/admin/pick-up-points/${item.id}/`, item);
  },
  async DeletePickUpPoint(item: any) {
    return await $axios.delete(`/admin/pick-up-points/${item.id}/`);
  },

  GetClientsList(id: any, params: any) {
    return useAsync(async () => {
      return await $axios.get(
        `/admin/pick-up-points/${id}/clients/` + CreateSearchParams(params)
      );
    }, [CreateSearchParams({ id, params })]);
  },

  // GetClientsList(searchParamsObj: any) {
  //   return useAsync(async () => {
  //     return await $axios.get(
  //       `/admin/pick-up-points/${searchParamsObj}/clients/`
  //     );
  //   }, [CreateSearchParams(searchParamsObj)]);
  // },

  async PickUpPointsExcelExport(item: any) {
    return await $axios.post(
      "/admin/pick-up-points/clients/export-excel/",
      item
    );
  },
};
