import React, {useEffect, useState} from 'react';
import {
    Autocomplete, Button, Checkbox, FormControlLabel, Stack,
    TextField
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {CustomerService} from "../../services/CustomerService";
import {NotificationService} from "../../services/NotificationService";


function AddNotification() {
    const navigate = useNavigate()
    const [notificationInfo, setNotificationInfo] = useState<any>({
        title: '',
        message: '',
        customers: [],
        send_all: true,
    })
    const [userSearchName, setUserSearchName] = useState('')
    const [customers, setCustomers] = useState<any>([])
    const customersList = CustomerService.GetCustomersListBySearch({search: userSearchName})

    useEffect(() => {
        if (!customersList.loading && !customersList.error) {
            setCustomers(customersList.result?.data)
        }
    }, [customersList.error, customersList.loading, customersList.result?.data])

    const handleFormSubmit = () => {
        NotificationService.CreateNotification(notificationInfo).then(() => {
            navigate({
                pathname: '/notifications',
            })
        })
    }

    const modules = {
        toolbar: [
            [{'header': [1, 2, false]}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            ['clean']
        ],
    }
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]
    return (
        <>
            <p className="componentMainWrapper__title">Добавление уведомления</p>
            <span className="componentMainWrapper__subTitle">Товарищи! укрепление и развитие структуры в значительной степени обуславливает создание новых предложений. </span>
            <div className='newDataAdd'>
                <div className='newDataAdd__title'>
                    Новое уведомление
                </div>
                <form className="newDataAdd__form" onSubmit={(event) => {
                    event.preventDefault()
                    handleFormSubmit()
                }}>
                    <div className="newDataAdd__textFieldBox">
                        <p>Заголовок уведомления:</p>
                        <TextField
                            value={notificationInfo.title}
                            placeholder='Заголовок уведомления'
                            required={true}
                            fullWidth={true}
                            type='text'
                            InputProps={{sx: {height: 40}}}
                            onChange={(event) => {
                                setNotificationInfo({
                                    ...notificationInfo,
                                    title: event.target.value,
                                })
                            }}
                        />
                    </div>
                    <div className="newDataAdd__textFieldBox">
                        <p>Кому отправить:</p>
                        <Autocomplete
                            freeSolo
                            multiple
                            disabled={notificationInfo.send_all}
                            isOptionEqualToValue={(option: any, value) => option.full_name === value.full_name}
                            getOptionLabel={(option: any) => option.full_name}
                            options={customers}
                            loading={customersList.loading}
                            onChange={(event: any, newValue: any,) => {
                                setNotificationInfo({
                                    ...notificationInfo,
                                    customers: newValue.map((customer: any) => customer.id)
                                })
                            }}
                            inputValue={userSearchName}
                            onInputChange={(event, newInputValue,) => {
                                setUserSearchName(newInputValue);
                            }}
                            sx={{width: '100%'}}
                            size="small"
                            renderInput={(params) => <TextField {...params}
                                                                placeholder="ФИО Пользователя"
                                                                required={notificationInfo.customers.length < 1 && !notificationInfo.send_all}
                            />}
                        />
                    </div>
                    <div className="newDataAdd__textFieldBox">
                        <FormControlLabel control={
                            <Checkbox
                                checked={notificationInfo.send_all}
                                onChange={() => {
                                    setNotificationInfo({
                                        ...notificationInfo,
                                        customers: [],
                                        send_all: !notificationInfo.send_all,
                                    })
                                }}
                                inputProps={{'aria-label': 'controlled'}}
                            />
                        } label="Отправить всем"
                        />
                    </div>
                    <div className="newDataAdd__textFieldBox">
                        <p>Заголовок уведомления:</p>
                        <ReactQuill theme="snow"
                                    style={{width: '100%'}}
                                    value={notificationInfo.message}
                                    onChange={(value) => {
                                        setNotificationInfo({
                                            ...notificationInfo,
                                            message: value,
                                        })
                                    }}
                                    modules={modules}
                                    formats={formats}
                        />
                    </div>
                    <Stack direction='row' spacing='20px'>
                        <Button
                            variant='contained'
                            type='submit'
                        >Добавить</Button>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                navigate({
                                    pathname: '/notifications',
                                })
                            }}
                        >Отменить</Button>
                    </Stack>
                </form>
            </div>
        </>
    );
}

export default AddNotification;

