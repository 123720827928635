import React from 'react';
import {Outlet} from "react-router-dom";
import '../styles/layout.css'
import CourierHeader from "./CourierHeader";

function LayoutCourier() {
    return (
        <div className='layoutCourier'>
            <CourierHeader/>
            <Outlet/>
        </div>
    );
}

export default LayoutCourier;