import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import Customers from "./pages/customers/Customers";
import Notifications from "./pages/notifications/Notifications";
import Warehouse from "./pages/warehouse/Warehouse";
import AddNotification from "./pages/notifications/AddNotification";
import Personal from "./pages/personal/Personal";
import Orders from "./pages/orders/Orders";
import OrderView from "./pages/orders/OrderView";
import SortPage from "./pages/sorting/SortPage";
import Ransom from "./pages/ransom/Ransom";
import AddRansom from "./pages/ransom/AddRansom";
import EditRansom from "./pages/ransom/EditRansom";
import BoxOffice from "./pages/boxOffice/BoxOffice";
import Billing from "./pages/boxOffice/billing/Billing";
import BillingView from "./pages/boxOffice/billing/BillingView";
import Queue from "./pages/queue/Queue";
import QueueUserPanel from "./pages/queue/QueueUserPanel";
import Tariff from "./pages/tariff/Tariff";
import TariffDetails from "./pages/tariff/TariffDetails";
import LostOrders from "./pages/orders/LostOrders";
import CustomersView from "./pages/customers/CustomersView";
import QueueView from "./pages/queue/QueueView";
import DeliveryOrders from "./pages/deliveries/DeliveryOrders";
import DeliveryView from "./pages/deliveries/DeliveryView";
import LayoutCourier from "./components/LayoutCourier";
import CourierMainPage from "./pages/courier/CourierMainPage";
import Auth from "./pages/Auth";
import CourierDeliveryViewPage from "./pages/courier/CourierDeliveryViewPage";
import { useSelector } from "react-redux";
import MiddleWare from "./http/MiddleWare";
import WarehouseDetails from "./pages/warehouse/WarehouseDetails";
import SortingDetails from "./pages/sorting/SortingDetails";
import OrderPickUpPoints from "./pages/OrderPickUpPoints/OrderPickUpPoints";
import OrderPickUpPointsView from "./pages/OrderPickUpPoints/OrderPickUpPointsView";

function App() {
  const user = useSelector((state: any) => state.userData.user);
  return (
    <BrowserRouter>
      <Routes>
        {user?.group === "Курьер" ? (
          <Route
            path="/*"
            element={
              <MiddleWare>
                <LayoutCourier />
              </MiddleWare>
            }
          >
            <Route path="home" index element={<CourierMainPage />} />
            <Route path="delivery/:id" element={<CourierDeliveryViewPage />} />
          </Route>
        ) : (
          <Route
            path="/*"
            element={
              <MiddleWare>
                <Layout />
              </MiddleWare>
            }
          >
            <Route index path="home" element={<Home />} />
            <Route path="customers/*">
              <Route index element={<Customers />} />
              <Route path="viewCustomer/:id" element={<CustomersView />} />
            </Route>
            <Route path="warehouse/*">
              <Route index element={<Warehouse />} />
              <Route path="details" element={<WarehouseDetails />} />
            </Route>
            <Route path="ransom/*">
              <Route index element={<Ransom />} />
              <Route path="add" element={<AddRansom />} />
              <Route path="edit/:id" element={<EditRansom />} />
            </Route>
            <Route path="sorting/*">
              <Route index element={<SortPage />} />
              <Route path="details" element={<SortingDetails />} />
            </Route>
            <Route path="orders/*">
              <Route index element={<Orders />} />
              <Route path="orderViewPage/:id" element={<OrderView />} />
            </Route>
            <Route path="deliveryOrders/*">
              <Route index element={<DeliveryOrders />} />
              <Route path="details/:id" element={<DeliveryView />} />
            </Route>
            <Route path="boxOffice/*">
              <Route index element={<BoxOffice />} />
              <Route path="billing" element={<Billing />} />
              <Route path="billingView/:id" element={<BillingView />} />
              <Route path="orderViewPage/:id" element={<OrderView />} />
            </Route>
            <Route path="queue/*">
              <Route index element={<Queue />} />
              <Route path="queueView/:id" element={<QueueView />} />
            </Route>
            <Route path="lostOrders/*">
              <Route index element={<LostOrders />} />
            </Route>
            <Route path="tariff/*">
              <Route index element={<Tariff />} />
              <Route path="details" element={<TariffDetails />} />
            </Route>
            <Route path="notifications/*">
              <Route index element={<Notifications />} />
              <Route path="addNotification" element={<AddNotification />} />
            </Route>
            <Route path="personal" element={<Personal />} />

            <Route path="order-pick-up-points/*">
              <Route index element={<OrderPickUpPoints />} />
              <Route path='view/:id' element={<OrderPickUpPointsView/>}/>
            </Route>

            {/* <Route path='order-pick-up-points' element={<OrderPickUpPoints/>}/> */}
          </Route>
        )}

        <Route
          path="login"
          element={
            <MiddleWare>
              <Auth />
            </MiddleWare>
          }
        />
        <Route path="queueUserPanel" element={<QueueUserPanel />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
