import React, { FormEvent, useEffect, useState } from "react";
import "../../styles/personal.css";
import { Button, IconButton, Modal, TextField } from "@mui/material";
import { checkModalResponse } from "../../helpers/helpers";
import { LoadingButton } from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { OrderPickUpPointsService } from "../../services/OrderPickUpPointsService";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const modalInitialValues = {
  open: false,
  values: {
    id: "",
    name: "",
  },
  validation: {
    error: {
      name: false,
    },
    message: {
      name: "",
    },
  },
  requested: false,
  showPassword: false,
  action: "",
};

export default function OrderPickUpPoints() {
  const navigate = useNavigate();
  const [modal, setModal] = useState<any>(modalInitialValues);

  const pickUpPointsList = OrderPickUpPointsService.GetOrdersList();

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    setModal({
      ...modal,
      requested: true,
    });

    switch (modal.action) {
      case "add":
        OrderPickUpPointsService.CreatePickUpPoints(modal.values)
          .then(() => {
            setModal(modalInitialValues);
            pickUpPointsList.execute();
          })
          .catch((err) => {
            checkModalResponse(err.response.data, setModal, modal);
          });
        break;

      case "edit":
        OrderPickUpPointsService.UpdatePickUpPoint(modal.values)
          .then(() => {
            setModal(modalInitialValues);
            pickUpPointsList.execute();
          })
          .catch((err) => {
            checkModalResponse(err.response.data, setModal, modal);
          });
        break;

      case "delete":
        OrderPickUpPointsService.DeletePickUpPoint(modal.values)
          .then(() => {
            setModal(modalInitialValues);
            pickUpPointsList.execute();
          })
          .catch((err) => {
            checkModalResponse(err.response.data, setModal, modal);
          });

        break;
    }
  };


  return (
    <>
      <div className="flex items-center justify-between w-full my-[50px]">
        <h1 className="text-[30px] font-[400] text-black">
          Пункты выдачи заказа
        </h1>

        <div>
          <Button
            color="blue"
            className="bg-gradient"
            variant="contained"
            onClick={() => {
              setModal({
                ...modalInitialValues,
                open: true,
                action: "add",
              });
            }}
          >
            ДОБАВИТЬ ПВЗ
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-[30px]">
        {!pickUpPointsList.loading &&
          !pickUpPointsList.error &&
          pickUpPointsList.result?.data.map((item: any, index: number) => (
            <div className="w-[421px] mt-[20px] shadow-block p-[24px] rounded-[10px]">
              <div className="flex flex-col gap-[36px]">
                <span>{item.name}</span>

                <div className="flex items-center gap-[24px]">
                  <Button
                    onClick={() => navigate(`view/${item.id}`)}
                    color="blue"
                    variant="contained"
                  >
                    Подробнее
                  </Button>

                  <IconButton
                    onClick={() => {
                      setModal({
                        ...modal,
                        open: true,
                        action: "edit",
                        values: {
                          ...pickUpPointsList.result?.data[index],
                        },
                      });
                    }}
                  >
                    <EditIcon className="text-[#48A5D1]" />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setModal({
                        ...modal,
                        open: true,
                        action: "delete",
                        values: {
                          ...pickUpPointsList.result?.data[index],
                        },
                      });
                    }}
                  >
                    <DeleteIcon className="text-[#48A5D1]" />
                  </IconButton>
                </div>
              </div>
            </div>
          ))}
      </div>

      <Modal open={modal.open} onClose={() => setModal(modalInitialValues)}>
        <div className="mainModal relative flex flex-col justify-start items-center">
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
            onClick={() => setModal(modalInitialValues)}
          >
            <CloseIcon />
          </IconButton>

          <form onSubmit={handleFormSubmit}>
            <h1 className="header-currencyBox__title">
              {modal.action === "add" && "Добавить пункт выдачи заказа"}
              {modal.action === "delete" && "Удалить ?"}
              {modal.action === "edit" && "Редактирование пункта выдачи заказа"}
            </h1>
            {modal.action !== "delete" && (
              <div className="w-full mt-[30px]">
                <TextField
                  fullWidth
                  label="Адрес"
                  required
                  value={modal.values.name}
                  onChange={(event) => {
                    setModal({
                      ...modal,
                      values: {
                        ...modal.values,
                        name: event.target.value,
                      },
                    });
                  }}
                />
              </div>
            )}

            <div className="w-full flex justify-center gap-[30px] items-center mt-[50px]">
              <LoadingButton
                variant="contained"
                loading={modal.requested}
                disabled={modal.requested}
                type="submit"
                className="w-[252px] bg-gradient"
              >
                {modal.action === "add" && "Готово"}
                {modal.action === "delete" && "Удалить"}
                {modal.action === "edit" && "Готово"}
              </LoadingButton>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
