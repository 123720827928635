import React, { useEffect, useState } from "react";
import "../styles/header.css";
import logo from "../assets/images/logo.png";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import HomeIcon from "@mui/icons-material/Home";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import LogoutIcon from "@mui/icons-material/Logout";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import GroupIcon from "@mui/icons-material/Group";
import NotificationsIcon from "@mui/icons-material/Notifications";
import EditIcon from "@mui/icons-material/Edit";

import {
  AppBar,
  Avatar,
  Box,
  Button,
  CssBaseline,
  Dialog,
  Divider,
  Drawer,
  FormControl,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Toolbar,
} from "@mui/material";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CachedIcon from "@mui/icons-material/Cached";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { LocalShipping } from "@mui/icons-material";
import { CurrencyService } from "../services/CurrencyService";
import { AddressesService } from "../services/AddressesService";
import { useSelector } from "react-redux";
import { StringAvatar } from "../helpers/helpers";
import { accessRules } from "../http/MiddleWare";
import { dispatch } from "../index";
import { logout } from "../store/slices/userSlice";

export const drawerWidth = 224;

function generateMenuItems(userPosition: string) {
  const menuItems = [
    {
      path: "/home",
      label: "Главная",
      icon: <HomeIcon />,
    },
    {
      path: "/personal",
      label: "Сотрудники",
      icon: <EmojiPeopleIcon />,
    },
    {
      path: "/orders",
      label: "Посылки",
      icon: <ViewComfyIcon />,
    },
    {
      path: "/ransom",
      label: "Выкуп",
      icon: <ShoppingBasketIcon />,
    },
    {
      path: "/tariff",
      label: "Тарифы",
      icon: <EqualizerIcon />,
    },
    {
      path: "/customers",
      label: "Клиенты",
      icon: <GroupIcon />,
    },
    {
      path: "/deliveryOrders",
      label: "Доставка",
      icon: <LocalShipping />,
    },
    {
      path: "/warehouse",
      label: "Склад",
      icon: <WarehouseIcon />,
    },
    {
      path: "/sorting",
      label: "Сортировка",
      icon: <AssignmentIcon />,
    },
    {
      path: "/queue",
      label: "Выдача",
      icon: <CachedIcon />,
    },
    {
      path: "/lostOrders",
      label: "Утерянные товары",
      icon: <SupervisorAccountIcon />,
    },
    {
      path: "/boxOffice",
      label: "Касса",
      icon: <AccountBalanceWalletIcon />,
    },
    {
      path: "/order-pick-up-points",
      label: "Пункты выдачи заказа",
      icon: <ReceiptLongIcon />,
    },
    {
      path: "/notifications",
      label: "Уведомления",
      icon: <NotificationsIcon />,
    },
  ];
  const rules = accessRules;

  return menuItems.filter((item: any) =>
    Array.from(new Set(rules[item.path])).some((elem: any) => {
      return elem.position === userPosition;
    })
  );
}

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.userData.user);

  const [currencyState, setCurrencyState] = useState({
    modal: false,
    updateCurrency: false,
    personalCountry: "",
    value: [],
  });

  const currencyList = CurrencyService.GetCurrency({
    country: currencyState.personalCountry,
    updateRequest: currencyState.updateCurrency,
  });
  const getPersonalCountries =
    AddressesService.GetPersonalCountries("customer");
  const handleCurrencyValuesChanges =
    (id: number) =>
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const currencyValues: any = currencyState.value;
      for (let i = 0; i < currencyValues.length; i++) {
        if (currencyValues[i].id === id) {
          currencyValues[i].value = event.target.value;
          event.currentTarget.value = event.target.value;
        }
      }
      setCurrencyState({
        ...currencyState,
        value: currencyValues,
      });
    };
  const submitChanges = () => {
    for (let i = 0; i < currencyState.value.length; i++) {
      CurrencyService.UpdateCurrency(currencyState.value[i]).then(() => {
        setCurrencyState({
          ...currencyState,
          modal: false,
          updateCurrency: !currencyState.updateCurrency,
        });
      });
    }
  };
  useEffect(() => {
    if (!getPersonalCountries.loading && !getPersonalCountries.error) {
      for (let i = 0; i < getPersonalCountries.result?.data.length; i++) {
        if (getPersonalCountries.result?.data[i].code === "KGZ") {
          setCurrencyState({
            ...currencyState,
            personalCountry: getPersonalCountries.result?.data[i].id,
          });
        }
      }
    }
  }, [getPersonalCountries.loading, getPersonalCountries.error]);
  useEffect(() => {
    if (!currencyList.loading && !currencyList.error) {
      const arr: any = [];
      for (let i = 0; i < currencyList.result?.data.length; i++) {
        arr.push({
          id: currencyList.result?.data[i].id,
          value: currencyList.result?.data[i].value,
          code: currencyList.result?.data[i].code,
          name: currencyList.result?.data[i].name,
        });
      }
      setCurrencyState((prevState: any) => ({
        ...prevState,
        value: arr,
      }));
    }
  }, [currencyList.loading, currencyList.error, currencyList.result?.data]);

  const menuItems = generateMenuItems(user?.group);

  const handleLogout = async () => {
    await dispatch(logout());
    window.location.reload();
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            background: "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
          }}
        >
          <div className="w-full flex justify-between">
            <Toolbar sx={{ width: drawerWidth }}>
              <Link to="/" className="header__logo">
                <img src={logo} alt="logo" />
              </Link>
            </Toolbar>

            <Toolbar>
              <div className="min-w-[200px] flex justify-end items-center gap-[10px]">
                <p>{`${user.group}`}</p>
                <Link to="personal">
                  <Avatar
                    {...StringAvatar(`${user.first_name} ${user.last_name}`)}
                  ></Avatar>
                </Link>
              </div>
            </Toolbar>
          </div>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: "auto" }}>
            <List>
              {menuItems.map((item, index) => (
                <ListItem
                  onClick={() => navigate(item.path)}
                  key={index}
                  disablePadding
                  sx={{ width: "100%" }}
                >
                  <ListItemButton
                    sx={{ position: "relative" }}
                    selected={location.pathname.includes(item.path)}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} />
                    {location.pathname.includes(item.path) && (
                      <div className="absolute right-0 top-0 bg-[#282828] rounded-l-lg w-[6px] h-full"></div>
                    )}
                  </ListItemButton>
                </ListItem>
              ))}

              <Divider />
              <ListItem disablePadding>
                <ListItemButton onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary={"Выход"} />
                </ListItemButton>
              </ListItem>
            </List>
            <Toolbar />
            <div className="header-sideBar__currencyBox">
              <div className="headerSideBar__currencyBoxIconBox header-sideBarLink">
                <AttachMoneyIcon className="header-sideBarLinkIcon" />
                <div className="header-sideBarLinkText">
                  <FormControl required size="small">
                    <Select
                      sx={{
                        boxShadow: "none",
                        outline: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        ".Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: 0,
                        },
                      }}
                      labelId="firm-select-label"
                      id="demo-select-small"
                      value={currencyState.personalCountry}
                      onChange={(event) => {
                        setCurrencyState({
                          ...currencyState,
                          personalCountry: event.target.value,
                        });
                      }}
                      defaultValue=""
                    >
                      {getPersonalCountries.loading ? (
                        <div>loading</div>
                      ) : getPersonalCountries.error ? (
                        <div>Error</div>
                      ) : (
                        getPersonalCountries.result?.data.map((item: any) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.code}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                  <span
                    onClick={() => {
                      setCurrencyState({
                        ...currencyState,
                        modal: true,
                      });
                    }}
                  >
                    <EditIcon color="secondary" />
                  </span>
                </div>
              </div>
              <div className="headerSideBar__currencyBoxCurrencyBox">
                {!currencyList.loading &&
                  !currencyList.error &&
                  currencyList.result?.data.map(
                    (currency: any, index: number) => (
                      <div key={index}>
                        <p>{currency.code}</p>
                        <span>{currency.value}</span>
                      </div>
                    )
                  )}
              </div>
            </div>
          </Box>
          <Dialog
            onClose={() => {
              setCurrencyState({ ...currencyState, modal: false });
            }}
            open={currencyState.modal}
          >
            <form
              onSubmit={(event) => {
                event.preventDefault();
                submitChanges();
              }}
              style={{
                padding: "20px 56px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="header-currencyBox__avatar">
                <EmojiPeopleIcon sx={{ color: "white" }} />
              </div>
              <h1 className="header-currencyBox__title">
                Редактирование курса валют
              </h1>
              <FormControl sx={{ width: "100%", height: 40 }} size="small">
                <Select
                  labelId="firm-select-label"
                  id="demo-select-small"
                  value={currencyState.personalCountry}
                  onChange={(event) => {
                    setCurrencyState({
                      ...currencyState,
                      personalCountry: event.target.value,
                    });
                  }}
                  required={true}
                  defaultValue=""
                >
                  {!getPersonalCountries.loading &&
                    !getPersonalCountries.error &&
                    getPersonalCountries.result?.data.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.country}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {!currencyList.loading &&
                !currencyList.error &&
                currencyList.result?.data.map(
                  (currency: any, index: number) => (
                    <TextField
                      key={index}
                      sx={{ width: "328px" }}
                      margin="normal"
                      label={currency.code}
                      defaultValue={currency.value}
                      required={true}
                      onChange={handleCurrencyValuesChanges(currency.id)}
                    />
                  )
                )}

              <Button
                sx={{
                  background:
                    "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
                  height: "56px",
                  width: "328px",
                  color: "#FFFFFF",
                  cursor: "pointer",
                  marginTop: "20px",
                  textTransform: "uppercase",
                }}
                type="submit"
              >
                Применить
              </Button>
            </form>
          </Dialog>
        </Drawer>
      </Box>
    </>
  );
}

export default Header;
