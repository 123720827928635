import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import "../../styles/orders.css";
import Shop2Icon from "@mui/icons-material/Shop2";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment/moment";
import { OrdersService } from "../../services/OrdersService";
import { AddressesService } from "../../services/AddressesService";
import { StoresService } from "../../services/StoresService";

const tableInitialValues = {
  rows: [],
  status: {
    loading: false,
    error: false,
    message: "",
  },
  filter: {
    country: "",
    store: "",
    search: "",
    status__status: "showAll",
    page: 1,
    size: 20,
    total_pages: 1,
  },
};

export default function Orders() {
  const navigate = useNavigate();
  const [table, setTable] = useState<any>({
    ...tableInitialValues,
    columns: [
      { field: "id", headerName: "No", flex: 1, sortable: false, hide: true },
      {
        field: "user_id",
        headerName: "Код",
        sortable: false,
        flex: 1,
        renderCell: (params: any) => params.row.user?.id,
      },
      {
        field: "full_name",
        headerName: "Клиент",
        sortable: false,
        flex: 1,
        renderCell: (params: any) =>
          params.row.user !== null
            ? params.row.user?.full_name
            : params.row.attribute,
      },
      {
        field: "tracking_number",
        headerName: "Трек",
        sortable: false,
        flex: 1,
      },
      {
        field: "store",
        headerName: "Магазин",
        sortable: false,
        flex: 1,
        renderCell: (params: any) => params.row.store?.name,
      },
      {
        field: "country",
        headerName: "Страна",
        sortable: false,
        flex: 1,
        renderCell: (params: any) => params.row.country?.name,
      },
      { field: "rounded_weight", headerName: "Вес", sortable: false, flex: 1 },
      {
        field: "created_at",
        headerName: "Дата создания",
        sortable: false,
        flex: 1,
        renderCell: (params: any) =>
          moment(params.row.created_at).format("DD.MM.YYYY HH:mm").toString(),
      },
      {
        field: "payment",
        headerName: "Оплата",
        flex: 1,
        sortable: false,
        renderCell: (params: any) =>
          params.row.payment ? (
            <p style={{ color: "#009D35" }}>оплачено</p>
          ) : (
            <p style={{ color: "#FF0000" }}>Не оплачено</p>
          ),
      },
      {
        field: "type_of_transport",
        headerName: "Транспорт",
        sortable: false,
        flex: 1,
        renderCell: (params: any) => (
          <img src={params.row.type_of_transport.icon} alt="icon" />
        ),
      },
      {
        field: "status",
        headerName: "Статус",
        sortable: false,
        flex: 1,
        renderCell: (params: any) => (
          <img src={params.row.status?.icon} alt="icon" />
        ),
      },
    ],
  });
  const [ordersTitle, setOrdersTitle] = useState("Показать все");

  const getOrderStatuses = OrdersService.GetOrderStatusButtons();
  const getCountries = AddressesService.GetCountries();
  const getStores = StoresService.GetStores();
  const tableList = OrdersService.GetOrders(table.filter);

  useEffect(() => {
    if (tableList.loading) {
      setTable((prevState: any) => ({
        ...prevState,
        status: {
          ...prevState.status,
          loading: true,
        },
      }));
    } else if (tableList.error) {
      setTable((prevState: any) => ({
        ...prevState,
        status: {
          ...prevState.status,
          loading: false,
          error: true,
        },
      }));
    } else {
      const data = tableList.result?.data;
      setTable((prevState: any) => ({
        ...prevState,
        rows: data.results,
        status: {
          ...prevState.status,
          loading: false,
          error: false,
        },
        filter: {
          ...prevState.filter,
          page: data.current_page,
          total_pages: data.total_pages,
        },
        selectedRows: [],
      }));
    }
  }, [tableList.loading, tableList.error, tableList.result?.data]);

  return (
    <>
      <h1 className="text-[30px] font-[400] text-black my-[50px]">
        Статусы заказов
      </h1>
      <div className="orderStatus__buttons">
        <div
          className={
            table.filter.status__status === "showAll"
              ? `orderStatus__button orderStatus__buttonAll orderStatus__buttonSelected`
              : `orderStatus__button orderStatus__buttonAll`
          }
          onClick={() => {
            setTable({
              ...table,
              filter: {
                ...table.filter,
                status__status: "showAll",
                page: 1,
              },
            });
            setOrdersTitle("Показать все");
          }}
        >
          <Shop2Icon sx={{ color: "#48A5D1" }} />
          <p className="orderStatus__buttonText">Показать все</p>
          <p className="orderStatus__buttonNum">
            {!getOrderStatuses.loading &&
              !getOrderStatuses.error &&
              getOrderStatuses.result?.data
                .map((result: any) => result.count)
                .reduce((partialSum: any, a: any) => partialSum + a, 0)}
          </p>
        </div>
        {getOrderStatuses.loading ? (
          <CircularProgress />
        ) : getOrderStatuses.error ? (
          <div>Error</div>
        ) : (
          getOrderStatuses.result?.data.map((item: any, index: number) => (
            <div
              className={
                table.filter.status__status === item.status
                  ? `orderStatus__button ${
                      item.status === "showAll" && "orderStatus__buttonAll"
                    } orderStatus__buttonSelected`
                  : `orderStatus__button ${
                      item.status === "showAll" && "orderStatus__buttonAll"
                    }`
              }
              key={index}
              onClick={() => {
                setTable({
                  ...table,
                  filter: {
                    ...table.filter,
                    page: 1,
                    status__status: item.status,
                  },
                });
                setOrdersTitle(item.name);
              }}
            >
              <img src={item.icon} alt="icon" />
              <p className="orderStatus__buttonText">{item.name}</p>
              <p className="orderStatus__buttonNum">{item.count}</p>
            </div>
          ))
        )}
      </div>
      <Stack
        width="100%"
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <h1 className="orderTableBox__title">{ordersTitle}</h1>
        <Stack
          direction="row"
          spacing="20px"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          marginBottom="20px"
        >
          <div className="flex items-center gap-[20px]">
            <FormControl sx={{ width: "160px" }} size="small">
              <InputLabel>Страна</InputLabel>
              <Select
                value={table.filter.country}
                label="Страна"
                onChange={(event) => {
                  setTable({
                    ...table,
                    filter: {
                      ...table.filter,
                      page: 1,
                      country: event.target.value,
                    },
                  });
                }}
                defaultValue=""
              >
                <MenuItem value="">
                  <div style={{ fontStyle: "italic" }}>Убрать фильтр</div>
                </MenuItem>
                {getCountries.loading ? (
                  <div>loading</div>
                ) : getCountries.error ? (
                  <div>Error</div>
                ) : (
                  getCountries.result?.data.map((item: any) => (
                    <MenuItem key={item.id} id={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
            <FormControl sx={{ width: "160px" }} size="small">
              <InputLabel>Магазин</InputLabel>
              <Select
                value={table.filter.store}
                label="Магазин"
                onChange={(event) => {
                  setTable({
                    ...table,
                    filter: {
                      ...table.filter,
                      page: 1,
                      store: event.target.value,
                    },
                  });
                }}
              >
                <MenuItem value="">
                  <div style={{ fontStyle: "italic" }}>Убрать фильтр</div>
                </MenuItem>
                {getStores.loading ? (
                  <div>loading</div>
                ) : getStores.error ? (
                  <div>Error</div>
                ) : (
                  getStores.result?.data.map((item: any) => (
                    <MenuItem key={item.id} id={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </div>
          <TextField
            label="Поиск посылки"
            value={table.filter.search}
            sx={{ width: "340px" }}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fill: "#48A5D1" }} />
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              setTable({
                ...table,
                filter: {
                  ...table.filter,
                  page: 1,
                  search: event.target.value,
                },
              });
            }}
          />
        </Stack>

        <div
          style={{ width: "100%", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)" }}
        >
          <DataGrid
            rows={table.rows}
            columns={table.columns}
            disableColumnFilter
            disableColumnMenu
            filterMode="server"
            autoHeight
            localeText={{
              noRowsLabel: "Нет информации",
            }}
            loading={tableList.loading}
            disableSelectionOnClick
            onRowDoubleClick={(params) => {
              navigate(`orderViewPage/${params.row.id}`);
            }}
            components={{
              Footer: () => {
                return (
                  <GridFooterContainer>
                    <Pagination
                      count={table.filter.total_pages}
                      page={table.filter.page}
                      onChange={(event, value: number) => {
                        setTable({
                          ...table,
                          filter: {
                            ...table.filter,
                            page: value,
                          },
                        });
                      }}
                    />
                  </GridFooterContainer>
                );
              },
            }}
          />
        </div>
      </Stack>
    </>
  );
}
