import {useAsync} from "react-async-hook";
import {$axios} from "../http/axiosInstance";
import {CreateSearchParams} from "../helpers/helpers";

export const LostOrdersService = {
    GetLostOrdersList(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/lost/orders/' + CreateSearchParams(searchParamsObj));
        }, [CreateSearchParams(searchParamsObj)])
    },
    async LostOrderUpdate(ordersId: any, user_id: any) {
        return await $axios.post('/admin/lost/order/update/', {
            orders: ordersId, user: user_id,
        })
    }
}