import {useAsync} from "react-async-hook";
import {$axios} from "../http/axiosInstance";
import {CreateSearchParams} from "../helpers/helpers";

export const OrdersService = {
    GetOrders(searchParamsObj: any) {
        return useAsync(async () => {
            return await $axios.get('/admin/orders/' + CreateSearchParams(searchParamsObj));
        }, [CreateSearchParams(searchParamsObj)])
    },

    GetOrderStatusButtons() {
        return useAsync(async () => {
            return await $axios.get('/statuses/');
        }, [])
    },

    GetOrderInfo(order_id: string | undefined) {
        return useAsync(async () => {
            return await $axios.get(`/admin/orders/${order_id}`);
        }, [order_id])
    },
}