import React, {useEffect, useState} from 'react';
import '../../styles/customers.css'
import {DataGrid, GridFooterContainer, GridHeader} from "@mui/x-data-grid";
import {
    Button, Dialog,
    IconButton, InputAdornment,
    Pagination, Stack, TextField,
} from "@mui/material";
import {DeliveryService} from "../../services/DeliveryService";
import {DeliveryTableI} from "../../models/DeliveryTableI";
import {DeliveryI} from "../../models/DeliveryI";
import moment from "moment/moment";
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import {useNavigate} from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

const tableFilterInitialState = {
    page: 1,
    total_pages: 1,
    search: '',
    updateTable: false,
}
const commentModalInitialState = {
    modal: false,
    comment: '',
    action: '',
    delivery_ids: [],
}

function DeliveryOrders() {
    const navigate = useNavigate()

    const [newComment, setNewComment] = useState<any>(commentModalInitialState)

    const [table, setTable] = useState<DeliveryTableI>({
        selectedRows: [],
        filter: tableFilterInitialState,
        rows: [],
        columns:
            [
                {field: 'id', headerName: 'ID', width: 100, sortable: false, hide: true},
                {field: 'user_id', headerName: 'ID клиента', flex: 1, sortable: false},
                {field: 'full_name', headerName: 'ФИО', flex: 1, sortable: false},
                {field: 'created_at', headerName: 'Дата создания', flex: 1, sortable: false},
                {field: 'phone', headerName: `Телефон`, flex: 1, sortable: false},
                {field: 'address', headerName: `Адрес`, flex: 1, sortable: false},
                {
                    field: 'status',
                    headerName: `Статус`,
                    flex: 1,
                    sortable: false,
                    renderCell: (params) => (
                        params.row.status.name
                    )
                },
                {
                    field: 'actions',
                    headerName: `Действия`,
                    flex: 1,
                    sortable: false,
                    renderCell: (params) => (
                        params.row.status.status === 'waiting' &&
                        <Stack direction='row' spacing='10px'>
                            <IconButton title='отклонить' color="secondary" aria-label="add an alarm" onClick={() => {
                                setNewComment({
                                    ...newComment,
                                    modal: true,
                                    action: 'reject',
                                    delivery_ids: [params.row.id]
                                })
                            }}>
                                <ClearIcon style={{color: "red"}}/>
                            </IconButton>
                            <IconButton title='принять' color="secondary" aria-label="add an alarm" onClick={() => {
                                setNewComment({
                                    ...newComment,
                                    modal: true,
                                    action: 'accept',
                                    delivery_ids: [params.row.id]
                                })
                            }}>

                                <CheckIcon style={{color: "green"}}/>
                            </IconButton>
                        </Stack>
                    )
                },
            ]
    })

    const handleSubmitForm = () => {
        DeliveryService.SetComment({
            action: newComment.action,
            comment: newComment.comment,
            delivery_ids: newComment.delivery_ids.length === 0 ? table.selectedRows : newComment.delivery_ids,
        }).then(() => {
            handleFormClose()
        })
    }

    const handleFormClose = () => {
        setNewComment(commentModalInitialState)
        setTable({
            ...table,
            selectedRows: [],
            filter: {
                ...table.filter,
                updateTable: !table.filter.updateTable
            }
        })
    }

    const deliveryList = DeliveryService.GetDeliveryList(table.filter)

    useEffect(() => {
        if (!deliveryList.loading && !deliveryList.error) {
            const rows: DeliveryI[] = []
            for (let i = 0; i < deliveryList.result?.data.results.length; i++) {
                let delivery = deliveryList.result?.data.results[i]
                rows[i] = {
                    id: delivery.id,
                    user_id: delivery.user.id,
                    full_name: delivery.user.full_name,
                    created_at: moment(delivery.created_at).format('DD.MM.YYYY').toString(),
                    phone: delivery.phone,
                    address: delivery.address,
                    status: delivery.status,
                }
            }
            setTable((prevState) => ({
                ...prevState,
                filter: {
                    ...prevState.filter,
                    total_pages: deliveryList.result?.data.total_pages,
                },
                rows: rows
            }))
        }
    }, [deliveryList.error, deliveryList.loading, deliveryList.result?.data.results])

    return (
        <>
            <p className="componentMainWrapper__title">Заявки на доставку</p>
            <span className="componentMainWrapper__subTitle">Товарищи! укрепление и развитие структуры в значительной степени обуславливает создание новых предложений. </span>

            <Stack direction='row' spacing='20px' justifyContent='space-between' alignItems='center' width='100%'
                   marginBottom='20px'>
                <Stack direction='row' spacing='20px'>
                </Stack>
                <Stack direction='row' spacing='20px'>
                    <TextField
                        label="Поиск"
                        id="search-bar"
                        value={table.filter.search}
                        sx={{width: '340px'}}
                        size='small'
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton>
                                    <SearchIcon style={{fill: "#48A5D1"}}/>
                                </IconButton>
                            </InputAdornment>,
                        }}
                        onChange={(event) => {
                            setTable({
                                ...table,
                                filter: {
                                    ...table.filter,
                                    search: event.target.value,
                                }
                            })
                        }}
                    />
                    <Button
                        sx={{
                            minWidth: "150px",
                            height: "36px",
                            background: "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
                            boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
                            borderRadius: "4px",
                            color: 'white',
                        }}
                        onClick={() => {
                            setNewComment({
                                ...newComment,
                                modal: true,
                            })
                        }}
                    >
                        Добавить комментарий
                    </Button>
                </Stack>
            </Stack>

            <div style={{width: '100%', borderRadius: '10px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'}}>
                <DataGrid
                    rows={table.rows}
                    columns={table.columns}
                    disableColumnFilter
                    keepNonExistentRowsSelected
                    filterMode='server'
                    autoHeight
                    checkboxSelection
                    selectionModel={table.selectedRows}
                    onSelectionModelChange={(value) => {
                        setTable({
                            ...table,
                            selectedRows: value
                        })
                    }}
                    onRowDoubleClick={(params) => {
                        navigate(`/deliveryOrders/details/${params.row.id}/`)
                    }}
                    loading={deliveryList.loading}
                    disableSelectionOnClick
                    localeText={{
                        noRowsLabel: "Нет информации",
                      }}
                    components={{
                        Header: () => {
                            return (
                                <GridHeader>

                                </GridHeader>
                            );
                        },
                        Footer: () => {
                            return (
                                <GridFooterContainer>
                                    <Pagination
                                        count={table.filter.total_pages}
                                        page={table.filter.page}
                                        onChange={(event, value: number) => {
                                            setTable({
                                                ...table,
                                                filter: {
                                                    ...table.filter,
                                                    page: value,
                                                }
                                            })
                                        }}
                                    />
                                </GridFooterContainer>
                            );
                        },
                    }}
                />
            </div>

            <Dialog onClose={() => {
                handleFormClose()
            }} open={newComment.modal} sx={{zIndex: '999'}}>
                <form onSubmit={(event) => {
                    event.preventDefault()
                    handleSubmitForm()
                }}
                      style={{
                          padding: '20px 56px',
                          maxWidth: '600px',
                          width: '600px',
                          position: "relative",
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'center'
                      }}>
                    <IconButton sx={{position: 'absolute', right: '20px', top: '10px'}} onClick={() => {
                        handleFormClose()
                    }}>
                        <CloseIcon/>
                    </IconButton>
                    <h1 className='header-currencyBox__title'>Добавьте комментарий</h1>

                    <TextField
                        fullWidth={true}
                        multiline
                        rows={2}
                        maxRows={4}
                        value={newComment.comment}
                        onChange={(event) => {
                            setNewComment({...newComment, comment: event.target.value})
                        }}
                    />

                    <Stack direction='row' spacing='20px' sx={{marginTop: '30px'}}>
                        <Button
                            sx={{
                                background: "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
                                color: "#FFFFFF",
                                cursor: "pointer",
                                textTransform: "uppercase",
                            }}
                            type='submit'
                        >
                            Подтвердить
                        </Button>
                    </Stack>
                </form>
            </Dialog>
        </>
    );
}

export default DeliveryOrders;

