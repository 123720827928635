import React, {useEffect, useState} from 'react';
import '../../styles/customers.css'
import {DataGrid, GridColDef, GridFooterContainer, GridHeader} from "@mui/x-data-grid";
import {
    Autocomplete,
    Button, Dialog,
    IconButton,
    InputAdornment,
    Pagination,
    Stack,
    TextField
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from "@mui/icons-material/Close";
import {LostOrdersService} from "../../services/LostOrdersService";
import {CustomerService} from "../../services/CustomerService";

function LostOrders() {
    const [selectedOrdersId, setSelectedOrdersId] = useState<any>([])
    const mainTableColumns: GridColDef[] = [
        {field: 'id', headerName: 'ID', width: 100, hide: true},
        {field: 'user_id', headerName: 'User ID ', width: 90},
        {field: 'name', headerName: 'ФИО', width: 200},
        {field: 'tracking_number', headerName: 'Трек', width: 200},
        {field: 'store', headerName: 'Магазин', width: 160},
        {field: 'country', headerName: 'Страна', width: 140},
        {field: 'rounded_weight', headerName: 'Окрг. вес', width: 100},
        {field: 'price', headerName: 'Цена', width: 160},
        {
            field: 'status', headerName: 'Статус', width: 70, renderCell: (params) => {
                return (
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                         onClick={(event) => {
                             event.stopPropagation()
                         }}>
                        <img src={params.row.status.icon} alt="icon"/>
                    </div>
                )
            }
        },
    ];
    const [filterValue, setFilterValue] = React.useState<any>({
        search: '',
        page: 1,
        refresh_table: false,
    })
    const lostOrdersList = LostOrdersService.GetLostOrdersList(filterValue)

    const [modalOpened, setModalOpened] = useState<boolean>(false)

    const [customerInfo, setCustomerInfo] = useState<any>({
        id: '',
        full_name: '',
    })
    const customersList = CustomerService.GetCustomersListBySearch({search: customerInfo.full_name})
    const customerById = CustomerService.GetCustomersListBySearch({search: customerInfo.id})
    useEffect(() => {
        if (!customerById.loading && !customerById.error) {
            const data = customerById.result?.data
            if (data.length > 0) {
                setCustomerInfo((prevState: any) => ({
                    ...prevState,
                    full_name: data[0].full_name,
                }))
            } else {
                setCustomerInfo((prevState: any) => ({
                    ...prevState,
                    full_name: '',
                }))
            }
        }
    }, [customerById.loading, customerById.error, customerById.result?.data])

    return (
        <>
            <p className="componentMainWrapper__title mb-[40px]">Утерянные товары</p>

            <Stack direction='row' spacing='20px' justifyContent='space-between' alignItems='center' width='100%'
                   marginBottom='20px'>
                <Stack direction='row' spacing='20px'>
                </Stack>
                <Stack direction='row' spacing='20px'>
                    <TextField
                        label="Поиск"
                        id="search-bar"
                        defaultValue={filterValue.search}
                        sx={{width: '340px'}}
                        size='small'
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton>
                                    <SearchIcon style={{fill: "#48A5D1"}}/>
                                </IconButton>
                            </InputAdornment>,
                        }}
                        onChange={(event) => {
                            setFilterValue({
                                ...filterValue,
                                search: event.target.value,
                            })
                        }}
                    />
                    <Button
                        sx={{
                            width: "200px",
                            height: "36px",
                            background: "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
                            boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
                            borderRadius: "4px",
                            color: 'white',
                            margin: '20px'
                        }}
                        disabled={!(selectedOrdersId.length > 0)}
                        onClick={() => {
                            setModalOpened(true)
                        }}
                    >
                        привязать к
                    </Button>
                </Stack>
            </Stack>
            <div style={{width: '100%', borderRadius: '10px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'}}>
                <DataGrid
                    rows={(!lostOrdersList.loading && !lostOrdersList.error)
                        ?
                        lostOrdersList.result?.data.results.map((item: any) => ({
                            id: item.id,
                            user_id: item.user !== null ? item.user.id : '',
                            name: item.user !== null ? item.user.full_name : item.attribute,
                            tracking_number: item.tracking_number,
                            store: item.store?.name,
                            country: item.country.name,
                            rounded_weight: Math.round(item.rounded_weight),
                            price: item.total_price,
                            status: item.status,
                        }))
                        : []
                    }
                    columns={mainTableColumns}
                    disableColumnFilter
                    keepNonExistentRowsSelected
                    filterMode='server'
                    autoHeight
                    checkboxSelection
                    localeText={{
                        noRowsLabel: "Нет информации",
                      }}
                    onSelectionModelChange={(value) => {
                        setSelectedOrdersId(value)
                    }}
                    selectionModel={selectedOrdersId}
                    loading={lostOrdersList.loading}
                    disableSelectionOnClick
                    components={{
                        Header: () => {
                            return (
                                <GridHeader>

                                </GridHeader>
                            );
                        },
                        Footer: () => {
                            return (
                                <GridFooterContainer>
                                    <Pagination
                                        count={!lostOrdersList.loading && !lostOrdersList.error ? lostOrdersList.result?.data.total_pages : 1}
                                        page={filterValue.page}
                                        onChange={(event, value: number) => {
                                            setFilterValue({
                                                ...filterValue,
                                                page: value,
                                            })
                                        }}
                                    />
                                </GridFooterContainer>
                            );
                        },
                    }}
                />
            </div>

            <Dialog onClose={() => {
                setModalOpened(false)
            }} maxWidth={false} open={modalOpened} sx={{zIndex: '999'}}>
                <form onSubmit={(event) => {
                    event.preventDefault()
                    LostOrdersService.LostOrderUpdate(selectedOrdersId, customerInfo.id).then(() => {
                        setModalOpened(false)
                        setFilterValue({
                            ...filterValue,
                            refresh_table: !filterValue.refresh_table,
                            search: '',
                        })
                    }).catch((err) => {
                        console.log(err)
                    })
                }}
                      style={{
                          padding: '20px 56px',
                          position: "relative",
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          alignItems: 'center'
                      }}>
                    <IconButton sx={{position: 'absolute', right: '20px', top: '10px'}} onClick={() => {
                        setModalOpened(false)
                    }}>
                        <CloseIcon/>
                    </IconButton>
                    <h1 className='header-currencyBox__title'>Привязать к пользователю</h1>

                    <TextField
                        value={customerInfo.id}
                        placeholder='ID Пользователя'
                        fullWidth={true}
                        required={true}
                        InputProps={{sx: {height: 40, margin: '10px 0 10px 0'}}}
                        onChange={(event) => {
                            setCustomerInfo({
                                ...customerInfo,
                                id: event.target.value,
                            });
                        }}
                    />

                    <Autocomplete
                        freeSolo
                        isOptionEqualToValue={(option: any, value) => option.full_name === value}
                        getOptionLabel={(option: any) => option.full_name}
                        options={(!customersList.loading && !customersList.error) ? customersList.result?.data : []}
                        loading={customersList.loading}
                        onChange={(event: any, newValue: any,) => {
                            setCustomerInfo({
                                ...customerInfo,
                                id: newValue !== null ? newValue.id : '',
                                full_name: newValue !== null ? newValue.full_name : '',
                            });
                        }}
                        inputValue={customerInfo.full_name}
                        onInputChange={(event, newInputValue,) => {
                            setCustomerInfo({
                                ...customerInfo,
                                full_name: newInputValue !== null ? newInputValue : '',
                            });
                        }}
                        id="controllable-states-demo"
                        sx={{width: '100%', height: '40px', margin: '10px 0 10px 0'}}
                        size="small"
                        renderInput={(params) => <TextField {...params} placeholder="ФИО Пользователя"/>}
                    />

                    <Stack direction='row' spacing='20px' sx={{marginTop: '30px'}}>
                        <Button
                            sx={{
                                background: "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
                                color: "#FFFFFF",
                                cursor: "pointer",
                                textTransform: "uppercase",
                            }}
                            disabled={customerInfo.id === ''}
                            type='submit'
                        >
                            Привязать
                        </Button>
                    </Stack>
                </form>
            </Dialog>
        </>
    );
}

export default LostOrders;

