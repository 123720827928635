import React from "react";
import {createSearchParams} from "react-router-dom";
import {styled} from "@mui/material";

export function CreateSearchParams(params: any){
    const newSearchObj = {...params}
    const keys = Object.keys(newSearchObj)
    for(let i=0;i < keys.length;i++){
        if(newSearchObj[keys[i]] === '' || newSearchObj[keys[i]] === null){
            delete newSearchObj[keys[i]]
        }
    }
    const emptyOrder = !Object.keys(newSearchObj).length;
    return emptyOrder ? '' : `?${createSearchParams(newSearchObj)}`
}
export const JustNumber = (value: string) => {
    if(value === ''){
        return '0'
    }
    else{
        value = `${value}`
        return value.replace(/\D/g, '')
    }
}
export const JustNumberForMath = (value: string) => {
    if(value === ''){
        return '0'
    }
    else if(value[0]=== '0'){
        return value.replace(/\D/g, '').slice(1)
    }
    else{
        return value.replace(/\D/g, '')
    }
}
export function ImageToPrint(source: any) {
    return "<html><head><scri"+"pt>function step1(){\n" +
        "setTimeout('step2()', 10);}\n" +
        "function step2(){window.print();window.close()}\n" +
        "</scri" + "pt></head><body style='display: flex; height: min-content; justify-content: center;" +
        "align-items: flex-start' onload='step1()'>\n" +
        "<img src='" + source + "' /></body></html>";
}

export function PrintImage(source: any) {
    let PageLink = "about:blank";
    let pwa = window.open(PageLink, "_new");
    pwa?.document.open();
    pwa?.document.write(ImageToPrint(source));
    pwa?.document.close();
}

export const EmptyData = ({icon, text}: {icon: any, text: string}) => {
    return(
        <div className='emptyData'>
            <div className='emptyData__imgBox'>
                {icon}
            </div>
            <p>{text}</p>
        </div>
    )
}

export function OrdersEmptyIcon() {
    return(
        <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.3" clipPath="url(#clip0_2992_8553)">
                <path d="M15.0835 37.5C12.7918 37.5 10.9377 39.375 10.9377 41.6666C10.9377 43.9583 12.7918 45.8333 15.0835 45.8333C17.3752 45.8333 19.2502 43.9583 19.2502 41.6666C19.2502 39.375 17.3752 37.5 15.0835 37.5ZM2.5835 4.16663V8.33329H6.75016L14.2502 24.1458L11.4377 29.25C11.1043 29.8333 10.9168 30.5208 10.9168 31.25C10.9168 33.5416 12.7918 35.4166 15.0835 35.4166H40.0835V31.25H15.9585C15.6668 31.25 15.4377 31.0208 15.4377 30.7291L15.5002 30.4791L17.3752 27.0833H32.896C34.4585 27.0833 35.8335 26.2291 36.5418 24.9375L44.0002 11.4166C44.1668 11.125 44.2502 10.7708 44.2502 10.4166C44.2502 9.27079 43.3127 8.33329 42.1668 8.33329H11.3543L9.396 4.16663H2.5835ZM35.9168 37.5C33.6252 37.5 31.771 39.375 31.771 41.6666C31.771 43.9583 33.6252 45.8333 35.9168 45.8333C38.2085 45.8333 40.0835 43.9583 40.0835 41.6666C40.0835 39.375 38.2085 37.5 35.9168 37.5Z" fill="url(#paint0_linear_2992_8553)"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_2992_8553" x1="2.5835" y1="4.16663" x2="50.3662" y2="13.0924" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#48A5D1"/>
                    <stop offset="1" stopColor="#E52EC7"/>
                </linearGradient>
                <clipPath id="clip0_2992_8553">
                    <rect width="50" height="50" fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export function ValidateFormSubmitResponse(response: any[], errorFields: any, messageFields: any) {
    return new Promise((resolve, reject) => {
        const newArray: any[] = Object.entries(response).map(([key, value]) => ({ [key]: value }));
        const errors: any = errorFields;
        const messages: any = messageFields;


        for (let i = 0; i < newArray.length; i++) {
            const errorKey: string = Object.keys(newArray[i])[0];
            const message: string[] = newArray[i][errorKey][0];

            findAndModify(errors, errorKey, true);
            findAndModify(messages, errorKey, message);
        }

        resolve({ errors, messages });
        reject('something is wrong...')
    });
}

function findAndModify(obj:any, targetKey: any, newValue: any) {
    for (const key in obj) {
        if (key === targetKey) {
            obj[key] = newValue;
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            findAndModify(obj[key], targetKey, newValue);
        }
    }
}

export function checkModalResponse(responseData: any, setModal: any, modal: any) {
    ValidateFormSubmitResponse(responseData, modal.validation.error, modal.validation.message)
        .then((res: any) => {
            setModal((prevState: any)=>({
                ...prevState,
                validation: {
                    ...prevState.validation,
                    error: res.errors,
                    message: res.messages
                },
                requested: false
            }))
        }).catch((err) => {
        console.log(err)
    })
}
export function convertImageUrlToFile(url: any) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';

        xhr.onload = function () {
            if (xhr.status === 200) {
                const blob = new Blob([xhr.response], { type: 'image/jpeg' });
                const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
                resolve(file);
            } else {
                reject(new Error('Failed to convert image URL to file object.'));
            }
        };

        xhr.onerror = function () {
            reject(new Error('Failed to convert image URL to file object.'));
        };

        xhr.send();
    });
}
export function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}
export function StringAvatar(name: string) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


export function RemoveEmptyObj(params: any) {
    const newSearchObj = { ...params };
    const keys = Object.keys(newSearchObj);
    for (let i = 0; i < keys.length; i++) {
        if (newSearchObj[keys[i]] === "" || newSearchObj[keys[i]] === null) {
            delete newSearchObj[keys[i]];
        }
    }
    return newSearchObj;
}