import React, {useEffect, useState} from 'react';
import '../../styles/layout.css';
import {
    Autocomplete,
    Button, Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    MenuItem,
    Select,
    Stack,
    TextField
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {DataGrid, GridColDef, GridFooterContainer, GridHeader, GridValueSetterParams} from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/Delete';
import {JustNumber} from "../../helpers/helpers";
import {RansomService} from "../../services/RansomService";
import {StoresService} from "../../services/StoresService";
import {TransportsService} from "../../services/TransportsService";
import {AddressesService} from "../../services/AddressesService";
import {PaymentService} from "../../services/PaymentService";
import {CustomerService} from "../../services/CustomerService";
import {CurrencyService} from "../../services/CurrencyService";

function EditRansom() {
    const {id} = useParams()
    const navigate = useNavigate()
    const ransom = RansomService.GetRansomInfo(id)
    const getStores = StoresService.GetStores()
    let getTransports = TransportsService.GetTransports()
    let getCountries = AddressesService.GetCountries()
    let getPayments = PaymentService.GetPayments()
    const [orderValidation, setOrderValidation] = useState({
        tracking_numberCheck: false,
        tracking_numberText: '',
    })
    const [orderInfo, setOrderInfo] = useState<any>({
        order: {
            tracking_number: '',
            store: '',
            type_of_transport: '',
            country: '',
            delivery_country: '',
            leave_shoe_boxes: false,
            leave_gift_boxes: false,
        },
        customer: '',
        full_name: '',
        payment_type: '',
        currency_value: '',
        currency: '',
        withdrawal_amount: 0,
        order_total_price: 0,
        remains: 0,
        comment: '',
    })
    const [ransomProducts, setRansomProducts] = useState<any>([
        {
            id: 0,
            title: '',
            link: '',
            quantity: 0,
            price_per_unit: 0,
            commission: 0,
            total_price: 0,
        },
    ])
    const setFullName = (key: string) => (params: GridValueSetterParams) => {
        let order_total_price = 0
        setRansomProducts((prev: any) => {
            const newArray = prev
            if (key === 'quantity' || key === 'price_per_unit' || key === 'commission') {
                if (params.value >= 0) {
                    newArray[params.row.id][key] = JustNumber(params.value)
                    if (key === 'quantity') {
                        newArray[params.row.id].total_price = (parseInt(newArray[params.row.id].price_per_unit) * parseInt(JustNumber(params.value)) + parseInt(newArray[params.row.id].commission))
                    }
                    if (key === 'price_per_unit') {
                        newArray[params.row.id].total_price = (parseInt(JustNumber(params.value)) * parseInt(newArray[params.row.id].quantity)) + parseInt(newArray[params.row.id].commission)
                    }
                    if (key === 'commission') {
                        newArray[params.row.id].total_price = (parseInt(newArray[params.row.id].price_per_unit) * parseInt(newArray[params.row.id].quantity)) + parseInt(JustNumber(params.value))
                    }
                }
            } else {
                newArray[params.row.id][key] = params.value
            }
            for (let i = 0; i < newArray.length; i++) {
                order_total_price = order_total_price + parseInt(newArray[i].total_price)
            }
            return [
                ...newArray
            ]
        })
        setOrderInfo({
            ...orderInfo,
            order_total_price: order_total_price * orderInfo.currency_value,
        })
        return {...params.row};
    }
    const mainTableColumns: GridColDef[] = [
        {field: 'id', headerName: 'ID', width: 80, hide: true},
        {
            field: 'title',
            headerName: 'Товар',
            width: 200,
            editable: true,
            valueSetter: setFullName('title'),
            renderCell: (params) => {
                return (
                    params.row.title === ''
                        ? <p style={{color: '#6B6B6B'}}>Товар</p>
                        : params.row.title
                )
            }
        },
        {
            field: 'link',
            headerName: 'Ссылка на товар',
            width: 200,
            editable: true,
            valueSetter: setFullName('link'),
            renderCell: (params) => {
                return (
                    params.row.link === ''
                        ? <p style={{color: '#6B6B6B'}}>Ссылка на товар</p>
                        : <a style={{color: '#48a5d1', textDecoration: 'underline'}} href={params.row.link}>Ссылка на
                            товар</a>
                )
            }
        },
        {
            field: 'quantity',
            headerName: 'Кол-во',
            width: 120,
            editable: true,
            valueSetter: setFullName('quantity'),
            renderCell: (params) => {
                return (
                    params.row.quantity === ''
                        ? <p style={{color: '#6B6B6B'}}>Кол-во</p>
                        : params.row.quantity
                )
            }
        },
        {
            field: 'price_per_unit',
            headerName: 'Стоимость',
            width: 120,
            editable: true,
            valueSetter: setFullName('price_per_unit'),
            renderCell: (params) => {
                return (
                    params.row.price_per_unit === ''
                        ? <p style={{color: '#6B6B6B'}}>Стоимость</p>
                        : params.row.price_per_unit
                )
            }
        },
        {
            field: 'commission',
            headerName: 'Комиссия',
            width: 120,
            editable: true,
            valueSetter: setFullName('commission'),
            renderCell: (params) => {
                return (
                    params.row.commission === ''
                        ? <p style={{color: '#6B6B6B'}}>Комиссия</p>
                        : params.row.commission
                )
            }
        },
        {field: 'total_price', headerName: 'Итог. стоимость', width: 150},
        {
            field: 'edit_icon', headerName: '', width: 50, renderCell: (params) => {
                return (
                    ransomProducts.length > 1 && <IconButton onClick={() => {
                        setRansomProducts((prev: any) => {
                            const newArray = prev
                            const index = newArray.findIndex((obj: any) => obj.id === params.row.id);
                            if (index !== -1) {
                                newArray.splice(index, 1);
                            }
                            return [
                                ...newArray
                            ]
                        })
                    }}>
                        <DeleteIcon/>
                    </IconButton>
                )
            }
        },
    ];
    const [customers, setCustomers] = useState<any>([])
    const customersList = CustomerService.GetCustomersListBySearch(orderInfo.full_name)
    const getCurrency = CurrencyService.GetCurrency({country: orderInfo.order.delivery_country})
    const handleSubmit = () => {
        const newObj = {
            ...orderInfo,
            ransom_products: ransomProducts,
        }
        if (newObj.order.tracking_number === '') {
            delete newObj.order
        }
        let checker = false
        for (let i = 0; i < newObj.ransom_products.length; i++) {
            checker = newObj.ransom_products[i].title === "" || newObj.ransom_products[i].quantity === 0 || newObj.ransom_products[i].price_per_unit === 0;
        }
        if (checker) {
            alert('Заполните поля продуктов!')
        } else {
            RansomService.UpdateRansom(newObj, id).then(() => {
                navigate({
                    pathname: '/ransom',
                })
            }).catch((err) => {
                console.log(err)
                if (err.response.data.order.tracking_number) {
                    setOrderValidation({
                        ...orderValidation,
                        tracking_numberCheck: true,
                        tracking_numberText: err.response.data.order.tracking_number[0],
                    })
                }
            })
        }
    }
    useEffect(() => {
        if (!customersList.loading && !customersList.error) {
            setCustomers(customersList.result?.data)
        }
    }, [customersList.loading])
    useEffect(() => {
        let order_total_price = 0
        for (let i = 0; i < ransomProducts.length; i++) {
            order_total_price = order_total_price + parseInt(ransomProducts[i].total_price)
        }
        setOrderInfo({
            ...orderInfo,
            order_total_price: order_total_price * orderInfo.currency_value,
        })
    }, [ransomProducts.length, orderInfo.currency_value])
    useEffect(() => {
        setOrderInfo({
            ...orderInfo,
            remains: orderInfo.order_total_price - orderInfo.withdrawal_amount,
        })
    }, [orderInfo.order_total_price, orderInfo.withdrawal_amount])
    useEffect(() => {
        if (!ransom.loading && !ransom.error) {
            setOrderInfo({
                ...orderInfo,
                order: ransom.result?.data.order !== null ? {
                        tracking_number: ransom.result?.data.order.tracking_number,
                        store: ransom.result?.data.order.store.id,
                        type_of_transport: ransom.result?.data.order.type_of_transport.id,
                        country: ransom.result?.data.order.country.id,
                        delivery_country: ransom.result?.data.customer?.user.country.id,
                        leave_shoe_boxes: ransom.result?.data.order.leave_shoe_boxes,
                        leave_gift_boxes: ransom.result?.data.order.leave_gift_boxes,
                    }
                    : {
                        tracking_number: '',
                        store: '',
                        type_of_transport: '',
                        country: '',
                        delivery_country: ransom.result?.data.customer?.user.country.id,
                    },
                comment: ransom.result?.data.comment,
                customer: ransom.result?.data.customer?.id,
                full_name: ransom.result?.data.full_name,
                payment_type: ransom.result?.data.payment_type.id,
                currency_value: ransom.result?.data.currency_value,
                currency: ransom.result?.data.currency.id,
                withdrawal_amount: ransom.result?.data.withdrawal_amount,
                order_total_price: ransom.result?.data.converted_total_price,
                remains: ransom.result?.data.remains,
            })
            const newArray = ransom.result?.data.ransom_products.map((product: any, index: number) => (
                {
                    id: index,
                    title: product.title,
                    link: product.link,
                    quantity: product.quantity,
                    price_per_unit: product.price_per_unit,
                    commission: product.commission,
                    total_price: (parseInt(product.price_per_unit) * parseInt(product.quantity) + parseInt(product.commission)),
                }
            ))
            setRansomProducts(newArray)
        }
    }, [ransom.loading])
    return (
        <>
            <p className="componentMainWrapper__title">Просмотр - редактирование выкупа</p>
            <span className="componentMainWrapper__subTitle">Товарищи! укрепление и развитие структуры в значительной степени обуславливает создание новых предложений. </span>

            <div className="newDataAdd">
                <div className="newDataAdd__title">Посылка</div>
                <form className="newDataAdd__form" onSubmit={(event) => {
                    event.preventDefault()
                    handleSubmit()
                }}>
                    <Stack width='100%' direction='row' spacing='20px'>
                        <div className="newDataAdd__textFieldBox">
                            <p>ФИО Пользователя:</p>
                            <Autocomplete
                                freeSolo
                                isOptionEqualToValue={(option: any, value) => option.id === value.id}
                                getOptionLabel={(option: any) => option.full_name}
                                options={customers}
                                loading={customersList.loading}
                                onChange={(event: any, newValue: any,) => {
                                    if (newValue !== null) {
                                        setOrderInfo({
                                            ...orderInfo,
                                            customer: newValue.id,
                                            full_name: newValue.full_name,
                                            order: {
                                                ...orderInfo.order,
                                                delivery_country: newValue.country,
                                            },
                                            currency_value: '',
                                        });
                                    }
                                }}
                                inputValue={orderInfo.full_name}
                                onInputChange={(event, newInputValue,) => {
                                    setOrderInfo({
                                        ...orderInfo,
                                        full_name: newInputValue !== null ? newInputValue : '',
                                    });
                                }}
                                onBlur={() => {
                                    if (orderInfo.customer === '') {
                                        setOrderInfo({
                                            ...orderInfo,
                                            full_name: '',
                                        });
                                    }
                                }}
                                id="controllable-states-demo"
                                sx={{width: '100%', height: '40px'}}
                                size="small"
                                renderInput={(params) => <TextField {...params} required
                                                                    placeholder="ФИО Пользователя"/>}
                            />
                        </div>
                        <div className="newDataAdd__textFieldBox">
                            <p>ID пользователя:</p>
                            <TextField
                                value={orderInfo.customer}
                                placeholder='ID пользователя'
                                fullWidth={true}
                                disabled
                                required={true}
                                InputProps={{sx: {height: 40}}}
                            />
                        </div>
                        <div className="newDataAdd__textFieldBox">
                            <p>Курс:</p>
                            <TextField
                                value={orderInfo.currency_value}
                                placeholder='Курс'
                                fullWidth={true}
                                disabled={true}
                                type='number'
                                InputProps={{sx: {height: 40}}}
                            />
                        </div>
                        <div className="newDataAdd__textFieldBox">
                            <p>Валюта:</p>
                            <FormControl sx={{width: '100%', height: 40}} size="small">
                                <Select
                                    labelId="firm-select-label"
                                    id="demo-select-small"
                                    value={orderInfo.currency}
                                    onChange={(event) => {
                                        let currencyValue = ''
                                        if (!getCurrency.loading && !getCurrency.error) {
                                            for (let i = 0; i < getCurrency.result?.data.length; i++) {
                                                if (getCurrency.result?.data[i].id === event.target.value) {
                                                    currencyValue = getCurrency.result?.data[i].value
                                                }
                                            }
                                        }
                                        setOrderInfo({
                                            ...orderInfo,
                                            currency: event.target.value,
                                            currency_value: currencyValue,
                                        })
                                    }}
                                    required={true}
                                    defaultValue=""
                                >
                                    {getCurrency.loading
                                        ? <div>loading</div>
                                        : getCurrency.error
                                            ? <div>Error</div>
                                            : getCurrency.result?.data.map((item: any) => (
                                                <MenuItem key={item.id} value={item.id}>{item.code}</MenuItem>
                                            ))
                                    }
                                </Select>
                            </FormControl>
                        </div>
                    </Stack>

                    <div style={{width: '100%', borderRadius: '10px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)'}}>
                        <DataGrid
                            rows={ransomProducts.map((product: any, index: number) => ({
                                id: index,
                                title: product.title,
                                link: product.link,
                                quantity: product.quantity,
                                price_per_unit: product.price_per_unit,
                                commission: product.commission,
                                total_price: product.total_price,
                            }))}
                            columns={mainTableColumns}
                            disableColumnFilter
                            filterMode='server'
                            autoHeight
                            localeText={{
                                noRowsLabel: "Нет информации",
                              }}
                            disableSelectionOnClick
                            components={{
                                Header: () => {
                                    return (
                                        <GridHeader>

                                        </GridHeader>
                                    );
                                },
                                Footer: () => {
                                    return (
                                        <GridFooterContainer>
                                            <Stack direction='row' spacing='20px' sx={{margin: '20px'}}>
                                                <Button
                                                    variant='outlined'
                                                    size='small'
                                                    onClick={() => {
                                                        setRansomProducts([
                                                            ...ransomProducts,
                                                            {
                                                                id: ransomProducts.length,
                                                                title: '',
                                                                link: '',
                                                                quantity: 0,
                                                                price_per_unit: 0,
                                                                commission: 0,
                                                                total_price: 0,
                                                            },
                                                        ])
                                                    }}
                                                >
                                                    Добавить товар
                                                </Button>
                                            </Stack>
                                        </GridFooterContainer>
                                    );
                                },
                            }}
                        />
                    </div>

                    <Stack width='100%' display='grid' columnGap='20px' rowGap='20px'
                           gridTemplateColumns='1fr 1fr 1fr 1fr'>
                        <div className="newDataAdd__textFieldBox">
                            <p>Вид оплаты:</p>
                            <FormControl sx={{width: '100%', height: 40}} size="small">
                                <Select
                                    labelId="firm-select-label"
                                    id="demo-select-small"
                                    value={orderInfo.payment_type}
                                    onChange={(event) => {
                                        setOrderInfo({
                                            ...orderInfo,
                                            payment_type: event.target.value
                                        })
                                    }}
                                    required={true}
                                    defaultValue=""
                                >
                                    {getPayments.loading
                                        ? <div>loading</div>
                                        : getPayments.error
                                            ? <div>Error</div>
                                            : getPayments.result?.data.map((item: any) => (
                                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                            ))
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className="newDataAdd__textFieldBox">
                            <p>Итого к оплате:</p>
                            <TextField
                                value={orderInfo.order_total_price}
                                placeholder='Итого к оплате'
                                fullWidth={true}
                                disabled={true}
                                InputProps={{sx: {height: 40}}}
                            />
                        </div>
                        <div className="newDataAdd__textFieldBox">
                            <p>Снятие денег:</p>
                            <TextField
                                value={orderInfo.withdrawal_amount}
                                placeholder='Снятие денег'
                                fullWidth={true}
                                required={true}
                                type='number'
                                InputProps={{sx: {height: 40}}}
                                onChange={(event) => {
                                    setOrderInfo({
                                        ...orderInfo,
                                        withdrawal_amount: event.target.value,
                                    })
                                }}
                            />
                        </div>
                        <div className="newDataAdd__textFieldBox">
                            <p>Остаток по оплате:</p>
                            <TextField
                                value={orderInfo.remains}
                                placeholder='Остаток по оплате'
                                fullWidth={true}
                                required={true}
                                InputProps={{sx: {height: 40}}}
                                disabled={true}
                            />
                        </div>
                        <div className="newDataAdd__textFieldBox">
                            <p>Магазин:</p>
                            <FormControl sx={{width: '100%', height: 40}} size="small">
                                <Select
                                    labelId="shop-select-label"
                                    id="shop-select"
                                    value={orderInfo.order.store}
                                    inputProps={{placeholder: 'Магазин'}}
                                    onChange={(event) => {
                                        setOrderInfo({
                                            ...orderInfo,
                                            order: {
                                                ...orderInfo.order,
                                                store: event.target.value,
                                            },
                                        })
                                    }}
                                    required={true}
                                    defaultValue=""
                                >
                                    {getStores.loading
                                        ? <div>loading</div>
                                        : getStores.error
                                            ? <div>Error</div>
                                            : getStores.result?.data.map((item: any) => (
                                                <MenuItem key={item.id} id={item.id}
                                                          value={item.id}>{item.name}</MenuItem>
                                            ))
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className="newDataAdd__textFieldBox">
                            <p>Транспорт:</p>
                            <FormControl sx={{width: '100%', height: 40}} size="small">
                                <Select
                                    labelId="firm-select-label"
                                    id="demo-select-small"
                                    value={orderInfo.order.type_of_transport}
                                    onChange={(event) => {
                                        setOrderInfo({
                                            ...orderInfo,
                                            order: {
                                                ...orderInfo.order,
                                                type_of_transport: event.target.value
                                            },
                                        })
                                    }}
                                    required={true}
                                    defaultValue=""
                                >
                                    {getTransports.loading
                                        ? <div>loading</div>
                                        : getTransports.error
                                            ? <div>Error</div>
                                            : getTransports.result?.data.map((item: any) => (
                                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                            ))
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className="newDataAdd__textFieldBox">
                            <p>Страна:</p>
                            <FormControl sx={{width: '100%', height: 40}} size="small">
                                <Select
                                    labelId="firm-select-label"
                                    id="demo-select-small"
                                    value={orderInfo.order.country}
                                    onChange={(event) => {
                                        setOrderInfo({
                                            ...orderInfo,
                                            order: {
                                                ...orderInfo.order,
                                                country: event.target.value
                                            },
                                        })
                                    }}
                                    required={true}
                                    defaultValue=""
                                >
                                    {getCountries.loading
                                        ? <div>loading</div>
                                        : getCountries.error
                                            ? <div>Error</div>
                                            : getCountries.result?.data.map((item: any) => (
                                                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                            ))
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className="newDataAdd__textFieldBox">
                            <p>Трек код:</p>
                            <TextField
                                value={orderInfo.order.tracking_number}
                                placeholder='Трек код'
                                fullWidth={true}
                                helperText={orderValidation.tracking_numberText}
                                error={orderValidation.tracking_numberCheck}
                                InputProps={{sx: {height: 40}}}
                                onChange={(event) => {
                                    setOrderInfo({
                                        ...orderInfo,
                                        order: {
                                            ...orderInfo.order,
                                            tracking_number: event.target.value,
                                        },
                                    });
                                }}
                            />
                        </div>
                    </Stack>

                    {orderInfo.order.country == '2' &&
                        <Stack direction='row' spacing='20px' width='100%' justifyContent='center' marginBottom='20px'>
                            <FormControlLabel
                                control={<Checkbox checked={orderInfo.order.leave_shoe_boxes} onChange={() => {
                                    setOrderInfo({
                                        ...orderInfo,
                                        order: {
                                            ...orderInfo.order,
                                            leave_shoe_boxes: !orderInfo.order.leave_shoe_boxes
                                        }
                                    })
                                }}/>} label={'Leave shoe boxes'}/>
                            <FormControlLabel
                                control={<Checkbox checked={orderInfo.order.leave_gift_boxes} onChange={() => {
                                    setOrderInfo({
                                        ...orderInfo,
                                        order: {
                                            ...orderInfo.order,
                                            leave_gift_boxes: !orderInfo.order.leave_gift_boxes
                                        }
                                    })
                                }}/>} label={'Leave gift boxes (bulk weight)'}/>
                        </Stack>
                    }
                    <div className="newDataAdd__textFieldBox">
                        <p>Комментарий:</p>
                        <TextField
                            value={orderInfo.comment}
                            placeholder='Комментарий'
                            fullWidth={true}
                            InputProps={{sx: {height: 100}}}
                            onChange={(event) => {
                                setOrderInfo({
                                    ...orderInfo,
                                    comment: event.target.value,
                                });
                            }}
                        />
                    </div>
                    <Stack direction='row' spacing='20px'>
                        <Button
                            variant='contained'
                            type='submit'
                        >
                            Сохранить
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                navigate({
                                    pathname: '/ransom',
                                })
                            }}
                        >Отменить</Button>
                    </Stack>
                </form>
            </div>
        </>
    );
}

export default EditRansom;

