import React, { FormEvent, useEffect, useState } from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Pagination,
  Select,
  TextField,
} from "@mui/material";
import "../../../styles/orders.css";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { AddressesService } from "../../../services/AddressesService";
import { BoxOfficeService } from "../../../services/BoxOfficeService";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { checkModalResponse } from "../../../helpers/helpers";

const tableInitialValues = {
  rows: [],
  filter: {
    delivery_country: "",
    search: "",
    page: 1,
    total_pages: 1,
    for_delivery: false,
  },
  status: {
    loading: false,
    error: false,
    message: "",
  },
  selectedRows: [],
};

const modalInitialValues = {
  open: false,
  values: {
    bonus_amount: "0",
    paid_with_bonus: "0",
    paid_with_cash: "0",
    paid_with_card: "0",
    discount: "0",
    comment: "",
    sorting_group: "",
    for_delivery: false,
    currency: "",
    currency_value: "",
    total_price: "0",
    total_price_usd: "",
  },
  validation: {
    error: {
      paid_with_bonus: false,
      paid_with_cash: false,
      paid_with_card: false,
      discount: false,
      comment: false,
    },
    message: {
      paid_with_bonus: "0",
      paid_with_cash: "0",
      paid_with_card: "0",
      discount: "0",
      comment: "",
    },
  },
  requested: false,
};

export default function Billing() {
  const navigate = useNavigate();
  const getPersonalCountries =
    AddressesService.GetPersonalCountries("customer");

  const [table, setTable] = useState<any>({
    ...tableInitialValues,
    columns: [
      { field: "id", headerName: "ID посылки", flex: 1, hide: true },
      {
        field: "user_id",
        headerName: "Код клиента",
        flex: 1,
        renderCell: (params: any) => params.row.customer?.id,
      },
      { field: "full_name", headerName: "ФИО", flex: 1 },
      {
        field: "customer_country",
        headerName: "Страна клиента",
        flex: 1,
        renderCell: (params: any) =>
          params.row.customer?.user?.country?.country,
      },
      { field: "orders_count", headerName: "Количество", flex: 1 },
      { field: "total_weight", headerName: "Вес", flex: 1 },
      {
        field: "for_delivery",
        headerName: "Доставка",
        flex: 1,
        renderCell: (params: any) => (params.row.for_delivery ? "Да" : "Нет"),
      },
      {
        field: "actions",
        headerName: `Действия`,
        width: 100,
        sortable: false,
        renderCell: (params: any) => (
          <IconButton
            title="выставить счет"
            onClick={() => {
              handleCalculatePayment(params.row.id, params.row.for_delivery);
            }}
          >
            <ReceiptLongIcon />
          </IconButton>
        ),
      },
    ],
  });
  const [modal, setModal] = useState(modalInitialValues);

  const tableList = BoxOfficeService.GetBillingList(table.filter);

  useEffect(() => {
    if (tableList.loading) {
      setTable((prevState: any) => ({
        ...prevState,
        status: {
          ...prevState.status,
          loading: true,
        },
      }));
    } else if (tableList.error) {
      setTable((prevState: any) => ({
        ...prevState,
        status: {
          ...prevState.status,
          loading: false,
          error: true,
        },
      }));
    } else {
      const data = tableList.result?.data;
      setTable((prevState: any) => ({
        ...prevState,
        rows: data.results,
        status: {
          ...prevState.status,
          loading: false,
          error: false,
        },
        filter: {
          ...prevState.filter,
          page: data.current_page,
          total_pages: data.total_pages,
        },
        selectedRows: [],
      }));
    }
  }, [tableList.loading, tableList.error, tableList.result?.data]);

  useEffect(() => {
    if (!getPersonalCountries.loading && !getPersonalCountries.error) {
      const data = getPersonalCountries.result?.data;
      if ([...data].pop()) {
        setTable({
          ...table,
          filter: {
            ...table.filter,
            delivery_country: [...data].pop().id,
          },
        });
      }
    }
  }, [
    getPersonalCountries.loading,
    getPersonalCountries.error,
    getPersonalCountries.result?.data,
  ]);

  const handleCalculatePayment = (sorting_group: any, for_delivery?: any) => {
    BoxOfficeService.GetBillingPaymentInfo({
      sorting_group: sorting_group,
    })
      .then((res) => {
        setModal({
          ...modal,
          open: true,
          values: {
            ...modal.values,
            ...res.data,
            sorting_group: sorting_group,
            for_delivery: for_delivery,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();

    setModal({
      ...modal,
      requested: true,
    });

    BoxOfficeService.BillingPaymentCreate(modal.values)
      .then((res) => {
        navigate(`/boxOffice/`);
      })
      .catch((err) => {
        checkModalResponse(err.response.data, setModal, modal);
      });
  };

  return (
    <>
      <div className="w-full flex items-center gap-[20px] mb-[50px]">
        <h1 className="componentMainWrapper__title">Выставление счета</h1>
        <Button
          disabled={!table.filter.for_delivery}
          variant="contained"
          onClick={() => {
            setTable({
              ...table,
              filter: {
                ...table.filter,
                for_delivery: false,
              },
            });
          }}
        >
          Посылки
        </Button>
        <Button
          disabled={table.filter.for_delivery}
          variant="contained"
          onClick={() => {
            setTable({
              ...table,
              filter: {
                ...table.filter,
                for_delivery: true,
              },
            });
          }}
        >
          Доставки
        </Button>
      </div>

      <div className="w-full flex justify-between items-center mb-[20px]">
        <div className="flex items-center gap-[20px]">
          <FormControl size="small">
            <InputLabel>Страна получателя</InputLabel>
            <Select
              value={table.filter.delivery_country}
              label="Страна получателя"
              onChange={(event) => {
                setTable({
                  ...table,
                  filter: {
                    ...table.filter,
                    delivery_country: event.target.value,
                  },
                });
              }}
            >
              {!getPersonalCountries.loading &&
                !getPersonalCountries.error &&
                getPersonalCountries.result?.data.map((item: any) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.country}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <div className="flex items-center gap-[20px]">
          <TextField
            label="Поиск клиента"
            value={table.filter.search}
            sx={{ width: "340px" }}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fill: "#48A5D1" }} />
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              setTable({
                ...table,
                filter: {
                  ...table.filter,
                  search: event.target.value,
                },
              });
            }}
          />
        </div>
      </div>

      <div className="w-full rounded-[10px] shadow-md">
        <DataGrid
          rows={table.rows}
          keepNonExistentRowsSelected
          columns={table.columns}
          disableColumnFilter
          filterMode="server"
          autoHeight
          loading={tableList.loading}
          localeText={{
            noRowsLabel: "Нет информации",
          }}
          disableSelectionOnClick
          components={{
            Footer: () => {
              return (
                <GridFooterContainer>
                  <Pagination
                    count={table.filter.total_pages}
                    page={table.filter.page}
                    onChange={(event, value: number) => {
                      setTable((prevState: any) => ({
                        ...prevState,
                        filter: {
                          ...prevState.filter,
                          page: value,
                        },
                      }));
                    }}
                  />
                </GridFooterContainer>
              );
            },
          }}
        />
      </div>

      <Modal
        open={modal.open}
        onClose={() => setModal(modalInitialValues)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form
          onSubmit={handleFormSubmit}
          className="mainModal relative flex flex-col justify-start items-center"
        >
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
            onClick={() => setModal(modalInitialValues)}
          >
            <CloseIcon />
          </IconButton>
          <h2 className="text-[30px] font-[600] text-[#1E1C2A] mb-[40px]">
            Выставить счет
          </h2>
          <div className="flex justify-center gap-[20px] text-[16px] font-semibold mb-[40px]">
            <div className="flex items-center gap-[10px]">
              <span>Сумма (сом):</span>
              <span>{modal.values.total_price}</span>
            </div>
            <div className="flex items-center gap-[10px]">
              <span>Бонусы (сом):</span>
              <span>{modal.values.bonus_amount}</span>
            </div>
          </div>
          <div className="w-full grid grid-cols-2 gap-[30px] mb-[50px]">
            <TextField
              label="Наличными"
              fullWidth
              type="number"
              value={modal.values.paid_with_cash}
              onChange={(event) => {
                setModal({
                  ...modal,
                  values: {
                    ...modal.values,
                    paid_with_cash: event.target.value,
                  },
                });
              }}
            />
            <TextField
              label="Безналичными"
              fullWidth
              type="number"
              value={modal.values.paid_with_card}
              onChange={(event) => {
                setModal({
                  ...modal,
                  values: {
                    ...modal.values,
                    paid_with_card: event.target.value,
                  },
                });
              }}
            />
            <TextField
              label="Бонусом"
              fullWidth
              type="number"
              value={modal.values.paid_with_bonus}
              onChange={(event) => {
                if (event.target.value <= modal.values.bonus_amount) {
                  setModal({
                    ...modal,
                    values: {
                      ...modal.values,
                      paid_with_bonus: event.target.value,
                    },
                    validation: modalInitialValues.validation,
                  });
                }
              }}
            />
            <TextField
              label="Скидка"
              fullWidth
              type="number"
              value={modal.values.discount}
              onChange={(event) => {
                setModal({
                  ...modal,
                  values: {
                    ...modal.values,
                    discount: event.target.value,
                  },
                });
              }}
            />
          </div>
          <div className="flex justify-between gap-[40px] items-center mt-[20px]">
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                setModal(modalInitialValues);
              }}
            >
              Отмена
            </Button>
            <LoadingButton
              type="submit"
              disabled={modal.requested}
              loading={modal.requested}
              variant="contained"
            >
              Готово
            </LoadingButton>
          </div>
        </form>
      </Modal>
    </>
  );
}
