import React, {useEffect, useState} from 'react';
import '../../styles/ransom.css';
import {DataGrid, GridFooterContainer} from "@mui/x-data-grid";
import {Button, IconButton, Pagination} from "@mui/material";
import {useNavigate} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import {RansomService} from "../../services/RansomService";

const tableInitialValues = {
    rows: [],
    status: {
        loading: false,
        error: false,
        message: "",
    },
    filter: {
        page: 1,
        size: 20,
        total_pages: 1,
    },
};

export default function Ransom() {
    const navigate = useNavigate();

    const [table, setTable] = useState<any>({
        ...tableInitialValues,
        columns: [
            {field: 'id', headerName: 'ID', flex: 1, hide: true},
            {
                field: 'customer_id', headerName: 'ID клиента', flex: 1, renderCell: (params: any) => (
                    params.row.customer?.id
                )
            },
            {field: 'full_name', headerName: 'ФИО', flex: 1},
            {
                field: 'store', headerName: 'Магазин', flex: 1, renderCell: (params: any) => (
                    params.row.order?.store?.name
                )
            },
            {field: 'total_product_price', headerName: 'Стоимость', flex: 1},
            {field: 'total_commission', headerName: 'Комиссия', flex: 1},
            {
                field: 'currency', headerName: 'Курс', flex: 1, renderCell: (params: any) => (
                    params.row.currency?.value
                )
            },
            {field: 'total_price', headerName: 'Итог. стоимость', flex: 1},
            {
                field: 'status', headerName: 'Статус', flex: 1, renderCell: (params: any) => (
                    <img src={params.row.order?.status?.icon} alt="icon"/>
                )
            },
            {
                field: 'edit', headerName: '', width: 80, renderCell: (params: any) => (
                    <IconButton color="secondary" onClick={() => {
                        navigate({
                            pathname: `/ransom/edit/${params.row.id}`,
                            // search: createSearchParams({
                            //     action: "edit",
                            //     ransom_id: params.row.id,
                            // }).toString(),
                        });
                    }}>
                        <EditIcon style={{color: "#B9B9B9"}}/>
                    </IconButton>
                )
            },
        ],
    });

    const tableList = RansomService.GetRansomList(table.filter)

    useEffect(() => {
        if (tableList.loading) {
            setTable((prevState: any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: true,
                },
            }));
        } else if (tableList.error) {
            setTable((prevState: any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: false,
                    error: true,
                },
            }));
        } else {
            const data = tableList.result?.data;
            setTable((prevState: any) => ({
                ...prevState,
                rows: data.results,
                status: {
                    ...prevState.status,
                    loading: false,
                    error: false,
                },
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
                selectedRows: []
            }));
        }
    }, [tableList.loading, tableList.error, tableList.result?.data]);
    return (
        <>
            <h1 className="text-[30px] font-[400] text-black my-[50px]">Выкуп</h1>

            <div className='w-full flex justify-end'>
                <Button
                    color='purple'
                    variant='contained'
                    onClick={() => {
                        // navigate({
                        //     pathname: "/ransom/details",
                        //     search: createSearchParams({
                        //         action: "add",
                        //     }).toString(),
                        // });
                        navigate({
                            pathname: `/ransom/add/`,
                            // search: createSearchParams({
                            //     action: "edit",
                            //     ransom_id: params.row.id,
                            // }).toString(),
                        });
                    }}>
                    Добавить
                </Button>
            </div>

            <div className='w-full mt-[20px] rounded-[10px] shadow-md'>
                <DataGrid
                    rows={table.rows}
                    columns={table.columns}
                    disableColumnFilter
                    filterMode='server'
                    autoHeight
                    loading={tableList.loading}
                    localeText={{
                        noRowsLabel: "Нет информации",
                      }}
                    disableSelectionOnClick
                    components={{
                        Footer: () => {
                            return (
                                <GridFooterContainer>
                                    <Pagination
                                        count={table.filter.total_pages}
                                        page={table.filter.page}
                                        onChange={(event, value: number) => {
                                            setTable({
                                                ...table,
                                                filter: {
                                                    ...table.filter,
                                                    page: value,
                                                },
                                            });
                                        }}
                                    />
                                </GridFooterContainer>
                            );
                        },
                    }}
                />
            </div>
        </>
    );
}
