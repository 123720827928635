import React, { useEffect, useState } from "react";
import "../../styles/personal.css";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import { Pagination } from "@mui/material";
import { useParams } from "react-router-dom";
import { OrderPickUpPointsService } from "../../services/OrderPickUpPointsService";
import { Button } from "@mui/material";

const tableInitialValues = {
  rows: [],
  status: {
    loading: false,
    error: false,
    message: "",
  },
  filter: {
    page: 1,
    size: 20,
    total_pages: 1,
  },
};

export default function OrderPickUpPointsView() {
  const { id } = useParams();

  const [table, setTable] = useState<any>({
    ...tableInitialValues,
    columns: [
      // { field: "id", headerName: "ID", flex: 1, hide: true },
      { field: "id", headerName: "Код клиента", flex: 1 },
      {
        field: "full_name",
        headerName: "ФИО",
        flex: 1,
        renderCell: (params: any) =>
          `${params.row.user.first_name} ${params.row.user.last_name}`,
      },
      { field: "phone", headerName: "Номер телефона", flex: 1 },
    ],
  });

  const tableList = OrderPickUpPointsService.GetClientsList(id, table.filter);

  console.log("tableList", tableList);

  //   const rows = tableList.result?.data?.results
  //     ? tableList.result.data.results.map((reception: any, index: number) => ({
  //         id: index + 1,
  //         code: reception.id,
  //         full_name: `${reception.user.last_name} ${reception.user.first_name}`,
  //         phone: reception.phone,
  //       }))
  //     : [];

  const handleExcelExport = async () => {
    try {
      const response: any =
        await OrderPickUpPointsService.PickUpPointsExcelExport({
          pick_up_point: id,
        });

      if (response && response.data?.file_url) {
        window.open(response.data?.file_url);
      } else {
        console.error("File URL not found in response");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (tableList.loading) {
      setTable((prevState: any) => ({
        ...prevState,
        status: {
          ...prevState.status,
          loading: true,
        },
      }));
    } else if (tableList.error) {
      setTable((prevState: any) => ({
        ...prevState,
        status: {
          ...prevState.status,
          loading: false,
          error: true,
        },
      }));
    } else {
      const data = tableList.result?.data;
      setTable((prevState: any) => ({
        ...prevState,
        rows: data.results,
        status: {
          ...prevState.status,
          loading: false,
          error: false,
        },
        filter: {
          ...prevState.filter,
          page: data.current_page,
          total_pages: data.total_pages,
        },
        selectedRows: [],
      }));
    }
  }, [tableList.loading, tableList.error, tableList.result?.data]);

  return (
    <>
      <h1 className="text-[30px] font-[400] text-black my-[50px]">Клиенты</h1>

      <div className="w-full mt-[20px] rounded-[10px] shadow-md">
        <DataGrid
          rows={table.rows}
          columns={table.columns}
          disableColumnFilter
          filterMode="server"
          autoHeight
          //   loading={table.loading}
          localeText={{
            noRowsLabel: "Нет информации",
          }}
          disableSelectionOnClick
          components={{
            Footer: () => (
              <div className="flex justify-between items-center px-[30px] pb-[20px]">
                <GridFooterContainer>
                  <Pagination
                    count={table.filter.total_pages}
                    page={table.filter.page}
                    onChange={async (event, value: number) => {
                      await setTable((prevTable: any) => ({
                        ...prevTable,
                        filter: {
                          ...prevTable.filter,
                          page: value,
                        },
                      }));
                      tableList.execute();
                    }}
                  />
                </GridFooterContainer>{" "}
                <Button
                  onClick={handleExcelExport}
                  color="blue"
                  variant="contained"
                >
                  СКАЧАТЬ EXCEL
                </Button>
              </div>
            ),
          }}
        />
      </div>
    </>
  );
}
