import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {DeliveryService} from "../../services/DeliveryService";
import {Button, CircularProgress, Pagination} from "@mui/material";
import moment from "moment/moment";
import {EmptyData, OrdersEmptyIcon} from "../../helpers/helpers";

const ordersInitialState = {
    data:[],
    filter:{
        page: 1,
        total_pages: 1,
    },
    selectedDeliveries: [],
}
const CourierDeliveryViewPage = () => {
    const {id} = useParams()
    const navigate = useNavigate()
    const [statusUpdateSent, setStatusUpdateSent] = useState(false)
    const deliveryInfo = DeliveryService.GetDelivery(id)
    const [filter, setFilter] = useState({
        page: 1,
        total_pages: 1,
    })
    const [orders, setOrders] = useState<any>(ordersInitialState)

    const ordersList = DeliveryService.GetDeliveryOrderList(filter, id)

    useEffect(()=>{
        if(!ordersList.loading && !ordersList.error){
            const data = ordersList.result?.data
            setOrders((prevState: any)=>({
                ...prevState,
                data: data.results,
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                }
            }))
        }
    },[ordersList.loading, ordersList.error, ordersList.result?.data])

    const handleStatusUpdate = () => {
        setStatusUpdateSent(true)
        DeliveryService.UpdateDeliveryStatus({delivery_id: id}).then(()=>{
            navigate('/')
        })
    }
    return (
        <div className='courierDelivery'>
            {deliveryInfo.loading
                ? <CircularProgress />
                : deliveryInfo.error
                    ? 'Error...'
                    : id !== undefined
                        ? <>
                            <div className="courierDelivery__title">
                                <p>Информация о доставке</p>
                                {deliveryInfo.result?.data.status.status === "atTheCourier" &&
                                    <Button
                                        variant='contained'
                                        size='small'
                                        disabled={statusUpdateSent}
                                        onClick={handleStatusUpdate}
                                    >
                                        ВЫДАТЬ
                                    </Button>
                                }
                            </div>
                            <div className="courierDelivery__body">
                                <div>
                                    <p>ID</p>
                                    <span>{deliveryInfo.result?.data.user.id}</span>
                                </div>
                                <div>
                                    <p>ФИО</p>
                                    <span>{deliveryInfo.result?.data.user.full_name}</span>
                                </div>
                                <div>
                                    <p>Телефон</p>
                                    <span>{deliveryInfo.result?.data.phone}</span>
                                </div>
                                <div>
                                    <p>Адрес</p>
                                    <span>{deliveryInfo.result?.data.address}</span>
                                </div>
                                <div>
                                    <p>Дата создания</p>
                                    <span>{moment(deliveryInfo.result?.data.created_at).format('DD.MM.YYYY').toString()}</span>
                                </div>
                                <div>
                                    <p>Статус</p>
                                    <span>{deliveryInfo.result?.data.status.name}</span>
                                </div>
                            </div>
                            <div className="courierDelivery__title">
                                <p>Посылки</p>
                            </div>
                            {ordersList.loading
                                ? <CircularProgress style={{marginTop: '20px', marginBottom: '20px'}}/>
                                : ordersList.error
                                    ? <div>Error</div>
                                    : orders.data.length === 0
                                        ? <EmptyData text={'Нет посылок'} icon={<OrdersEmptyIcon/>}/>
                                        :
                                        orders.data.map((order: any, index: number) => (
                                            <div className="MArrangeDelivery-orders__orderBox" key={index} id=''
                                                 data-id={order.id}
                                            >
                                                <div className="MArrangeDelivery-orderBox__textBox">
                                                    <p>{order.store.name}</p>
                                                    <div><span>{order.country.name}</span>
                                                        <span>{order.tracking_number}</span></div>
                                                </div>
                                            </div>
                                        ))
                            }
                            <Pagination
                                style={{marginTop: '20px'}}
                                count={filter.total_pages}
                                page={filter.page}
                                onChange={(event, value: number) => {
                                    setFilter({
                                        ...filter,
                                        page: value
                                    })
                                }}
                            />
                        </>
                        : 'no id'

            }
        </div>
    );
};

export default CourierDeliveryViewPage;