import React, { useState } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Avatar,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { setCookie } from "typescript-cookie";
import "../styles/auth.css";
import CircularProgress from "@mui/material/CircularProgress";
import jwtDecode from "jwt-decode";
import { AuthService } from "../services/AuthService";
import { access_token_name, refresh_token_name } from "../http/axiosInstance";
import { login } from "../store/slices/userSlice";
import { useDispatch } from "react-redux";

const formInitialState = {
  values: {
    email: "",
    password: "",
  },
  validation: {
    message: {
      email: "",
      password: "",
    },
    error: {
      email: false,
      password: false,
    },
  },
  showPassword: false,
  requested: false,
};
function Auth() {
  const dispatch = useDispatch();
  const [form, setForm] = useState(formInitialState);
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setForm({
      ...form,
      requested: true,
    });
    AuthService.PostAuthData(form.values)
      .then((res) => {
        const currentTimeInSeconds = Math.floor(Date.now() / 1000);

        const accessDecode: any = jwtDecode(res.data.access);
        const refreshDecode: any = jwtDecode(res.data.refresh);

        const accessExpirationInSeconds = accessDecode.exp;
        const refreshExpirationInSeconds = refreshDecode.exp;

        // Calculate the difference in seconds
        const accessDifferenceInSeconds =
          accessExpirationInSeconds - currentTimeInSeconds;
        const refreshDifferenceInSeconds =
          refreshExpirationInSeconds - currentTimeInSeconds;

        // Convert the difference in seconds to days
        const accessDifferenceInDays =
          accessDifferenceInSeconds / (60 * 60 * 24);
        const refreshDifferenceInDays =
          refreshDifferenceInSeconds / (60 * 60 * 24);

        setCookie(access_token_name, res.data.access, {
          expires: accessDifferenceInDays,
        });
        setCookie(refresh_token_name, res.data.refresh, {
          expires: refreshDifferenceInDays,
        });

        // write a user

        dispatch(
          login({
            authed: true,
            user: res.data.user,
          })
        );

        setTimeout(() => {
          window.location.reload();
        }, 100);
      })

      .catch((err) => {
        if (err.response.status === 401) {
          setForm({
            ...form,
            validation: {
              error: {
                email: true,
                password: true,
              },
              message: {
                email: err.response.data.detail,
                password: err.response.data.detail,
              },
            },
            requested: false,
          });
        }
      });
  };

  return (
    <div className="authPage" id="authPage">
      <div className="authModal" id="authModal">
        <div className="authModal__mainContainer">
          <form className="authModal__form" onSubmit={handleSubmit}>
            <Avatar className="Avatar">
              <LockOutlinedIcon />
            </Avatar>
            <p className="authModal-form__title">Авторизация</p>
            <TextField
              label="Email"
              variant="outlined"
              margin="normal"
              type="email"
              fullWidth={true}
              value={form.values.email}
              onChange={(event) => {
                setForm({
                  ...form,
                  values: {
                    ...form.values,
                    email: event.target.value,
                  },
                });
              }}
              error={form.validation.error.email}
              helperText={form.validation.message.email}
              required={true}
            />
            <TextField
              label="Пароль"
              variant="outlined"
              margin="normal"
              fullWidth={true}
              type={form.showPassword ? "text" : "password"}
              value={form.values.password}
              onChange={(event) => {
                setForm({
                  ...form,
                  values: {
                    ...form.values,
                    password: event.target.value,
                  },
                });
              }}
              error={form.validation.error.password}
              helperText={form.validation.message.password}
              required={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setForm({
                          ...form,
                          showPassword: !form.showPassword,
                        });
                      }}
                      onMouseDown={(event) => {
                        event.preventDefault();
                      }}
                      edge="end"
                    >
                      {form.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              sx={{
                background:
                  "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
                height: "56px",
                width: "100%",
                color: "#FFFFFF",
                cursor: "pointer",
                marginTop: "20px",
                textTransform: "uppercase",
              }}
              type="submit"
              disabled={form.requested}
            >
              {!form.requested ? (
                "войти"
              ) : (
                <CircularProgress sx={{ color: "white" }} />
              )}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Auth;
