import React, {useEffect, useState} from 'react';
import '../../styles/tariffEdit.css';
import {useLocation, useNavigate} from "react-router-dom";
import {Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, styled, TextField} from "@mui/material";
import {TariffService} from "../../services/TariffService";
import {AddressesService} from "../../services/AddressesService";
import {TransportsService} from "../../services/TransportsService";
import {checkModalResponse, convertImageUrlToFile} from "../../helpers/helpers";
import ReactQuill from "react-quill";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import 'react-quill/dist/quill.snow.css';


const tariffInitialState = {
    values: {
        country: '',
        delivery_country: '',
        type_of_transport: '',
        delivery_period: '',
        sms_link: '',
        tariff: '',
        prefix: '',
        note: '',
        work_time: '',
        weekend: '',
        important_note: '',
        prohibited_items_note: '',
        price_note: '',
        subtitle: '',
        title: '',
        filling_in: [],
        additional_fields: []
    },
    validation: {
        errors: {
            country: false,
            delivery_country: false,
            type_of_transport: false,
            delivery_period: false,
            sms_link: false,
            tariff: false,
            prefix: false,
            note: false,
            work_time: false,
            weekend: false,
            important_note: false,
            prohibited_items_note: false,
            price_note: false,
            subtitle: false,
            title: false,
        },
        messages: {
            country: '',
            delivery_country: '',
            type_of_transport: '',
            delivery_period: '',
            sms_link: '',
            tariff: '',
            prefix: '',
            note: '',
            work_time: '',
            weekend: '',
            important_note: '',
            prohibited_items_note: '',
            price_note: '',
            subtitle: '',
            title: '',
        }
    },
    requested: false,
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function TariffDetails() {
    const navigate = useNavigate()
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const action = params.get('action');
    const tariff = params.get('tariff');

    const [showEditors, setShowEditors] = useState(action === 'add')
    const [form, setForm] = useState<any>({
        ...tariffInitialState,
        action: action
    })

    const countries = AddressesService.GetCountries()
    const delivery_countries = AddressesService.GetPersonalCountries('customer')
    const type_of_transports = TransportsService.GetTransports()


    const handleSubmit = () => {
        const newData = form.values
        let form_data = new FormData()
        for (let key in newData) {
            if (Array.isArray(newData[key])) {
                for (let i = 0; i < newData[key].length; i++) {
                    for (let keyFilling in newData[key][i]) {
                        if (typeof newData[key][i][keyFilling] === 'object') {
                            for (let j = 0; j < newData[key][i][keyFilling].length; j++) {
                                form_data.append(`${key}[${i}]${keyFilling}[${j}]file`, newData[key][i][keyFilling][j]);
                            }
                        } else {
                            form_data.append(`${key}[${i}]${keyFilling}`, newData[key][i][keyFilling]);
                        }
                    }
                }
            } else {
                form_data.append(key, newData[key]);
            }
        }
        setForm({
            ...form,
            requested: true
        })

        switch (form.action) {
            case 'edit':
                TariffService.TariffUpdate(form_data, form.values.id).then((res) => {
                    navigate({
                        pathname: '/tariff'
                    })
                }).catch((err) => {
                    checkModalResponse(err.response.data, setForm, form)
                })
                break;

            case 'add':
                TariffService.TariffCreate(form_data).then((res) => {
                    navigate({
                        pathname: '/tariff'
                    })
                }).catch((err) => {
                    checkModalResponse(err.response.data, setForm, form)
                })
                break;

        }
    }

    useEffect(() => {
        if (action === 'edit' && tariff) {
            TariffService.GetTariffInfo(tariff).then(async (res: any) => {
                const fillingInFiles = await Promise.all(res.data.filling_in.map(async (item: any) => {
                    const convertedFiles = await Promise.all(item.files.map(async (file: any) => {
                        return await convertImageUrlToFile(file.file);
                    }));
                    return { ...item, files: convertedFiles };
                }));

                setForm({
                    ...form,
                    values: {
                        ...res.data,
                        delivery_country: res.data.delivery_country?.id,
                        country: res.data.country?.id,
                        type_of_transport: res.data.type_of_transport?.id,
                        filling_in: fillingInFiles,
                    }
                });
                setShowEditors(true);
            });
        }
    }, []);


    const handleDeleteFilling_inElem = (index: number) => {
        const updatedFilling_in = [...form.values.filling_in.slice(0, index), ...form.values.filling_in.slice(index + 1)];
        setForm({
            ...form,
            values: {
                ...form.values,
                filling_in: updatedFilling_in
            },
        });
    }
    const handleDeleteAdditionalFieldElem = (index: number) => {
        const updatedAdditional_fields = [...form.values.additional_fields.slice(0, index), ...form.values.additional_fields.slice(index + 1)];
        setForm({
            ...form,
            values: {
                ...form.values,
                additional_fields: updatedAdditional_fields
            },
        });
    }

    const modules = {
        toolbar: [
            [{align: []}, 'direction'],
            ['bold', 'italic', 'underline', 'strike'],
            [{color: []}, {background: []}],
            [{script: 'super'}, {script: 'sub'}],
            ['blockquote', 'code-block'],
            [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
            ['link', 'image', 'video'],
            ['clean']
        ],
    };
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]

    return (
        <>
            {action === 'edit' &&
                <p className="componentMainWrapper__title">Редактирование тарифа</p>
            }
            {action === 'add' &&
                <p className="componentMainWrapper__title">Добавления тарифа</p>
            }
            <span className="componentMainWrapper__subTitle">Товарищи! укрепление и развитие структуры в значительной степени обуславливает создание новых предложений. </span>
            <form onSubmit={(event) => {
                event.preventDefault();
                handleSubmit()
            }} className="w-full">
                <div
                    className='w-full grid grid-cols-3 gap-[30px] mb-[40px] bg-white p-[20px] pb-[40px] shadow-md rounded-[2px]'>
                    <FormControl required variant="outlined">
                        <InputLabel>Страна отправки</InputLabel>
                        <Select
                            label="Страна отправки"
                            value={form.values.country}
                            error={form.validation.errors.country}
                            onChange={(event: any) => {
                                setForm({
                                    ...form,
                                    values: {
                                        ...form.values,
                                        country: event.target.value,
                                    },
                                });
                            }}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {!countries.loading && !countries.error &&
                                countries.result?.data.map((country: any, index: number) => (
                                    <MenuItem key={index}
                                              value={country.id}>{country.name}</MenuItem>
                                ))
                            }
                        </Select>
                        <FormHelperText>{form.validation.messages.country}</FormHelperText>
                    </FormControl>
                    <FormControl required variant="outlined">
                        <InputLabel>Страна доставки</InputLabel>
                        <Select
                            label="Страна доставки"
                            value={form.values.delivery_country}
                            error={form.validation.errors.delivery_country}
                            onChange={(event: any) => {
                                setForm({
                                    ...form,
                                    values: {
                                        ...form.values,
                                        delivery_country: event.target.value,
                                    },
                                });
                            }}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {!delivery_countries.loading && !delivery_countries.error &&
                                delivery_countries.result?.data.map((delivery_country: any, index: number) => (
                                    <MenuItem key={index}
                                              value={delivery_country.id}>{delivery_country.country}</MenuItem>
                                ))
                            }
                        </Select>
                        <FormHelperText>{form.validation.messages.delivery_country}</FormHelperText>
                    </FormControl>
                    <FormControl required variant="outlined">
                        <InputLabel>Транспорт</InputLabel>
                        <Select
                            label="Транспорт"
                            value={form.values.type_of_transport}
                            error={form.validation.errors.type_of_transport}
                            onChange={(event: any) => {
                                setForm({
                                    ...form,
                                    values: {
                                        ...form.values,
                                        type_of_transport: event.target.value,
                                    },
                                });
                            }}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {!type_of_transports.loading && !type_of_transports.error &&
                                type_of_transports.result?.data.map((type_of_transport: any, index: number) => (
                                    <MenuItem key={index}
                                              value={type_of_transport.id}>{type_of_transport.name}</MenuItem>
                                ))
                            }
                        </Select>
                        <FormHelperText>{form.validation.messages.type_of_transport}</FormHelperText>
                    </FormControl>
                    <TextField
                        fullWidth
                        required
                        value={form.values.prefix}
                        error={form.validation.errors.prefix}
                        helperText={form.validation.messages.prefix}
                        label='Префикс'
                        onChange={(event) => {
                            setForm({
                                ...form,
                                values: {
                                    ...form.values,
                                    prefix: event.target.value
                                }
                            })
                        }}
                    />
                    <TextField
                        fullWidth
                        required
                        value={form.values.delivery_period}
                        error={form.validation.errors.delivery_period}
                        helperText={form.validation.messages.delivery_period}
                        label='Время доставки'
                        onChange={(event) => {
                            setForm({
                                ...form,
                                values: {
                                    ...form.values,
                                    delivery_period: event.target.value
                                }
                            })
                        }}
                    />
                    <TextField
                        fullWidth
                        required
                        value={form.values.tariff}
                        error={form.validation.errors.tariff}
                        helperText={form.validation.messages.tariff}
                        label='Тариф'
                        type={'number'}
                        onChange={(event) => {
                            setForm({
                                ...form,
                                values: {
                                    ...form.values,
                                    tariff: event.target.value
                                }
                            })
                        }}
                    />
                    <TextField
                        fullWidth
                        value={form.values.note}
                        error={form.validation.errors.note}
                        helperText={form.validation.messages.note}
                        label='Примечание'
                        onChange={(event) => {
                            setForm({
                                ...form,
                                values: {
                                    ...form.values,
                                    note: event.target.value
                                }
                            })
                        }}
                    />
                    <TextField
                        fullWidth
                        value={form.values.work_time}
                        error={form.validation.errors.work_time}
                        helperText={form.validation.messages.work_time}
                        label='Время работы склада'
                        onChange={(event) => {
                            setForm({
                                ...form,
                                values: {
                                    ...form.values,
                                    work_time: event.target.value
                                }
                            })
                        }}
                    />
                    <TextField
                        fullWidth
                        value={form.values.weekend}
                        error={form.validation.errors.weekend}
                        helperText={form.validation.messages.weekend}
                        label='Выходные дни'
                        onChange={(event) => {
                            setForm({
                                ...form,
                                values: {
                                    ...form.values,
                                    weekend: event.target.value
                                }
                            })
                        }}
                    />
                    <TextField
                        fullWidth
                        value={form.values.sms_link}
                        error={form.validation.errors.sms_link}
                        helperText={form.validation.messages.sms_link}
                        label='Смс шлюз'
                        onChange={(event) => {
                            setForm({
                                ...form,
                                values: {
                                    ...form.values,
                                    sms_link: event.target.value
                                }
                            })
                        }}
                    />
                    {showEditors &&
                        <>
                            <div className="newDataAdd__textFieldBox mb-[30px] col-start-1 col-end-4">
                                <p>Важно:</p>
                                <ReactQuill
                                    theme="snow"
                                    style={{width: '100%'}}
                                    value={form.values.important_note}
                                    onChange={(value) => {
                                        if (form.values.important_note !== value) {
                                            setForm({
                                                ...form,
                                                values: {
                                                    ...form.values,
                                                    important_note: value
                                                }
                                            });
                                        }
                                    }}
                                    modules={modules}
                                    formats={formats}
                                />
                            </div>
                            <div className="newDataAdd__textFieldBox mb-[30px] col-start-1 col-end-4">
                                <p>Запрещенные вещи:</p>
                                <ReactQuill
                                    theme="snow"
                                    style={{width: '100%'}}
                                    value={form.values.prohibited_items_note}
                                    onChange={(value) => {
                                        if (form.values.prohibited_items_note !== value) {
                                            setForm({
                                                ...form,
                                                values: {
                                                    ...form.values,
                                                    prohibited_items_note: value
                                                }
                                            });
                                        }
                                    }}
                                    modules={modules}
                                    formats={formats}
                                />
                            </div>
                            <div className="newDataAdd__textFieldBox mb-[30px] col-start-1 col-end-4">
                                <p>Прайс:</p>
                                <ReactQuill
                                    theme="snow"
                                    style={{width: '100%'}}
                                    value={form.values.price_note}
                                    onChange={(value) => {
                                        if (form.values.price_note !== value) {
                                            setForm({
                                                ...form,
                                                values: {
                                                    ...form.values,
                                                    price_note: value
                                                }
                                            });
                                        }
                                    }}
                                    modules={modules}
                                    formats={formats}
                                />
                            </div>
                        </>

                    }
                </div>

                <div
                    className='w-full mb-[40px] bg-white p-[20px] pb-[40px] shadow-md rounded-[2px] flex flex-col justify-start items-start'>
                    <div className='w-full flex justify-between items-start mb-[50px]'>
                        <p className='text-[20px] font-[500] text-[#141414]'>
                            Ключевые слова
                        </p>
                    </div>

                    <div className='w-full flex flex-col items-start justify-start gap-[20px]'>
                        <h5>Следующие ключевые слова будут заменены на соответствующие значения у клиентов в адресах. Вы
                            можете прописать эти ключевые фразы в любом месте текста в специально обозначенных полях или
                            групп полей.</h5>
                        <div>
                            <p>{`{client_code}`} - клиентский код</p>
                            <p>{`{client_phone}`} - клиентский номер телефона</p>
                            <p>{`{client_full_name}`} - ФИО клиента</p>
                            <p>{`{client_first_name}`} - Имя клиента</p>
                            <p>{`{client_last_name}`} - Фамилия клиента</p>
                        </div>
                    </div>

                </div>

                <div
                    className='w-full mb-[40px] bg-white p-[20px] pb-[40px] shadow-md rounded-[2px] flex flex-col justify-start items-start'>
                    <div className='w-full flex justify-between items-start mb-[50px]'>
                        <p className='text-[20px] font-[500] text-[#141414]'>
                            Заголовки
                        </p>
                    </div>
                    <h5 className='mb-[20px]'>* Можно использовать ключевые слова</h5>


                    <div className='w-full flex flex-col items-start justify-start gap-[20px]'>
                        <div className='w-full flex justify-between items-start gap-[30px]'>
                            <TextField
                                fullWidth
                                required
                                value={form.values.title}
                                label='Заголовок'
                                onChange={(event) => {
                                    setForm({
                                        ...form,
                                        values: {
                                            ...form.values,
                                            title: event.target.value
                                        }
                                    })
                                }}
                            />
                            <TextField
                                fullWidth
                                required
                                multiline
                                minRows={3}
                                value={form.values.subtitle}
                                label='Подзаголовок'
                                onChange={(event) => {
                                    setForm({
                                        ...form,
                                        values: {
                                            ...form.values,
                                            subtitle: event.target.value
                                        }
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div
                    className='w-full mb-[40px] bg-white p-[20px] pb-[40px] shadow-md rounded-[2px] flex flex-col justify-start items-start'>
                    <div className='w-full flex justify-between items-start mb-[50px]'>
                        <p className='text-[20px] font-[500] text-[#141414]'>
                            Дополнительные поля
                        </p>
                        <Button variant='contained'
                                onClick={() => {
                                    setForm({
                                        ...form,
                                        values: {
                                            ...form.values,
                                            additional_fields: [
                                                ...form.values.additional_fields,
                                                {
                                                    id: '',
                                                    name: '',
                                                    value: '',
                                                }
                                            ]
                                        }
                                    })
                                }}
                        >
                            Добавить поле
                        </Button>
                    </div>
                    <h5 className='mb-[20px]'>* Можно использовать ключевые слова</h5>

                    <div className='w-full flex flex-col items-start justify-start gap-[20px]'>
                        {form.values.additional_fields.map((item: any, index: number) => (
                            <div key={index} className='w-full flex justify-between items-center gap-[30px]'>
                                <TextField
                                    fullWidth
                                    value={item.name}
                                    label='Наименование'
                                    onChange={(event) => {
                                        const additional_fields: any = form.values.additional_fields
                                        additional_fields[index].name = event.target.value
                                        setForm({
                                            ...form,
                                            values: {
                                                ...form.values,
                                                additional_fields: additional_fields
                                            }
                                        })
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    required
                                    value={item.value}
                                    label='Значение'
                                    onChange={(event) => {
                                        const additional_fields: any = form.values.additional_fields
                                        additional_fields[index].value = event.target.value
                                        setForm({
                                            ...form,
                                            values: {
                                                ...form.values,
                                                additional_fields: additional_fields
                                            }
                                        })
                                    }}
                                />
                                <Button variant='contained' color='error'
                                        sx={{borderRadius: '50%', padding: '10px', minWidth: 'unset'}}
                                        onClick={() => handleDeleteAdditionalFieldElem(index)}
                                >
                                    <DeleteIcon/>
                                </Button>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='w-full mb-[40px] bg-white p-[20px] pb-[40px] shadow-md rounded-[2px] flex flex-col justify-start items-start'>
                    <div className='w-full flex justify-between items-start mb-[50px]'>
                        <p className='text-[20px] font-[500] text-[#141414]'>
                            Примеры заполнения
                        </p>
                        <Button variant='contained'
                                onClick={() => {
                                    setForm({
                                        ...form,
                                        values: {
                                            ...form.values,
                                            filling_in: [
                                                ...form.values.filling_in,
                                                {
                                                    id: '',
                                                    name: '',
                                                    description: '',
                                                    files: [],
                                                }
                                            ]
                                        }
                                    })
                                }}
                        >
                            Добавить этап
                        </Button>
                    </div>

                    <div className='w-full flex flex-col items-start justify-start gap-[20px]'>
                        {form.values.filling_in.map((item: any, index: number) => (
                            <div key={index} className='w-full flex justify-between items-center gap-[30px]'>
                                <TextField
                                    fullWidth
                                    value={item.name}
                                    label='Наименование'
                                    onChange={(event) => {
                                        const filling_in: any = form.values.filling_in
                                        filling_in[index].name = event.target.value
                                        setForm({
                                            ...form,
                                            values: {
                                                ...form.values,
                                                filling_in: filling_in
                                            }
                                        })
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    value={item.description}
                                    multiline
                                    minRows={3}
                                    label='Описание'
                                    onChange={(event) => {
                                        const filling_in: any = form.values.filling_in
                                        filling_in[index].description = event.target.value
                                        setForm({
                                            ...form,
                                            values: {
                                                ...form.values,
                                                filling_in: filling_in
                                            }
                                        })
                                    }}
                                />

                                <Button component="label" variant='contained' color='primary'
                                        sx={{borderRadius: '50%', padding: '10px', minWidth: 'unset'}}
                                >
                                    <AttachFileIcon/>
                                    <VisuallyHiddenInput multiple={true} type="file" onChange={(e) => {
                                        const filling_in: any = form.values.filling_in
                                        filling_in[index].files = e.target.files
                                        setForm({
                                            ...form,
                                            values: {
                                                ...form.values,
                                                filling_in: filling_in
                                            }
                                        })
                                    }}/>
                                </Button>
                                <Button variant='contained' color='error'
                                        sx={{borderRadius: '50%', padding: '10px', minWidth: 'unset'}}
                                        onClick={() => handleDeleteFilling_inElem(index)}
                                >
                                    <DeleteIcon/>
                                </Button>
                            </div>
                        ))}
                    </div>
                </div>

                <div className='w-full flex items-center justify-center gap-[40px] mb-[40px]'>
                    <Button
                        variant="contained"
                        sx={{
                            background: "rgba(72,165,209,0.7)",
                            height: "36px",
                            textTransform: "uppercase",
                        }}
                        onClick={() => {
                            navigate('/tariff')
                        }}
                    >
                        НАЗАД
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            background: "rgba(72,165,209,0.7)",
                            height: "36px",
                            textTransform: "uppercase",
                        }}
                        type='submit'
                        disabled={form.requested}
                    >
                        {action === 'edit' && 'СОХРАНИТЬ'}
                        {action === 'add' && 'Добавить'}
                    </Button>
                </div>
            </form>
        </>
    );
}

export default TariffDetails;

