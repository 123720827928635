import React, {useEffect, useState} from 'react'
import '../../styles/orderView.css'
import {useNavigate, useParams} from "react-router-dom";
import {DataGrid, GridColDef, GridFooterContainer} from "@mui/x-data-grid";
import {Button} from "@mui/material";
import moment from "moment/moment";
import {OrdersService} from "../../services/OrdersService";

export default function OrderView() {
    const navigate = useNavigate()
    const {id} = useParams()
    const getOrderInfo = OrdersService.GetOrderInfo(id)
    const [orderInfo, setOrderInfo] = useState<any>({
        receiver_name: '',
        receiver_id: '',
        receiver_phone: '',
        receiver_address: [],
        tracking_number: '',
        store: '',
        country: '',
        status: '',
        status_history: [],
    })
    const mainTableColumns: GridColDef[] = [
        {field: 'id', headerName: 'No', width: 80, hide: true},
        {field: 'product_name', headerName: 'Наименование товара', width: 610},
        {field: 'count', headerName: 'Кол-во', width: 100},
        {field: 'price', headerName: 'Цена', width: 135},
    ];
    const [mainTableData, setMainTableData] = useState<any>([])
    useEffect(() => {
        if (!getOrderInfo.loading && !getOrderInfo.error) {
            console.log(getOrderInfo)
            setOrderInfo({
                receiver_name: getOrderInfo.result?.data.user !== null ? getOrderInfo.result?.data.user.full_name : getOrderInfo.result?.data.attribute,
                receiver_id: getOrderInfo.result?.data.user !== null ? ` ID ${getOrderInfo.result?.data.user.id}` : '',
                receiver_phone: getOrderInfo.result?.data.user !== null ? getOrderInfo.result?.data.user.phone : '',
                receiver_address: getOrderInfo.result?.data.user !== null ? getOrderInfo.result?.data.user.addresses.map((address: any) => address.address) : [],
                tracking_number: getOrderInfo.result?.data.tracking_number,
                store: getOrderInfo.result?.data.store?.name,
                country: getOrderInfo.result?.data.country.name,
                status: getOrderInfo.result?.data.status.name,
                status_history: getOrderInfo.result?.data.order_status_history
            })
            setMainTableData(getOrderInfo.result?.data.products.map((product: any, index: number) => ({
                id: index + 1,
                product_name: product.description,
                count: product.quantity,
                price: product.price,
            })))
        }
    }, [getOrderInfo.loading])
    return (
        <>
            <h1 className='componentMainWrapper__title'>Данные посылки</h1>
            <div className="orderViewPage__mainContainer">
                <div className="orderViewPage__orderInfoBox">
                    <div className="orderViewPage__orderInfoItem">
                        <h1>Получатель: </h1>
                        <div>
                            <p>{orderInfo.receiver_name} {orderInfo.receiver_id}</p>
                        </div>
                    </div>
                    <div className="orderViewPage__orderInfoItem">
                        <h1>Номер получателя: </h1>
                        <div>
                            <p>{orderInfo.receiver_phone}</p>
                        </div>
                    </div>
                    <div className="orderViewPage__orderInfoItem">
                        <h1>Адрес получателя: </h1>
                        <div>
                            {orderInfo.receiver_address.map((address_name: string, index: number) => (
                                <p key={index}>{address_name}</p>
                            ))}
                        </div>
                    </div>
                    <div className="orderViewPage__orderInfoItem">
                        <h1>Трек: </h1>
                        <div>
                            <p>{orderInfo.tracking_number}</p>
                        </div>
                    </div>
                    <div className="orderViewPage__orderInfoItem">
                        <h1>Магазин:</h1>
                        <div>
                            <p>{orderInfo.store}</p>
                        </div>
                    </div>
                    <div className="orderViewPage__orderInfoItem">
                        <h1>Страна: </h1>
                        <div>
                            <p>{orderInfo.country}</p>
                        </div>
                    </div>
                    <div className="orderViewPage__orderInfoItem">
                        <h1>Статус: </h1>
                        <div>
                            <p>{orderInfo.status}</p>
                        </div>
                    </div>
                    <div className="orderViewPage__orderInfoItem">
                        <h1>История смены статусов: </h1>
                        <div>
                            {orderInfo.status_history.map((status: any, i: number) => (
                                <p>{status.status.name}: {(moment(status.created_at)).format('DD.MM.YYYY HH:mm').toString()}</p>
                            ))}
                        </div>
                    </div>
                    <Button
                        variant="contained"
                        sx={{
                            background: "rgba(72,165,209,0.7)",
                            height: "36px",
                            width: "100%",
                            textTransform: "uppercase",
                        }}
                        onClick={() => {
                            navigate(-1)
                        }}
                    >Назад</Button>
                </div>
                <div className="orderViewPage__orderProductsBox">
                    <DataGrid
                        rows={mainTableData}
                        columns={mainTableColumns}
                        disableColumnFilter
                        filterMode='server'
                        autoHeight
                        localeText={{
                            noRowsLabel: "Нет информации",
                          }}
                        loading={getOrderInfo.loading}
                        disableSelectionOnClick
                        components={{
                            Footer: () => {
                                return (
                                    <GridFooterContainer>
                                    </GridFooterContainer>
                                );
                            },
                        }}
                    />
                </div>
            </div>
        </>
    )
}