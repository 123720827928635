import {useAsync} from "react-async-hook";
import {$axios} from "../http/axiosInstance";

export const AddressesService = {
    GetCountries() {
        return useAsync(async () => {
            return await $axios.get('/countries/');
        }, [])
    },

    GetPersonalCountries(user_type: string) {
        return useAsync(async () => {
            return await $axios.get(`/accounts/countries/?user_type=${user_type}`);
        }, [])
    }
}