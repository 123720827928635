import {useAsync} from "react-async-hook";
import {$axios} from "../http/axiosInstance";

export const TariffService = {
    GetTariffList() {
        return useAsync(async () => {
            return await $axios.get('/admin/tariff/list/');
        }, [])
    },
    async TariffUpdate(tariff: any, tariff_id: string) {
        return await $axios.put(`/admin/country-additional-info/update/${tariff_id}/`, tariff)
    },
    async TariffCreate(tariff: any) {
        return await $axios.post(`/admin/country-additional-info/create/`, tariff)
    },
    async GetTariffInfo(id: any) {
        return await $axios.get(`/admin/country-additional-info/${id}/`)
    },
    async TariffDelete(tariff: any) {
        return await $axios.delete(`/admin/country-additional-info/${tariff.id}`)
    },
}