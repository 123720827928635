import React, { useEffect, useState } from "react";
import "../../styles/layout.css";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { StoresService } from "../../services/StoresService";
import { TransportsService } from "../../services/TransportsService";
import { AddressesService } from "../../services/AddressesService";
import { WarehouseService } from "../../services/WarehouseService";
import { CustomerService } from "../../services/CustomerService";
import { checkModalResponse } from "../../helpers/helpers";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";

export default function WarehouseDetails() {
  const user = useSelector((state: any) => state.userData.user);

  const formInitialValues = {
    open: false,
    values: {
      id: "",
      tracking_number: "",
      user: "",
      full_name: "",
      store: "",
      store_name: "",
      type_of_transport: "",
      country: "",
      weight: "",
      comment: "",
      leave_shoe_boxes: false,
      leave_gift_boxes: false,
    },
    validation: {
      error: {
        tracking_number: false,
        user: false,
        full_name: false,
        store: false,
        type_of_transport: false,
        country: false,
        weight: false,
        comment: false,
        leave_shoe_boxes: false,
        leave_gift_boxes: false,
      },
      message: {
        tracking_number: "",
        user: "",
        full_name: "",
        store: "",
        type_of_transport: "",
        country: "",
        weight: "",
        comment: "",
        leave_shoe_boxes: "",
        leave_gift_boxes: "",
      },
    },
    requested: false,
  };

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const action = params.get("action");
  const tracking_number = params.get("tracking_number");

  const [form, setForm] = useState<any>({
    ...formInitialValues,
    action: action,
  });

  const storesList = StoresService.GetStores();
  const countriesList = AddressesService.GetCountries();

  const transportsList = TransportsService.GetTransports(form.values.country);

  const customersList = CustomerService.GetCustomersListBySearch({
    search: form.values.full_name,
  });

  const customersListId = CustomerService.GetCustomersListBySearch({
    search: form.values.user,
  });

  useEffect(() => {
    WarehouseService.SearchOrder({ search: form.values.tracking_number }).then(
      (res) => {
        const data = res.data.pop();
        if (data) {
          setForm((prevState: any) => ({
            ...formInitialValues,
            values: {
              ...formInitialValues.values,
              tracking_number: prevState.values.tracking_number,
              user: data.user?.id,
              full_name:
                data.user === null ? data.attribute : data.user.full_name,
              store: data.store === null ? "" : data.store.id,
              store_name: data.store === null ? "" : data.store.name,
              type_of_transport: data.type_of_transport.id,
              // country: data.country?.id,
              country:
                user.group !== "Администратор" && user.group !== "Супер-админ"
                  ? form.values.country ||
                    (user.country &&
                      countriesList.result?.data.find(
                        (country: any) => country.code === user.country.code
                      )?.id)
                  : data.country?.id,
              weight: data.weight ? data.weight : "",
              comment: data.comment ? data.weight : "",
              leave_gift_boxes: data.leave_gift_boxes,
              leave_shoe_boxes: data.leave_shoe_boxes,
            },
          }));
        } else {
          setForm((prevState: any) => ({
            ...prevState,
            values: {
              ...prevState.values,
              user: "",
              full_name: "",
              store: "",
              store_name: "",
              type_of_transport: "",
              weight: "",
              // country:
              //   form.values.country ||
              //   (user.country && user.country.code === "TR"
              //     ? countriesList.result?.data.find(
              //         (country: any) => country.code === "TR"
              //       )?.id
              //     : ""),
              country:
                form.values.country ||
                (user.country &&
                  countriesList.result?.data.find(
                    (country: any) => country.code === user.country.code
                  )?.id),
              comment: "",
              leave_gift_boxes: false,
              leave_shoe_boxes: false,
            },
          }));
        }
      }
    );
  }, [form.values.tracking_number]);

  const handleFormSubmit = (action: string) => (event: React.FormEvent) => {
    event.preventDefault();
    setForm({
      ...form,
      requested: true,
    });
    const values = { ...form.values };

    if (user.country.code === "USA") {
      values.type_of_transport = 1;
    }

    switch (action) {
      case "add":
        WarehouseService.AddOrder(values)
          .then((res) => {
            if (`${form.values.country}` === "5") {
              window.open(res.data.sticker_link, "_blank");
            }

            setForm(formInitialValues);
          })
          .catch((err) => {
            checkModalResponse(err.response.data, setForm, form);
          });
        break;

      case "edit":
        WarehouseService.EditOrder(values)
          .then((res) => {
            if (`${form.values.country}` === "5") {
              window.open(res.data.sticker_link, "_blank");
            }
            setForm(formInitialValues);
            navigate({
              pathname: "/warehouse",
            });
          })
          .catch((err) => {
            checkModalResponse(err.response.data, setForm, form);
          });
        break;
    }
  };

  useEffect(() => {
    if (action === "edit" && tracking_number) {
      setForm({
        ...form,
        values: {
          ...form.values,
          tracking_number: tracking_number,
        },
      });
    }
  }, [action, tracking_number]);

  useEffect(() => {
    if (user.country && user.country.code === "USA") {
      setForm({
        ...form,
        values: {
          ...form.values,
          type_of_transport: 1,
        },
      });
    }
  }, [user.country]);

  return (
    <>
      <p className="componentMainWrapper__title mb-[40px]">Склад</p>

      <div className="newDataAdd">
        <div className="newDataAdd__title">
          {action === "edit" && "Редактирование посылки"}
          {action === "add" && "Добавление посылки"}
        </div>
        <form
          className="newDataAdd__form"
          onSubmit={(e) => action && handleFormSubmit(action)(e)}
        >
          <TextField
            label="Трек код / Track code / 跟踪代码:"
            value={form.values.tracking_number}
            placeholder="Трек код"
            fullWidth
            helperText={form.validation.message.tracking_number}
            error={form.validation.error.tracking_number}
            onChange={(event) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  tracking_number: event.target.value,
                },
              });
            }}
          />

          {/* <TextField
            label="ID Пользователя / User ID / 用户身份:"
            value={form.values.user}
            helperText={form.validation.message.user}
            error={form.validation.error.user}
            fullWidth
            // disabled
          /> */}

          <Autocomplete
            fullWidth
            freeSolo
            isOptionEqualToValue={(option: any, value) =>
              option.id === value.id
            }
            getOptionLabel={(option: any) =>
              option.id ? String(option.id) : ""
            }
            options={
              !customersListId.loading && !customersListId.error
                ? customersListId.result?.data
                : []
            }
            loading={customersListId.loading}
            onChange={(event: any, newValue: any) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  user: newValue !== null ? String(newValue.id) : "",
                  full_name: newValue !== null ? newValue.full_name : "",
                },
              });
            }}
            inputValue={String(form.values.user)}
            onInputChange={(event, newInputValue) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  user: newInputValue !== null ? String(newInputValue) : "",
                },
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={form.validation.message.user}
                error={form.validation.error.user}
                label="ID Пользователя / User ID / 用户身份:"
              />
            )}
          />

          <Autocomplete
            fullWidth
            freeSolo
            isOptionEqualToValue={(option: any, value) =>
              option.full_name === value.full_name
            }
            getOptionLabel={(option: any) =>
              option.full_name ? String(option.full_name) : ""
            }
            options={
              !customersList.loading && !customersList.error
                ? customersList.result?.data
                : []
            }
            loading={customersList.loading}
            onChange={(event: any, newValue: any) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  user: newValue !== null ? newValue.id : "",
                  full_name: newValue !== null ? newValue.full_name : "",
                },
              });
            }}
            inputValue={form.values.full_name}
            onInputChange={(event, newInputValue) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  full_name: newInputValue !== null ? newInputValue : "",
                  user: "",
                },
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={form.validation.message.full_name}
                error={form.validation.error.full_name}
                label="ФИО Пользователя / Username / 用户的姓氏名字父名:"
              />
            )}
          />

          <Autocomplete
            fullWidth
            freeSolo
            isOptionEqualToValue={(option: any, value) =>
              option.name === value.name
            }
            getOptionLabel={(option: any) => option.name}
            options={
              !storesList.loading && !storesList.error
                ? storesList.result?.data
                : []
            }
            loading={storesList.loading}
            onChange={(event: any, newValue: any) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  store: newValue !== null ? newValue.id : "",
                  store_name: newValue !== null ? newValue.name : "",
                },
              });
            }}
            inputValue={form.values.store_name}
            onInputChange={(event, newInputValue) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  store_name: newInputValue !== null ? newInputValue : "",
                  store: "",
                },
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Магазин / Store / 店铺:"
                helperText={form.validation.message.store}
                error={form.validation.error.store}
              />
            )}
          />

          <FormControl fullWidth>
            <InputLabel>Страна / Country / 国家:</InputLabel>
            <Select
              label="Страна / Country / 国家:"
              // value={
              //   form.values.country ||
              //   (user.country &&
              //     countriesList.result?.data.find(
              //       (country: any) => country.code === user.country.code
              //     )?.id) ||
              //   ""
              // }
              value={
                user.group === "Складовщик"
                  ? countriesList.result?.data.find(
                      (country: any) => country.code === user.country.code
                    )?.id || ""
                  : form.values.country ||
                    (user.country &&
                      countriesList.result?.data.find(
                        (country: any) => country.code === user.country.code
                      )?.id) ||
                    ""
              }
              onChange={(event: any) => {
                const selectedCountryId = event.target.value;
                setForm({
                  ...form,
                  values: {
                    ...form.values,
                    country: selectedCountryId,
                  },
                });
              }}
              required
              disabled={user.group === "Складовщик"}
              // disabled={
              //   user.group !== "Администратор" && user.group !== "Супер-админ"
              // }

              error={form.validation.error.country}
            >
              {countriesList.loading ? (
                <div>loading</div>
              ) : countriesList.error ? (
                <div>Error</div>
              ) : (
                countriesList.result?.data.map((item: any) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.code === "TR" ? "Turkey" : item.name}
                  </MenuItem>
                ))
              )}
            </Select>
            <FormHelperText>{form.validation.message.country}</FormHelperText>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Транспорт / Transport / 运输:</InputLabel>
            <Select
              label="Транспорт / Transport / 运输:"
              // disabled={user.group === "Складовщик"}
              disabled={user.country.code === "USA"}
              value={
                user.country.code === "USA" ? 1 : form.values.type_of_transport
              }
              onChange={(event) => {
                setForm({
                  ...form,
                  values: {
                    ...form.values,
                    type_of_transport: event.target.value,
                  },
                });
              }}
              required
              error={form.validation.error.type_of_transport}
            >
              {transportsList.loading ? (
                <div>loading</div>
              ) : transportsList.error ? (
                <div>Error</div>
              ) : (
                transportsList.result?.data.map((item: any) => (
                  <MenuItem key={item.id} value={item.id}>
                    <img src={item.icon} alt="icon" />
                  </MenuItem>
                ))
              )}
            </Select>
            <FormHelperText>
              {form.validation.message.type_of_transport}
            </FormHelperText>
          </FormControl>

          <TextField
            value={form.values.weight}
            label="Фактический вес / Actual weight / 实际重量:"
            fullWidth
            required
            type="number"
            helperText={form.validation.message.weight}
            error={form.validation.error.weight}
            onChange={(event) => {
              if (event.target.value >= "0" || event.target.value === "") {
                setForm({
                  ...form,
                  values: {
                    ...form.values,
                    weight: event.target.value,
                  },
                });
              }
            }}
          />
          <TextField
            value={form.values.comment}
            label="Комментарий / Comment / 评论:"
            fullWidth
            helperText={form.validation.message.comment}
            error={form.validation.error.comment}
            onChange={(event) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  comment: event.target.value,
                },
              });
            }}
          />

          {!countriesList.loading &&
            !countriesList.error &&
            countriesList.result?.data.find(
              (country: any) => form.values.country === country.id
            ) &&
            countriesList.result?.data.find(
              (country: any) => form.values.country === country.id
            ).code === "USA" && (
              <Stack
                direction="row"
                spacing="20px"
                width="100%"
                justifyContent="center"
                marginBottom="20px"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={true}
                      checked={form.values.leave_shoe_boxes}
                    />
                  }
                  label={"Leave shoe boxes"}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={true}
                      checked={form.values.leave_gift_boxes}
                    />
                  }
                  label={"Leave gift boxes (bulk weight)"}
                />
              </Stack>
            )}
          <Stack direction="row" spacing="20px">
            <Button
              variant="outlined"
              onClick={() => {
                navigate({
                  pathname: "/warehouse",
                });
              }}
            >
              Отменить
            </Button>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={form.requested}
              disabled={form.requested}
            >
              Сохранить
            </LoadingButton>
          </Stack>
        </form>
      </div>
    </>
  );
}
