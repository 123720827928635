import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
} from "@mui/material";
import "../../../styles/orders.css";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import { JustNumberForMath } from "../../../helpers/helpers";
import { BoxOfficeService } from "../../../services/BoxOfficeService";

const paymentInitialState = {
  modal: false,
  currency: {
    currency_value: "0",
    currency: "",
  },
  info: {
    delivery_id: "",
    total_price: "0",
    balance_amount: "0",
    bonus_amount: "0",
    paid_with_bonus: "0",
    paid_with_balance: "0",
    paid_with_cash: "0",
    paid_with_card: "0",
    discount: "0",
    comment: "",
    orders: [],
  },
  check: {
    paid: true,
    bonusCheck: false,
    balanceCheck: false,
    cashCheck: false,
    cardCheck: false,
    bonusText: "",
    balanceText: "",
    cashText: "",
    cardText: "",
  },
};

export default function BillingView() {
  const navigate = useNavigate();
  const { id } = useParams();
  const billingInfo = BoxOfficeService.GetBillingChequeInfo(id);
  const mainTableColumns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "tracking_number", headerName: "Трек номер", width: 250 },
    { field: "store", headerName: "Магазин", width: 150 },
    { field: "country", headerName: "Страна", width: 150 },
    { field: "rounded_weight", headerName: "Окр. вес", width: 150 },
    { field: "price", headerName: "Цена", width: 200 },
  ];
  const [mainTableData, setMainTableData] = useState<any>([]);

  const [forDelivery, setForDelivery] = useState(false);

  const [payment, setPayment] = useState<any>(paymentInitialState);

  useEffect(() => {
    if (!billingInfo.loading && !billingInfo.error) {
      const data = billingInfo.result?.data;
      setPayment((prevState: any) => ({
        ...prevState,
        info: {
          ...prevState.info,
          total_price: data.total_price_of_orders.total_national_currency,
          balance_amount: data.user_cash_info.balance_amount,
          bonus_amount: data.user_cash_info.bonus_amount,
        },
        currency: {
          ...prevState.currency,
          currency: data.total_price_of_orders.currency,
        },
      }));
      setForDelivery(data.for_delivery);
      setMainTableData(
        data.bill_orders.map((order: any) => ({
          id: order.id,
          tracking_number: order.tracking_number,
          store: order.store?.name,
          country: order.country.name,
          rounded_weight: Math.round(order.rounded_weight),
          price: order.total_price,
        }))
      );
    }
  }, [billingInfo.loading, billingInfo.error]);

  const submitBillingPayment = () => {
    const paymentInfo: any = {
      paid: payment.check.paid,
      paid_with_bonus: payment.info.paid_with_bonus,
      paid_with_balance: payment.info.paid_with_balance,
      orders: payment.info.orders,
      delivery_bill: forDelivery,
      delivery_id: payment.info.delivery_id,
      paid_with_cash: payment.info.paid_with_cash,
      paid_with_card: payment.info.paid_with_card,
      discount: payment.info.discount,
      comment: payment.info.comment,
    };
    let total_paid_amount: number =
      parseInt(paymentInfo.paid_with_bonus) +
      parseInt(paymentInfo.paid_with_balance) +
      parseInt(paymentInfo.paid_with_cash) +
      parseInt(paymentInfo.paid_with_card) +
      parseInt(paymentInfo.discount);
    let total_price: number = payment.info.total_price;
    if (paymentInfo.paid && total_paid_amount < total_price) {
      alert("Внесенная сумма меньше суммы оплаты!");
    } else {
      for (let key in paymentInfo) {
        if (forDelivery) {
          delete paymentInfo["orders"];
        } else {
          delete paymentInfo["delivery_bill"];
          delete paymentInfo["delivery_id"];
        }
      }
      BoxOfficeService.BillingPaymentUpdate(paymentInfo, id)
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => {
          alert("Непредвиденная ошибка, попробуйте произвести платеж позже!");
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (!payment.check.paid) {
      setPayment((prevState: any) => ({
        ...prevState,
        info: {
          ...prevState.info,
          paid_with_bonus: "0",
          paid_with_balance: "0",
          paid_with_cash: "0",
          paid_with_card: "0",
          discount: "0",
        },
      }));
    }
  }, [payment.check.paid]);

  return (
    <>
      <h1 className="componentMainWrapper__title">Касса - счет №{id}</h1>
      <span className="componentMainWrapper__subTitle">
        Товарищи! укрепление и развитие структуры в значительной степени
        обуславливает создание новых предложений.{" "}
      </span>
      <div
        style={{ width: "100%", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)" }}
      >
        <DataGrid
          rows={mainTableData}
          columns={mainTableColumns}
          disableColumnFilter
          filterMode="server"
          autoHeight
          localeText={{
            noRowsLabel: "Нет информации",
          }}
          loading={billingInfo.loading}
          disableSelectionOnClick
          onRowDoubleClick={(params) => {
            navigate(`/boxOffice/orderViewPage/${params.row.id}`);
          }}
        />
      </div>
      <Stack direction="column" spacing="20px" sx={{ margin: "20px" }}>
        <p>
          Итоговая сумма:{" "}
          <span style={{ color: "#48A5D1" }}>
            {!billingInfo.loading &&
              !billingInfo.error &&
              billingInfo.result?.data.total_price_of_orders.total_usd}{" "}
            $ /{" "}
            {!billingInfo.loading &&
              !billingInfo.error &&
              billingInfo.result?.data.total_price_of_orders
                .total_national_currency}{" "}
            {!billingInfo.loading &&
              !billingInfo.error &&
              billingInfo.result?.data.total_price_of_orders.currency}
          </span>
        </p>
        <p>
          Дата создания:{" "}
          <span style={{ color: "#48A5D1" }}>
            {!billingInfo.loading &&
              !billingInfo.error &&
              moment(billingInfo.result?.data.created_at)
                .format("DD.MM.YYYY")
                .toString()}
          </span>
        </p>
        <p>
          Чек:{" "}
          {!billingInfo.loading &&
            !billingInfo.error &&
            billingInfo.result?.data.pdf !== null && (
              <IconButton
                style={{
                  textDecorationLine: "underline",
                  cursor: "pointer",
                  color: "#48A5D1",
                }}
                onClick={() => {
                  window.location.assign(billingInfo.result?.data.pdf);
                }}
              >
                <PrintIcon sx={{ fontSize: "30px" }} />
              </IconButton>
            )}
        </p>
        <p>
          Статус оплаты: &nbsp;&nbsp;&nbsp;{" "}
          {!billingInfo.loading &&
          !billingInfo.error &&
          billingInfo.result?.data.paid ? (
            <span style={{ color: "#48A5D1" }}>оплачено</span>
          ) : (
            <Button
              variant="contained"
              size="small"
              sx={{
                width: "130px",
                background: "rgba(72,165,209,0.7)",
                borderRadius: "4px",
                color: "white",
              }}
              disabled={billingInfo.loading}
              onClick={() => {
                setPayment({
                  ...payment,
                  modal: true,
                });
              }}
            >
              Оплатить
            </Button>
          )}
        </p>
        {!billingInfo.loading &&
          !billingInfo.error &&
          billingInfo.result?.data.paid && (
            <div className="billingPaymentInfo">
              <div>
                <p style={{ minWidth: "150px" }}>Наличными:</p>{" "}
                <span style={{ color: "#48A5D1" }}>
                  {billingInfo.result?.data.paid_with_cash}{" "}
                  {billingInfo.result?.data.total_price_of_orders.currency}
                </span>
              </div>
              <div>
                <p style={{ minWidth: "150px" }}>Безналичными:</p>{" "}
                <span style={{ color: "#48A5D1" }}>
                  {billingInfo.result?.data.paid_with_card}{" "}
                  {billingInfo.result?.data.total_price_of_orders.currency}
                </span>
              </div>
              <div>
                <p style={{ minWidth: "150px" }}>Бонусами:</p>{" "}
                <span style={{ color: "#48A5D1" }}>
                  {billingInfo.result?.data.paid_with_bonus}{" "}
                  {billingInfo.result?.data.total_price_of_orders.currency}
                </span>
              </div>
              <div>
                <p style={{ minWidth: "150px" }}>Балансом:</p>{" "}
                <span style={{ color: "#48A5D1" }}>
                  {billingInfo.result?.data.paid_with_balance}{" "}
                  {billingInfo.result?.data.total_price_of_orders.currency}
                </span>
              </div>
              <div>
                <p style={{ minWidth: "150px" }}>Скидка:</p>{" "}
                <span style={{ color: "#48A5D1" }}>
                  {billingInfo.result?.data.discount ??
                    billingInfo.result?.data.discount}{" "}
                  {billingInfo.result?.data.total_price_of_orders.currency}
                </span>
              </div>
              <div>
                <p style={{ minWidth: "150px" }}>Комментарий:</p>{" "}
                <span style={{ color: "#48A5D1" }}>
                  {billingInfo.result?.data.comment}
                </span>
              </div>
            </div>
          )}
        {!billingInfo.loading &&
        !billingInfo.error &&
        billingInfo.result?.data.for_delivery
          ? ""
          : billingInfo.result?.data.status === null && (
              <Stack
                direction="row"
                spacing="20px"
                justifyContent="center"
                sx={{ marginTop: "20px", width: "100%" }}
              >
                <Button
                  variant="contained"
                  sx={{
                    width: "200px",
                    height: "36px",
                    background:
                      "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
                    boxShadow:
                      "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
                    borderRadius: "4px",
                    color: "white",
                  }}
                  disabled={billingInfo.loading}
                  onClick={() => {
                    BoxOfficeService.UpdateStatusFromBilling(
                      id,
                      "issueQueue"
                    ).then(() => {
                      navigate("/boxOffice");
                    });
                  }}
                >
                  В ОЧЕРЕДЬ
                </Button>
              </Stack>
            )}
      </Stack>

      <Dialog
        onClose={() => {
          setPayment(paymentInitialState);
        }}
        maxWidth={false}
        open={payment.modal}
        sx={{ zIndex: "999" }}
      >
        <form
          onSubmit={(event) => {
            event.preventDefault();
            submitBillingPayment();
          }}
          style={{
            padding: "20px 56px",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <IconButton
            sx={{ position: "absolute", right: "20px", top: "10px" }}
            onClick={() => {
              setPayment(paymentInitialState);
            }}
          >
            <CloseIcon />
          </IconButton>
          <h1 className="header-currencyBox__title">Оплата</h1>
          <Stack
            direction="row"
            spacing="20px"
            sx={{ borderBottom: "2px solid #48A5D1", paddingBottom: "0px" }}
          >
            <FormControl fullWidth sx={{ width: "200px" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">
                Сумма к оплате
              </InputLabel>
              <Input
                id="standard-adornment-amount"
                value={payment.info.total_price}
                disabled={true}
                endAdornment={
                  <InputAdornment position="end">
                    {payment.currency.currency}
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl fullWidth sx={{ width: "200px" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">
                Баланс
              </InputLabel>
              <Input
                id="standard-adornment-amount"
                value={payment.info.balance_amount}
                disabled={true}
                endAdornment={
                  <InputAdornment position="end">
                    {payment.currency.currency}
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl fullWidth sx={{ width: "200px" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">
                Бонусы
              </InputLabel>
              <Input
                id="standard-adornment-amount"
                value={payment.info.bonus_amount}
                disabled={true}
                endAdornment={
                  <InputAdornment position="end">
                    {payment.currency.currency}
                  </InputAdornment>
                }
              />
            </FormControl>
          </Stack>
          <TextField
            size="small"
            label="Наличными"
            required={true}
            margin="normal"
            disabled={!payment.check.paid}
            fullWidth={true}
            error={payment.check.cashCheck}
            helperText={payment.check.cashText}
            value={payment.info.paid_with_cash}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {payment.currency.currency}
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              setPayment({
                ...payment,
                info: {
                  ...payment.info,
                  paid_with_cash: JustNumberForMath(event.target.value),
                },
              });
            }}
          />
          <TextField
            size="small"
            label="Безналичными"
            required={true}
            type="number"
            fullWidth={true}
            disabled={!payment.check.paid}
            error={payment.check.cardCheck}
            helperText={payment.check.cardText}
            value={payment.info.paid_with_card}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {payment.currency.currency}
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              setPayment({
                ...payment,
                info: {
                  ...payment.info,
                  paid_with_card: JustNumberForMath(event.target.value),
                },
              });
            }}
          />
          <TextField
            size="small"
            label="Балансом"
            required={true}
            margin="normal"
            type="number"
            fullWidth={true}
            disabled={!payment.check.paid}
            error={payment.check.balanceCheck}
            helperText={payment.check.balanceText}
            value={payment.info.paid_with_balance}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {payment.currency.currency}
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              let error = false;
              let text = "";
              if (event.target.value > payment.info.balance_amount) {
                error = true;
                text = "Недостаточно баланса!";
              } else {
                error = false;
                text = "";
              }
              setPayment({
                ...payment,
                check: {
                  ...payment.check,
                  balanceCheck: error,
                  balanceText: text,
                },
                info: {
                  ...payment.info,
                  paid_with_balance: JustNumberForMath(event.target.value),
                },
              });
            }}
          />
          <TextField
            size="small"
            label="Бонусами"
            required={true}
            margin="normal"
            type="number"
            fullWidth={true}
            disabled={!payment.check.paid}
            error={payment.check.bonusCheck}
            helperText={payment.check.bonusText}
            value={payment.info.paid_with_bonus}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {payment.currency.currency}
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              if (event.target.value > payment.info.bonus_amount) {
                setPayment({
                  ...payment,
                  check: {
                    ...payment.check,
                    bonusCheck: true,
                    bonusText: "Недостаточно бонусов!",
                  },
                  info: {
                    ...payment.info,
                    paid_with_bonus: JustNumberForMath(event.target.value),
                  },
                });
              } else {
                setPayment({
                  ...payment,
                  check: {
                    ...payment.check,
                    bonusCheck: false,
                    bonusText: "",
                  },
                  info: {
                    ...payment.info,
                    paid_with_bonus: JustNumberForMath(event.target.value),
                  },
                });
              }
            }}
          />
          <TextField
            size="small"
            label="Скидка"
            required={true}
            margin="normal"
            type="number"
            fullWidth={true}
            disabled={!payment.check.paid}
            value={payment.info.discount}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {payment.currency.currency}
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              setPayment({
                ...payment,
                info: {
                  ...payment.info,
                  discount: JustNumberForMath(event.target.value),
                },
              });
            }}
          />
          <TextField
            size="small"
            label="Комментарий"
            margin="normal"
            fullWidth={true}
            value={payment.info.comment}
            onChange={(event) => {
              setPayment({
                ...payment,
                info: {
                  ...payment.info,
                  comment: event.target.value,
                },
              });
            }}
          />

          <Stack direction="row" spacing="20px" sx={{ marginTop: "30px" }}>
            <Button
              variant="contained"
              disabled={
                payment.check.paid
                  ? !(
                      !payment.check.bonusCheck &&
                      !payment.check.balanceCheck &&
                      !payment.check.cashCheck &&
                      !payment.check.cardCheck
                    )
                  : false
              }
              type="submit"
            >
              Выставить счет
            </Button>
            <FormControlLabel
              control={
                <Checkbox
                  checked={payment.check.paid}
                  onClick={() => {
                    setPayment({
                      ...payment,
                      check: {
                        ...payment.check,
                        paid: !payment.check.paid,
                      },
                    });
                  }}
                />
              }
              label="Оплата сразу"
            />
          </Stack>
        </form>
      </Dialog>
    </>
  );
}
