import React from "react";
import "../../styles/customers.css";
import {
  DataGrid,
  GridColDef,
  GridFooterContainer,
  GridHeader,
} from "@mui/x-data-grid";
import {
  Button,
  IconButton,
  InputAdornment,
  Pagination,
  Stack,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { getCookie } from "typescript-cookie";
import { CustomerService } from "../../services/CustomerService";

interface IFilter {
  search: string;
  page: number;
}

function Customers() {
  const group: string | undefined = getCookie("global_admin_group");
  const navigate = useNavigate();
  const excelDownloadLink = CustomerService.GetCustomersExcel();
  const [filterValue, setFilterValue] = React.useState<IFilter>({
    search: "",
    page: 1,
  });
  const customersList = CustomerService.GetCustomers(filterValue);
  const mainTableColumns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "data", headerName: "Дата", width: 100 },
    { field: "name", headerName: "Имя клиента", width: 200 },
    { field: "country", headerName: "Страна", width: 140 },
    { field: "activeOrders", headerName: "Активные посылки", width: 150 },
    { field: "ordersSum", headerName: "Сумма посылок", width: 150 },
    { field: "summaryWeight", headerName: "Общий вес посылок", width: 160 },
    { field: "phone", headerName: "Номер телефона", width: 160 },
  ];
  return (
    <>
      <p className="componentMainWrapper__title">Клиенты</p>
      <span className="componentMainWrapper__subTitle">
        Товарищи! укрепление и развитие структуры в значительной степени
        обуславливает создание новых предложений.{" "}
      </span>

      <Stack
        direction="row"
        spacing="20px"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        marginBottom="20px"
      >
        <Stack direction="row" spacing="20px"></Stack>
        <Stack direction="row" spacing="20px">
          <TextField
            label="Поиск"
            id="search-bar"
            value={filterValue.search}
            sx={{ width: "340px" }}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon style={{ fill: "#48A5D1" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              setFilterValue({
                ...filterValue,
                search: event.target.value,
              });
            }}
          />
          {group === "Администратор" && (
            <Button
              sx={{
                width: "200px",
                height: "36px",
                background:
                  "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
                boxShadow:
                  "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
                borderRadius: "4px",
                color: "white",
                margin: "20px",
              }}
              disabled={
                !!(excelDownloadLink.loading || excelDownloadLink.error)
              }
              onClick={() => {
                window.open(excelDownloadLink.result?.data.excel_file);
              }}
            >
              Скачать таблицу
            </Button>
          )}
        </Stack>
      </Stack>

      <div
        style={{
          width: "100%",
          borderRadius: "10px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <DataGrid
          rows={
            !customersList.loading && !customersList.error
              ? customersList.result?.data.results.map((item: any) => ({
                  id: item.id,
                  data: moment(item.date_joined)
                    .format("DD.MM.YYYY")
                    .toString(),
                  name: item.user.first_name + " " + item.user.last_name,
                  country: item.user.country?.country,
                  activeOrders: item.count_of_active_orders,
                  ordersSum: item.count_of_orders,
                  summaryWeight: item.weight_of_all_orders,
                  phone: item.phone,
                }))
              : []
          }
          localeText={{
            noRowsLabel: "Нет информации",
          }}
          columns={mainTableColumns}
          disableColumnFilter
          filterMode="server"
          autoHeight
          loading={customersList.loading}
          disableSelectionOnClick
          onRowDoubleClick={(params) => {
            navigate(`viewCustomer/${params.row.id}`);
          }}
          components={{
            Header: () => {
              return <GridHeader></GridHeader>;
            },
            Footer: () => {
              return (
                <GridFooterContainer>
                  <Pagination
                    count={
                      !customersList.loading && !customersList.error
                        ? customersList.result?.data.total_pages
                        : 1
                    }
                    page={filterValue.page}
                    onChange={(event, value: number) => {
                      setFilterValue({
                        ...filterValue,
                        page: value,
                      });
                    }}
                  />
                </GridFooterContainer>
              );
            },
          }}
        />
      </div>
    </>
  );
}

export default Customers;
