import React, { FormEvent, useEffect, useState } from "react";
import "../../styles/warehouse.css";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Pagination,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { createSearchParams, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { AddressesService } from "../../services/AddressesService";
import { WarehouseService } from "../../services/WarehouseService";
import { TransportsService } from "../../services/TransportsService";
import { checkModalResponse, VisuallyHiddenInput } from "../../helpers/helpers";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers";
import { useSelector } from "react-redux";

const tableInitialValues = {
  rows: [],
  rows1: [],
  filter: {
    country: "",
    date_from: null,
    date_to: null,
    search: "",
    page: 1,
    total_pages: 1,
  },
  filter1: {
    country: "",
    date_from: null,
    date_to: null,
    search: "",
    page: 1,
    total_pages: 1,
  },
  status: {
    loading: false,
    error: false,
    message: "",
  },
  selectedRows: [],
  excel: false,
};
const modalInitialValues = {
  open: false,
  values: {
    excel: null,
    send_all: false,
    orders: [],
    excels: [],
    country: "",
    type_of_transport: "",
  },
  validation: {
    error: {
      excel: false,
      country: false,
      type_of_transport: false,
    },
    message: {
      excel: "",
      country: "",
      type_of_transport: "",
    },
  },
  requested: false,
  action: "",
};

function Warehouse() {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.userData.user);

  const [table, setTable] = useState<any>({
    ...tableInitialValues,
    columns: [
      { field: "id", headerName: "ID", width: 80 },
      {
        field: "code",
        headerName: "Код клиента",
        flex: 1,
        renderCell: (params: any) =>
          params.row.user ? params.row.user.id : "",
      },
      {
        field: "name",
        headerName: "ФИО",
        flex: 1,
        renderCell: (params: any) =>
          params.row.user ? params.row.user.full_name : params.row.attribute,
      },
      { field: "tracking_number", headerName: "Трек номер", flex: 1 },
      {
        field: "store",
        headerName: "Магазин",
        flex: 1,
        renderCell: (params: any) => params.row.store?.name,
      },
      { field: "weight", headerName: "Факт. вес", flex: 1 },
      {
        field: "country",
        headerName: "Страна",
        flex: 1,
        renderCell: (params: any) => params.row.country?.name,
      },
      { field: "comment", headerName: "Комментарий", flex: 1 },
      {
        field: "edit",
        headerName: "",
        flex: 1,
        renderCell: (params: any) => (
          <IconButton
            color="secondary"
            aria-label="add an alarm"
            onClick={() => {
              navigate({
                pathname: "/warehouse/details",
                search: createSearchParams({
                  action: "edit",
                  tracking_number: params.row.tracking_number,
                }).toString(),
              });
            }}
          >
            <EditIcon style={{ color: "#B9B9B9" }} />
          </IconButton>
        ),
      },
    ],
    columns1: [
      { field: "id", headerName: "ID файла", width: 80 },
      {
        field: "created_at",
        headerName: "Дата добавления",
        flex: 1,
        renderCell: (params: any) =>
          params.row.created_at !== null
            ? moment(params.row.created_at).format("YYYY-MM-DD").toString()
            : "-",
      },
      {
        field: "updated_at",
        headerName: "Дата отправки",
        flex: 1,
        renderCell: (params: any) =>
          params.row.updated_at !== null
            ? moment(params.row.updated_at).format("YYYY-MM-DD").toString()
            : "-",
      },
      {
        field: "created_excel",
        headerName: "Готовый файл",
        flex: 1,
        renderCell: (params: any) => {
          return (
            params.row.created_excel !== null && (
              <IconButton
                onClick={() => {
                  window.location.assign(params.row.created_excel);
                }}
              >
                <FileCopyIcon sx={{ color: "#48A5D1", fontSize: "30px" }} />
              </IconButton>
            )
          );
        },
      },
      {
        field: "uploaded_excel",
        headerName: "Загруженный файл",
        flex: 1,
        renderCell: (params: any) => {
          return (
            params.row.uploaded_excel !== null && (
              <IconButton
                onClick={() => {
                  window.location.assign(params.row.uploaded_excel);
                }}
              >
                <FileCopyIcon sx={{ color: "#48A5D1", fontSize: "30px" }} />
              </IconButton>
            )
          );
        },
      },
      {
        field: "edit",
        headerName: "",
        width: 125,
        renderCell: (params: any) => {
          return (
            params.row.date_send === null && (
              <IconButton
                color="secondary"
                aria-label="add an alarm"
                onClick={() => {
                  WarehouseService.DeleteExcel(params.row.id)
                    .then(() => {
                      excelsList.execute();
                    })
                    .catch(() => {
                      alert("Произошла ошибка!");
                    });
                }}
              >
                <DeleteIcon style={{ color: "#B9B9B9" }} />
              </IconButton>
            )
          );
        },
      },
    ],
  });
  const [modal, setModal] = useState<any>(modalInitialValues);

  const getTransports = TransportsService.GetTransports(modal.values.country);

  const getCountries = AddressesService.GetCountries();
  const excelsList = WarehouseService.GetExcelsList({
    ...table.filter1,
    ...(table.filter1.date_from !== null
      ? { date_from: moment(table.filter1.date_from?.$d).format("YYYY-MM-DD") }
      : {}),
    ...(table.filter1.date_to !== null
      ? { date_to: moment(table.filter1.date_to?.$d).format("YYYY-MM-DD") }
      : {}),
  });
  const ordersList = WarehouseService.GetOrdersList({
    ...table.filter,
    ...(table.filter.date_from !== null
      ? { date_from: moment(table.filter.date_from?.$d).format("YYYY-MM-DD") }
      : {}),
    ...(table.filter.date_to !== null
      ? { date_to: moment(table.filter.date_to?.$d).format("YYYY-MM-DD") }
      : {}),
  });

  useEffect(() => {
    if (excelsList.loading) {
      setTable((prevState: any) => ({
        ...prevState,
        status: {
          ...prevState.status,
          loading: true,
        },
      }));
    } else if (excelsList.error) {
      setTable((prevState: any) => ({
        ...prevState,
        status: {
          ...prevState.status,
          loading: false,
          error: true,
        },
      }));
    } else {
      const data = excelsList.result?.data;
      setTable((prevState: any) => ({
        ...prevState,
        rows1: data.results,
        status: {
          ...prevState.status,
          loading: false,
          error: false,
        },
        filter1: {
          ...prevState.filter1,
          page1: data.current_page,
          total_pages: data.total_pages,
        },
        selectedRows: [],
      }));
    }
  }, [excelsList.loading, excelsList.error, excelsList.result?.data]);
  useEffect(() => {
    if (ordersList.loading) {
      setTable((prevState: any) => ({
        ...prevState,
        status: {
          ...prevState.status,
          loading: true,
        },
      }));
    } else if (ordersList.error) {
      setTable((prevState: any) => ({
        ...prevState,
        status: {
          ...prevState.status,
          loading: false,
          error: true,
        },
      }));
    } else {
      const data = ordersList.result?.data;
      setTable((prevState: any) => ({
        ...prevState,
        rows: data.results,
        status: {
          ...prevState.status,
          loading: false,
          error: false,
        },
        filter: {
          ...prevState.filter,
          page: data.current_page,
          total_pages: data.total_pages,
        },
        selectedRows: [],
      }));
    }
  }, [ordersList.loading, ordersList.error, ordersList.result?.data]);

  useEffect(() => {
    if (!getCountries.loading && !getCountries.error) {
      // const staffCountry = user.country?.id;
      const staffCountry =
        table.filter1.country ||
        (user.country &&
          getCountries.result?.data.find(
            (country: any) => country.code === user.country.code
          )?.id) ||
        "";

      const listOfCounties = [...getCountries.result?.data];

      if (
        staffCountry !== "" &&
        listOfCounties.find(
          (country: any) => parseInt(staffCountry) === country.id
        )
      ) {
        setTable((prevState: any) => ({
          ...prevState,
          filter1: {
            ...prevState.filter1,
            country: staffCountry,
          },
          filter: {
            ...prevState.filter,
            country: staffCountry,
          },
        }));
      } else {
        setTable((prevState: any) => ({
          ...prevState,
          filter1: {
            ...prevState.filter1,
            country: listOfCounties.pop() ? listOfCounties.pop().id : "",
          },
          filter: {
            ...prevState.filter,
            country: listOfCounties.pop() ? listOfCounties.pop().id : "",
          },
        }));
      }
    }
  }, [getCountries.error, getCountries.loading, getCountries.result?.data]);

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();

    setModal({
      ...modal,
      requested: true,
    });
    if (modal.action === "uploadExcel") {
      WarehouseService.AddExcel({
        excel: modal.values.excel,
        country: modal.values.country,
        type_of_transport: modal.values.type_of_transport,
      })
        .then(() => {
          excelsList.execute();
          ordersList.execute();
          setModal(modalInitialValues);
        })
        .catch((err) => {
          checkModalResponse(err.response.data, setModal, modal);
        });
    }
    if (modal.action === "ordersSending") {
      WarehouseService.SendOrders({
        orders: modal.values.orders,
        send_all: modal.values.send_all,
        country: modal.values.country,
      })
        .then((res) => {
          excelsList.execute();
          ordersList.execute();
          setModal(modalInitialValues);
          window.open(res.data.excel_link, "_blank");
        })
        .catch((err) => {
          checkModalResponse(err.response.data, setModal, modal);
        });
    }
    if (modal.action === "excelsSending") {
      WarehouseService.SendExcels({
        excels: modal.values.excels,
        send_all: modal.values.send_all,
        country: modal.values.country,
      })
        .then(() => {
          excelsList.execute();
          ordersList.execute();
          setModal(modalInitialValues);
        })
        .catch((err) => {
          checkModalResponse(err.response.data, setModal, modal);
        });
    }
  };

  return (
    <>
      <div className="w-full flex items-center gap-[20px] mb-[50px]">
        <h1 className="componentMainWrapper__title">Склад</h1>
        <Button
          disabled={!table.excel}
          variant="contained"
          onClick={() => {
            setTable({
              ...table,
              excel: false,
            });
          }}
        >
          Посылки
        </Button>
        <Button
          disabled={table.excel}
          variant="contained"
          onClick={() => {
            setTable({
              ...table,
              excel: true,
            });
          }}
        >
          Excel
        </Button>
      </div>

      {table.excel ? (
        <>
          <div className="w-full flex justify-between items-center mb-[20px]">
            <div className="flex items-center gap-[20px]">
              <FormControl size="small" sx={{ minWidth: "150px" }}>
                <InputLabel>Страна отправки</InputLabel>
                <Select
                  value={table.filter1.country}
                  label="Страна отправки"
                  onChange={(event) => {
                    setTable({
                      ...table,
                      filter1: {
                        ...table.filter1,
                        country: event.target.value,
                      },
                    });
                  }}
                >
                  {!getCountries.loading &&
                    !getCountries.error &&
                    getCountries.result?.data.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className="flex items-center gap-[20px]">
              <DatePicker
                label="Начало даты"
                value={table.filter.date_from}
                onChange={(newValue) => {
                  setTable({
                    ...table,
                    filter: {
                      ...table.filter,
                      date_from: newValue,
                    },
                  });
                }}
                renderInput={(props) => <TextField size="small" {...props} />}
              />
              <DatePicker
                label="Конец даты"
                value={table.filter.date_to}
                onChange={(newValue) => {
                  setTable({
                    ...table,
                    filter: {
                      ...table.filter,
                      date_to: newValue,
                    },
                  });
                }}
                renderInput={(props) => <TextField size="small" {...props} />}
              />
              <Button
                sx={{
                  width: "150px",
                  height: "36px",
                  background:
                    "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
                  boxShadow:
                    "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
                  borderRadius: "4px",
                  color: "white",
                }}
                onClick={() => {
                  setModal({
                    ...modalInitialValues,
                    open: true,
                    action: "uploadExcel",
                  });
                }}
              >
                Добавить
              </Button>
            </div>
          </div>

          <div className="w-full rounded-[10px] shadow-md">
            <DataGrid
              rows={table.rows1}
              keepNonExistentRowsSelected
              columns={table.columns1}
              disableColumnFilter
              filterMode="server"
              selectionModel={table.selectedRows}
              localeText={{
                noRowsLabel: "Нет информации",
              }}
              checkboxSelection
              onSelectionModelChange={(value: any) => {
                setTable({
                  ...table,
                  selectedRows: value,
                });
              }}
              autoHeight
              loading={ordersList.loading || excelsList.loading}
              isRowSelectable={(params: any) => params.row.updated_at === null}
              disableSelectionOnClick
              components={{
                Footer: () => {
                  return (
                    <GridFooterContainer>
                      <Pagination
                        count={table.filter1.total_pages}
                        page={table.filter1.page}
                        onChange={(event, value: number) => {
                          setTable((prevState: any) => ({
                            ...prevState,
                            filter1: {
                              ...prevState.filter1,
                              page: value,
                            },
                          }));
                        }}
                      />
                      <div className="flex items-center gap-[20px] pr-[10px]">
                        <Button
                          disabled={table.rows1.length === 0}
                          sx={{
                            height: "36px",
                            background:
                              "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
                            boxShadow:
                              "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
                            borderRadius: "4px",
                            color: "white",
                          }}
                          onClick={() => {
                            setModal({
                              ...modalInitialValues,
                              open: true,
                              action: table.excel
                                ? "excelsSending"
                                : "ordersSending",
                              values: {
                                ...modalInitialValues.values,
                                excels: table.selectedRows,
                                send_all: table.selectedRows.length === 0,
                                country: table.filter.country,
                              },
                            });
                          }}
                        >
                          Отправить
                        </Button>
                      </div>
                    </GridFooterContainer>
                  );
                },
              }}
            />
          </div>
        </>
      ) : (
        <>
          <div className="w-full flex justify-between items-center mb-[20px]">
            <div className="flex items-center gap-[20px]">
              <FormControl size="small" sx={{ minWidth: "150px" }}>
                <InputLabel>Страна отправки</InputLabel>
                <Select
                  value={table.filter.country}
                  label="Страна отправки"
                  onChange={(event) => {
                    setTable({
                      ...table,
                      filter: {
                        ...table.filter,
                        country: event.target.value,
                      },
                    });
                  }}
                >
                  {!getCountries.loading &&
                    !getCountries.error &&
                    getCountries.result?.data.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className="flex items-center gap-[20px]">
              <TextField
                label="Поиск клиента"
                value={table.filter.search}
                sx={{ width: "340px" }}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ fill: "#48A5D1" }} />
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => {
                  setTable({
                    ...table,
                    filter: {
                      ...table.filter,
                      search: event.target.value,
                    },
                  });
                }}
              />
              <Button
                sx={{
                  width: "150px",
                  height: "36px",
                  background:
                    "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
                  boxShadow:
                    "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
                  borderRadius: "4px",
                  color: "white",
                }}
                onClick={() => {
                  navigate({
                    pathname: "/warehouse/details",
                    search: createSearchParams({
                      action: "add",
                    }).toString(),
                  });
                }}
              >
                Добавить
              </Button>
            </div>
          </div>

          <div className="w-full rounded-[10px] shadow-md">
            <DataGrid
              rows={table.rows}
              keepNonExistentRowsSelected
              columns={table.columns}
              disableColumnFilter
              filterMode="server"
              localeText={{
                noRowsLabel: "Нет информации",
              }}
              selectionModel={table.selectedRows}
              checkboxSelection
              onSelectionModelChange={(value: any) => {
                setTable({
                  ...table,
                  selectedRows: value,
                });
              }}
              autoHeight
              loading={ordersList.loading || excelsList.loading}
              isRowSelectable={(params: any) => true}
              disableSelectionOnClick
              components={{
                Footer: () => {
                  return (
                    <GridFooterContainer>
                      <Pagination
                        count={table.filter.total_pages}
                        page={table.filter.page}
                        onChange={(event, value: number) => {
                          setTable((prevState: any) => ({
                            ...prevState,
                            filter: {
                              ...prevState.filter,
                              page: value,
                            },
                          }));
                        }}
                      />
                      <div className="flex items-center gap-[20px] pr-[10px]">
                        <Button
                          disabled={table.rows.length === 0}
                          sx={{
                            height: "36px",
                            background:
                              "linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%)",
                            boxShadow:
                              "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
                            borderRadius: "4px",
                            color: "white",
                          }}
                          onClick={() => {
                            setModal({
                              ...modalInitialValues,
                              open: true,
                              action: table.excel
                                ? "excelsSending"
                                : "ordersSending",
                              values: {
                                ...modalInitialValues.values,
                                orders: table.selectedRows,
                                send_all: table.selectedRows.length === 0,
                                country: table.filter.country,
                              },
                            });
                          }}
                        >
                          Отправить
                        </Button>
                      </div>
                    </GridFooterContainer>
                  );
                },
              }}
            />
          </div>
        </>
      )}

      <Modal
        open={modal.open}
        onClose={() => setModal(modalInitialValues)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form
          onSubmit={handleFormSubmit}
          className="mainModal relative flex flex-col justify-start items-center"
        >
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
            onClick={() => setModal(modalInitialValues)}
          >
            <CloseIcon />
          </IconButton>
          <h2 className="text-[30px] font-[600] text-[#1E1C2A] mb-[40px]">
            {modal.action === "uploadExcel" && "Загрузить excel"}
            {modal.action === "ordersSending" && "Отправить посылки?"}
            {modal.action === "excelsSending" && "Отправить excel-файлы?"}
          </h2>

          {modal.values.send_all && <p>Внимание! Вы отправляете ВСЕ записи!</p>}

          {modal.action === "uploadExcel" && (
            <div className="w-full flex flex-col gap-[20px] mb-[50px]">
              <FormControl required fullWidth>
                <InputLabel>Страна</InputLabel>
                <Select
                  value={modal.values.country}
                  label="Страна"
                  error={modal.validation.error.country}
                  onChange={(event) => {
                    setModal({
                      ...modal,
                      values: {
                        ...modal.values,
                        country: event.target.value,
                        type_of_transport: "",
                      },
                    });
                  }}
                >
                  {!getCountries.loading &&
                    !getCountries.error &&
                    getCountries.result?.data.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                  {modal.validation.message.country}
                </FormHelperText>
              </FormControl>
              <FormControl required fullWidth>
                <InputLabel>Транспорт</InputLabel>
                <Select
                  value={modal.values.type_of_transport}
                  label="Транспорт"
                  error={modal.validation.error.type_of_transport}
                  onChange={(event) => {
                    setModal({
                      ...modal,
                      values: {
                        ...modal.values,
                        type_of_transport: event.target.value,
                      },
                    });
                  }}
                >
                  {!getTransports.loading &&
                    !getTransports.error &&
                    getTransports.result?.data.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                  {modal.validation.message.type_of_transport}
                </FormHelperText>
              </FormControl>
              <TextField
                variant="outlined"
                fullWidth
                disabled
                value={
                  modal.values.excel === null
                    ? "Выберите файл"
                    : modal.values.excel?.name
                }
                error={modal.validation.error.excel}
                helperText={modal.validation.message.excel}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {modal.values.excel !== null ? (
                        <Button
                          onClick={() => {
                            setModal({
                              ...modal,
                              values: {
                                ...modal.values,
                                excel: null,
                              },
                            });
                          }}
                        >
                          <CloseIcon />
                        </Button>
                      ) : (
                        <Button
                          component="label"
                          role={undefined}
                          tabIndex={-1}
                          variant="contained"
                        >
                          <AttachFileIcon />
                          <VisuallyHiddenInput
                            required
                            type="file"
                            onChange={(event) => {
                              if (event.target.files) {
                                setModal({
                                  ...modal,
                                  values: {
                                    ...modal.values,
                                    excel: event.target.files[0],
                                  },
                                });
                              }
                            }}
                          />
                        </Button>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}

          <div className="flex justify-between gap-[40px] items-center mt-[20px]">
            <Button
              type="button"
              variant="outlined"
              onClick={() => setModal(modalInitialValues)}
            >
              Отмена
            </Button>
            <LoadingButton
              type="submit"
              disabled={modal.requested}
              loading={modal.requested}
              variant="contained"
            >
              Готово
            </LoadingButton>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default Warehouse;
